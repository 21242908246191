import React, { useEffect, useContext} from "react";
import { Box, CircularProgress, Modal, Typography } from '@mui/material';
import InfiniteScroll from "react-infinite-scroll-component";

import { CardApps } from "./globalComponents/cards/CardsAdd"
import { appsLikeContext, tabsContext } from "./Layout";
import { allAppsContext } from "../App";
import { ButtonSimple } from "./globalComponents/inputs/inputs";
import { useNavigate } from "react-router-dom";

export const Applications = () => {
  const { data, dataUser, open, setOpen, topApps } = useContext(allAppsContext)
  const { setPage, hasMore} = useContext(tabsContext)
  const { setSite, topLast } = useContext(appsLikeContext)
  const token = localStorage.token
  const navigate = useNavigate()

  useEffect(()=>{
    setSite("Apps")
  }, [ setSite])

const handleClose = () => {setOpen(false)}

if(token){
    return(
       <InfiniteScroll dataLength={dataUser.length} hasMore={hasMore} next={() => {setPage((prevPage) => prevPage + 1)}} loader={<CircularProgress  color="inherit"  sx={{margin: "2%"}}/>} >
          {open ? 
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{position: 'absolute',  top: {sm: '30%', md:'55%', lg:'45%'},  left: '50%', transform: 'translate(-50%, -50%)',  width: 400,  bgcolor: 'background.paper',  border: '2px solid #000',  boxShadow: 24,  p: 4,}} >
                  <Typography id="modal-modal-description" sx={{ mt: 2, fontSize:{xs: "18px", sm: "24px"}, color: "var(--black-100)", textAlign: "center" }}>
                    Cantonica is built for the mobile web and search results are best viewed on a mobile device.
                  </Typography>
                  <ButtonSimple  text={"Continue"} onClick={handleClose} styles={{ marginY: "30px", width: {xs: "120px", sm: "200px"}, height: "50px", borderRadius: "15px", background: "#262626", ":hover": { background: "#262626", }, div:{ display: "flex", justifyContent: "center"}}}/>
                </Box>
              </Modal>
        :
          <Box sx={{ display: {xs:"flex", md: "grid"}, flexDirection: "column", gridTemplateColumns: {md: "45% 45%", lg:"30% 30% 30%"},  width: {sm:"90%", md:"100%"}, justifyContent: "center", alignSelf: "center"}}>
            {dataUser && dataUser.map(element => (
              <Box key={element._id} sx={{ display:  "flex", flexDirection: "column", cursor: "pointer", marginX: 2, paddingY: "7%", paddingX: "3%", borderBottom: "1px solid #F4F6F9", ":hover": ""}}>
                <CardApps element={element} page={"Apps"} />
              </Box>
            ))}    
          </Box>
        }
        </InfiniteScroll>
    )
  } else{
    return(
      <>
        <InfiniteScroll dataLength={data.length} hasMore={hasMore} next={() => {setPage((prevPage) => prevPage + 1)}} loader={<CircularProgress color="inherit"  sx={{margin: "2%"}}/>} >
        {open ? 
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{position: 'absolute',  top: {sm: '30%', md:'55%', lg:'45%'},  left: '50%', transform: 'translate(-50%, -50%)',  width: 400,  bgcolor: 'background.paper',  border: '2px solid #000',  boxShadow: 24,  p: 4,}} >
                <Typography id="modal-modal-description" sx={{ mt: 2, fontSize:{xs: "18px", sm: "24px"}, color: "var(--black-100)", textAlign: "center" }}>
                  Cantonica is built for the mobile web and search results are best viewed on a mobile device.
                </Typography>
                <ButtonSimple  text={"Continue"} onClick={handleClose} styles={{ marginY: "30px", width: {xs: "120px", sm: "200px"}, height: "50px", borderRadius: "15px", background: "#262626", ":hover": { background: "#262626", }, div:{ display: "flex", justifyContent: "center"}}}/>
              </Box>
            </Modal>
        :
          <>
            <Box sx={{display: "flex", flexDirection:{xs:"column", md:"row"}, paddingY: "3%", borderBottom: "1px solid #F4F6F9"}}>
              <Typography variant="h3" sx={{minWidth: "190px", fontSize: 16,  textAlign: "initial", margin: {xs:"5%", md: 0}, marginY: {md:4}, fontWeight: "700"}}>Top Apps Right Now</Typography>
              <Box sx={{display: "flex", flexDirection:"row", justifyContent: "space-around", width:{xs: "100%", md:"90%"} }}>
                {topApps && topApps.map(element => (
                  <>
                    <Box sx={{display: "flex", flexDirection:"column", alignItems: "center", justifyContent: "space-around", marginTop: {xs:"5%", md:0}}} >
                        <Box >
                          <Box
                            component="img"
                            src={element.logo}
                            onClick={() => {element.viewed = true; navigate(`/app/${element.nameExpanded}/${element._id}`)}}
                            alt={element.name}
                            id={element._id}
                            sx={{width: {xs: "40px", sm: "68px"}, height: {xs:"40px", sm: "68px"}, borderRadius:{xs:"15px", md: "24px"}}}
                          />
                        </Box>
                        <Typography variant="h3" sx={{display: "block",color: element.viewed ? "#747B84" : "#09101D", fontSize: 12, textAlign: "center", fontWeight: "700", width: "100%", marginLeft:{md: "5%"}, height: "21px", overflow: "hidden"}}>{element.name}</Typography>
                    </Box>
                  </>
                ))}
              </Box>
            </Box>
            <Typography variant="h3" sx={{minWidth: "120px", fontSize: "18px",  textAlign: "initial", margin: {xs:"5%", md: 0}, marginY: {md:4}, fontWeight: "700", paddingLeft:"5%"}}>Latest Apps</Typography>
            <Box sx={{ display: {xs:"flex", md: "grid"}, flexDirection: "column", gridTemplateColumns: {md: "45% 45%", lg:"30% 30% 30%"},  width: {sm:"90%", md:"100%"}, justifyContent: "center", alignSelf: "center", overflow:"auto", borderBottom: "1px solid #F4F6F9"}}>
              {topLast && topLast.map(element => (
                <Box key={element._id} sx={{ marginX: 2, paddingY: "7%", paddingX: "3%", borderBottom: "1px solid #F4F6F9", ":hover": ""}}>
                  <CardApps element={element}/>
                </Box>        
              ))}
            </Box>
            <Box sx={{ display: {xs:"flex", md: "grid"}, flexDirection: "column", gridTemplateColumns: {md: "45% 45%", lg:"30% 30% 30%"},  width: {sm:"90%", md:"100%"}, justifyContent: "center", alignSelf: "center", overflow:"auto"}}>
              {data && data.map(element => (
                <Box key={element._id} sx={{ marginX: 2, paddingY: "7%", paddingX: "3%", borderBottom: "1px solid #F4F6F9", ":hover": ""}}>
                  <CardApps element={element}/>
                </Box>        
              ))}
            </Box>
          </>
        }
        </InfiniteScroll>
      </>
    )
  }
}
