import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  Box,
  Typography,
  CircularProgress,
  Rating,
  Modal,
  TextField,
} from "@mui/material";
import { ButtonSimple } from "../inputs/inputs";
import { SnackbarBasic } from "../feedBacks/FeedBacks";
import { ratingSite } from "../connections";

export const ModalWriteReview = ({
  showModalWrite,
  handleClose,
  idSite,
  reviews,
  setEditReview,
  setMyReview,
  setRating,
}) => {
  const [value, setValue] = useState(0);
  const [text, setText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    success: false,
    error: false,
  });
  const { register, handleSubmit } = useForm();

  const onSubmit = ({ user }) => {
    if (localStorage.token) {
      const xuser = JSON.parse(localStorage.xuser);
      user = {
        ...user,
        site_id: idSite,
        user_id: xuser._id,
        rating: value,
      };
      if (!loading && value !== 0) {
        setLoading(true);
        axios
          .post(`/reviews/add_review`, user)
          .then((response) => {
            const data = response.data;
            data.newDate = data.created_at.substr(5, 6);
            data.user_name = xuser.name;
            ratingSite(idSite, setRating);
            setLoading(false);
            setEditReview(true);
            setMyReview(data);
            setOpenSnackbar({ success: true, error: false });
            reviews.push(data);
            setTimeout(() => {
              setOpenSnackbar({ success: false, error: false });
              handleClose();
            }, 3000);
          })
          .catch((error) => {
            setLoading(false);
            setOpenSnackbar({ success: false, error: true });
            setTimeout(() => {
              setOpenSnackbar({ success: false, error: false });
              handleClose();
            }, 3000);
            console.log(error);
          });
      } else {
        setText(true);
      }
    }
  };

  return (
    <Modal
      open={showModalWrite}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: 300, sm: 500 },
          bgcolor: "background.paper",
          border: "1px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: "15px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: 600,
            fontSize: { xs: 18, md: 24 },
            textAlign: "center",
            marginY: { md: 2 },
          }}
        >
          Share your experience
        </Typography>
        <Box
          component="form"
          autoComplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: { xs: "100%", sm: "75%" },
              alignItems: "center",
              gap: 3,
              marginTop: "16px",
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: 600, fontSize: 14 }}>
              Rate this app:
            </Typography>
            <Rating
              name="simple-controlled"
              value={value}
              sx={{ fontSize: { md: "32px" } }}
              onChange={(event, newValue) => {
                setValue(newValue);
                setText(false);
              }}
            />
          </Box>
          <Typography
            variant="body2"
            sx={{
              display: text ? "block" : "none",
              fontSize: "12px",
              textAlign: "center",
              alignSelf: { md: "center" },
              color: "red",
              fontWeight: "500",
              width: "90%",
            }}
          >
            Please enter a rating
          </Typography>
          <TextField
            multiline
            rows={4}
            fullWidth
            placeholder={"What do you think?"}
            inputProps={{ maxLength: 500, borderradius: "15px" }}
            {...register("user.comment")}
            sx={{
              marginY: "5px",
              border: "1px solid #000000",
              width: { xs: "323px", sm: "400px" },
              margin: { xs: "5%", md: 0 },
              borderRadius: "15px",
              alignSelf: { md: "center" },
              "& .MuiInputBase-multiline": {
                borderBottomColor: "#000000",
              },
              "& .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#000000",
                },
              "& .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderRadius: "15px",
                },
            }}
          />
          <ButtonSimple
            text={loading ? <CircularProgress color="inherit" /> : "Submit"}
            type={"submit"}
            styles={{
              marginY: { xs: "15px", md: "30px" },
              width: { xs: "300px", md: "343px" },
              height: "44px",
              borderRadius: "15px",
              background: "#262626",
              ":hover": { background: "#262626" },
              div: { alignSelf: "center", width: "100%", textAlign: "center" },
            }}
          />
          <SnackbarBasic
            open={openSnackbar.success}
            styles={{ width: "200px" }}
            autoHideDuration={5000}
            severity={"success"}
            message={"Published  successfully"}
            vertical={"bottom"}
            horizontal={"center"}
          />
          <SnackbarBasic
            open={openSnackbar.error}
            styles={{ width: "auto" }}
            autoHideDuration={5000}
            severity={"error"}
            message={"Sorry, there was an error. Please, try again later"}
            vertical={"bottom"}
            horizontal={"center"}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export const ModalEditReview = ({
  showModalEdit,
  handleCloseEdit,
  myReview,
  reviews,
  setRating,
  idSite,
}) => {
  const [value, setValue] = useState(0);
  const [text, setText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    success: false,
    error: false,
  });

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    if (myReview.rating) {
      setValue(myReview.rating);
    }
  }, [myReview]);

  const onSubmit = ({ user }) => {
    if (localStorage.token) {
      if (!loading && value !== 0) {
        user = { ...user, rating: value };
        setLoading(true);
        axios
          .put(`/reviews/${myReview._id}`, user)
          .then((response) => {
            reviews.forEach((element) => {
              if (element._id === myReview._id) {
                element.rating = user.rating;
                element.comment = user.comment;
              }
            });
            setLoading(false);
            ratingSite(idSite, setRating);
            setOpenSnackbar({ success: true, error: false });
            setTimeout(() => {
              setOpenSnackbar({ success: false, error: false });
              handleCloseEdit();
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setOpenSnackbar({ success: false, error: true });
            setTimeout(() => {
              setOpenSnackbar({ success: false, error: false });
            }, 3000);
          });
      }
    }
  };

  return (
    <Modal
      open={showModalEdit}
      onClose={handleCloseEdit}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: 300, sm: 500 },
          bgcolor: "background.paper",
          border: "1px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: "15px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: 600,
            fontSize: { xs: 18, md: 24 },
            textAlign: "center",
            marginY: { md: 2 },
          }}
        >
          Edit your experience
        </Typography>
        <Box
          component="form"
          autoComplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: { xs: "100%", sm: "75%" },
              alignItems: "center",
              gap: 3,
              marginTop: "16px",
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: 600, fontSize: 14 }}>
              Rate this app:
            </Typography>
            <Rating
              name="simple-controlled"
              value={value}
              sx={{ fontSize: { md: "32px" } }}
              onChange={(event, newValue) => {
                setValue(newValue);
                setText(false);
              }}
            />
          </Box>
          <Typography
            variant="body2"
            sx={{
              display: text ? "block" : "none",
              fontSize: "12px",
              textAlign: "center",
              alignSelf: { md: "center" },
              color: "red",
              fontWeight: "500",
              width: "90%",
            }}
          >
            Please enter a rating
          </Typography>
          <TextField
            multiline
            rows={4}
            fullWidth
            defaultValue={myReview.comment}
            placeholder={"What do you think?"}
            inputProps={{ maxLength: 500, borderradius: "15px" }}
            {...register("user.comment")}
            sx={{
              marginY: "5px",
              border: "1px solid #000000",
              width: { xs: "323px", sm: "400px" },
              margin: { xs: "5%", md: 0 },
              borderRadius: "15px",
              alignSelf: { md: "center" },
              "& .MuiInputBase-multiline": {
                borderBottomColor: "#000000",
              },
              "& .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#000000",
                },
              "& .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderRadius: "15px",
                },
            }}
          />
          <ButtonSimple
            text={loading ? <CircularProgress color="inherit" /> : "Submit"}
            type={"submit"}
            styles={{
              marginY: { xs: "15px", md: "30px" },
              width: { xs: "300px", md: "343px" },
              height: "44px",
              borderRadius: "15px",
              background: "#262626",
              ":hover": { background: "#262626" },
              div: { alignSelf: "center", width: "100%", textAlign: "center" },
            }}
          />
          <SnackbarBasic
            open={openSnackbar.success}
            styles={{ width: "200px" }}
            autoHideDuration={5000}
            severity={"success"}
            message={"Edited  successfully"}
            vertical={"bottom"}
            horizontal={"center"}
          />
          <SnackbarBasic
            open={openSnackbar.error}
            styles={{ width: "auto" }}
            autoHideDuration={5000}
            severity={"error"}
            message={"Sorry, there was an error. Please, try again later"}
            vertical={"bottom"}
            horizontal={"center"}
          />
        </Box>
      </Box>
    </Modal>
  );
};
