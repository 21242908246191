import React from "react";
import { Box, Typography } from '@mui/material';

export const CardMini = ({icon, title, quantity}) => {
    return(
        <Box sx={{display: "flex", alignItems: "center", width: {xs:"90%", sm:"30%"}, gap: 2, paddingX: 2, paddingY: 3, borderRadius: "15px", backgroundColor: "#F5F5F5", boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"}}>
            <Box sx={{border: "solid black 2px", borderRadius: "20px", padding: 1}}>
                {icon}
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", width: "135px"}}>
                <Typography variant="h5" gutterBottom sx={{alignSelf:"center", fontWeight: "bold", fontSize: "14px"}}>{title}</Typography>        
                <Typography variant="h6" gutterBottom sx={{alignSelf:"center", fontWeight: "bold", fontSize: "24px"}}>{quantity}</Typography>        
            </Box>
        </Box>
    )
}