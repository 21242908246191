import React, {forwardRef} from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

//severity = "success" , "error" , "warning" , "info"
//vertical = "top" or "bottom"
//horizontal = "right", "left" , "center"

export const SnackbarBasic = ({open, onClose, duration, severity, message, styles, vertical, horizontal}) => {
  return (
    <Stack spacing={2} sx={{ width: `${styles.width}` }}>
      <Snackbar open={open} autoHideDuration={duration} onClose={onClose}  anchorOrigin={{vertical, horizontal}} key={vertical + horizontal}>
        <Alert onClose={onClose} severity={severity} sx={{ width: `${styles.width}` }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
