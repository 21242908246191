import React, {useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { InputSelect, ButtonSimple } from "../globalComponents/inputs/inputs";
import { Box, Typography, CircularProgress, Paper, Modal, TextField } from '@mui/material';
import { editSite } from "../globalComponents/connections";
import { ClaimSite } from "./FormClaimSite";
import { SnackbarBasic } from "../globalComponents/feedBacks/FeedBacks";
import { Uploader } from "../globalComponents/uploader/Uploader";
import imageNotFound from "../globalComponents/imagens/imageNotFound.png"

const styles = {
    box:{
        display: "Flex",
        justifyContent: "center",
        padding: {xs: "0", md:"2%"},
        flexDirection: "column",
        gap: 1,
    },
    paper: {
        display: "Flex",
        flexDirection: "column",
        gap: 1,
        alignSelf:{md: "center"},
        alignItems:{sm: "center"},
        width:{xs: "100%", md: "70%"},
    },
    form : {
        display: "Flex",
        flexDirection: "column",
        margin: "10px",
        width: {sm:"50%", md: "70%", lg: "100%"},
    },
    h3: {
        fontSize: "18px", 
        textAlign: "initial",
        margin: "5%",
        fontWeight: "700",
    },
    text: {
        fontSize: "12px", 
        textAlign: "initial",
        alignSelf: {md: "center"},
        margin: {xs:"5%", md: 0},
        fontWeight: "500",
        width: {xs: "323px", sm: "400px"},
    },
    input: {
        marginY: "5px",
        marginLeft: {xs:"15px", sm: "30px", lg: 0},
        borderBottom: "1px solid #000000",
        alignSelf: {md: "center"},
        width: {xs: "323px", sm: "400px"},
        "& .MuiInput-underline:after": {
            borderBottomColor: "#000000"
         },
    },
    multiline: {
        marginY: "5px",
        border: "1px solid #000000",
        width: {xs: "323px", sm: "400px"},
        margin: {xs:"5%", md: 0},
        borderRadius: "15px",
        alignSelf: {md: "center"},
        "& .MuiInputBase-multiline": {
            borderBottomColor: "#000000",
         },
    },
    select : {
        width: "141px",
        height: "32px",
        border: "1px solid #DADEE3",
        borderRadius: "15px",
        color: "#7C7C7C",
        marginTop: "10px",
    },
    inputSimple:{
        marginTop: "5px",
        marginBottom: "30px",
        width: {xs: "323px", sm: "400px"},
        alignSelf: {md: "center"},
        fieldset: {
            borderRadius: "15px",
          },
        div:{
            alingItems: "center",
            width: {xs: "333px", sm: "410px"},
        },    
    },
    buttonSubmit: {
        marginY: "30px",
        width: "343px",
        height: "44px",
        borderRadius: "15px",
        background: "#262626",
        ":hover": {
            background: "#262626",
        },
        div:{
            alignSelf: "center",
            width: "100%", 
            textAlign: "center"
        },        
    },
    error: {
        fontSize: "12px",
        color: "red",
        fontWeight: "bold",
        width: "90%",
        overflow: "hidden",
        alignSelf: "center"
    },
    modal:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs:300, sm:500},
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        p: 4,
    }
}

const dataLanguage = [
    {
        id: "001",
        value: "English",
        text: "English",
    },
    {
        id: "002",
        value: "Spanish",
        text: "Spanish",
    },
]

export const FormEditApp = ({setShowEdit, infoSiteEdit, setInfoSiteEdit, setExecuto}) => {
    const xuser = JSON.parse(localStorage.xuser)
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [valueSelect, setValueSelect] = useState("English")
    const [dataOtp, setDataOtp] = useState({})
    const [imagens, setImagens] = useState(infoSiteEdit.media)
    const [colorText, setColorText] = useState({
        one: "#747B84",
        two: "#747B84",
        three: "#747B84",
        four: "#747B84",
    })
    const [textInput, setTextInput] = useState({
        one: "“Mobile web apps” are websites that function in-browser in a way similar to that of content downloaded from app stores.",
        two: "",
        three:"If you´re the owner of this web app, please enter an email (using the site´s URL) to activate admin access.",
        four: "",
    })

    const { register, handleSubmit, watch, formState: { errors }, reset} = useForm();
    const err = errors.user === undefined ? {} : errors.user
    const mediaLogo = watch("user.imageLogo")
    const mediaOne = watch("user.imageOne")
    const mediaTwo = watch("user.imageTwo")
    const mediaThree = watch("user.imageThree")
    const mediaFour = watch("user.imageFour")

    //Select Language
    const handleLanguage = (event) => {
        setValueSelect(event.target.value)
    }

    const handleClose = () => {setShowModal(false)}


    const validation = (user) => {
        let words = user.keywords.split(',')
        const formData = new FormData();
        if(user.admin_email.length !== 0){
            const newSite = {
                name: infoSiteEdit.name,
                description: user.description,
                language: valueSelect,
                keywords: words, 
                user_id: xuser._id,    
                admin_email:user.admin_email,
                old_media: imagens
            }
            formData.append('json', JSON.stringify(newSite));
        } else {
            const newSite = {
                name: infoSiteEdit.name,
                description: user.description,
                language: valueSelect,
                keywords: words, 
                user_id: xuser._id,
                old_media: imagens
            }    
            formData.append('json', JSON.stringify(newSite));
        }

        if(user.imageLogo.length > 0){
            const file = new File([user.imageLogo.item(0)], user.imageLogo.item(0).name, {
                type: user.imageLogo.item(0).type
            });
            formData.append('icon', file);
        }
        if(user.imageOne !== undefined && user.imageOne.length > 0){
            const file = new File([user.imageOne.item(0)], user.imageOne.item(0).name, {
                type: user.imageOne.item(0).type
            });
            formData.append('media', file);
        }
        if(user.imageTwo !== undefined && user.imageTwo.length > 0){
            const file = new File([user.imageTwo.item(0)], user.imageTwo.item(0).name, {
                type: user.imageTwo.item(0).type
            });
            formData.append('media', file);
        }
        if(user.imageThree !== undefined && user.imageThree.length > 0){
            const file = new File([user.imageThree.item(0)], user.imageThree.item(0).name, {
                type: user.imageThree.item(0).type
            });
            formData.append('media', file);
        }
        if(user.imageFour !== undefined && user.imageFour.length > 0){
            const file = new File([user.imageFour.item(0)], user.imageFour.item(0).name, {
                type: user.imageFour.item(0).type
            });
            formData.append('media', file);
        }
        return formData
    }

    const onSubmit = ({user}) => {
        setDataOtp({
            user_id: xuser._id,
            site_url: infoSiteEdit.url,
            email: user.admin_email
        })

        if(!loading){
            setLoading(true)
            const userValidator = validation(user)
            editSite(infoSiteEdit, userValidator, setTextInput, setColorText, setLoading, setShowModal, setOpenSnackbar, setInfoSiteEdit, setShowEdit, setExecuto)
        }
        
    }

    const validatorImage = (imageDB, imageForm) => {
        let image
        if(imageForm !== undefined && imageForm.length > 0){
            imagens.filter((item)=> item !== imageDB)
            image =  URL.createObjectURL(imageForm[0])
        } else if(imageDB !== undefined && imageDB.length > 0){
            image =  imageDB
        } else{
            image =  imageNotFound
        }
        return image
    }

    return(
        <Box sx={styles.box}>
            <Paper sx={styles.paper} elevation={0}>
                <form onSubmit={handleSubmit(onSubmit)} style={styles.form} >
                    <Typography variant="h3" sx={{fontSize: "18px", textAlign: "initial", marginX:"5%", marginTop:"5%", marginBottom: "2%", fontWeight: "700"}}>App Name</Typography>
                    <TextField  defaultValue={infoSiteEdit.name} sx={styles.input} inputProps={{ readOnly: true }} variant="standard"/>
                    <Typography variant="h3" sx={styles.h3}>Mobile Web URL </Typography>
                    <TextField defaultValue={infoSiteEdit.url} inputProps={{ readOnly: true }} sx={styles.input} variant="standard" />
                    <Typography variant="body2" color={colorText.one} sx={styles.text}>{textInput.one} </Typography>
                    <Box style={{display: "flex", marginTop: {md:"15px"}}}>
                        <Typography variant="h3" sx={styles.h3}>Do you own this web app?</Typography>
                        <Typography variant="body2" sx={{ fontSize: "12px", textAlign: {xs:"initial", sm: "end"}, alignSelf: {md: "center"}, margin: {xs:"5%", md: 0}, fontWeight: "500", width: {xs: "57px", sm: "210px", md: "135px"}}}><span style={{color: "red"}}>*</span> Optional</Typography>
                    </Box>
                    <TextField 
                        defaultValue={infoSiteEdit.admin_email === "admin@cantonica.com" ? "" : infoSiteEdit.admin_email} 
                        placeholder={"admin@example.com"} 
                        sx={styles.input}
                        variant="standard" 
                        error={err.admin_email ? true : false}
                        {...register("user.admin_email", {
                            pattern: {
                                value: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                                message: "Please, Enter a valid email"
                         } })}/>
                    <ErrorMessage errors={errors} name="user.admin_email" render={({message}) => (<span style={styles.error}>{message}</span>)} />
                    <Typography variant="body2"  color={colorText.three}  sx={styles.text}>{textInput.three}</Typography>
                    <Typography variant="h3" sx={styles.h3}>Brief description</Typography>
                    <TextField
                        multiline
                        rows={4}
                        fullWidth 
                        defaultValue={infoSiteEdit.description}
                        placeholder={"Description"} 
                        sx={styles.multiline}
                        inputProps={{maxLength: 500, borderradius: "15px" }}
                        error={err.description ? true : false}
                        {...register("user.description", {
                            required: "This field can´t be empty",
                        } )}/>
                    <ErrorMessage errors={errors} name="user.description" render={({message}) => (<span style={styles.error}>{message}</span>)} />
                    <Box sx={{display: "flex", alignSelf: {md:"center"}, marginTop: "30px"}}>
                        <Typography variant="body2" sx={{ fontSize: "12px", textAlign: "initial", margin: {xs:"5%", md: 0},fontWeight: "500",width: {xs: "323px", sm: "250px"}}}>Description language:</Typography>
                        <InputSelect value={valueSelect} styles={styles.select} onChange={handleLanguage} data={dataLanguage}/>
                    </Box>
                    <Typography variant="body2"  color={colorText.two}  sx={styles.text}>{textInput.two}</Typography>
                    <div style={{display: "flex", gap: "2rem", marginTop: "15px"}}>
                        <Typography variant="h3" sx={styles.h3}>Keywords</Typography>
                        <Typography variant="body2" sx={styles.text}>(When editing an app, comas are placed for differentiating keywords)</Typography>
                    </div>
                    <TextField  
                        defaultValue={infoSiteEdit.keywords}                        
                        placeholder={"Description"} 
                        type={"text"} 
                        inputProps={{maxLength: 40, borderRadius: "15px" }}
                        sx={styles.inputSimple}
                        error={err.keywords ? true : false}
                        {...register("user.keywords", {
                            required: "This field can´t be empty",
                    } )}/>
                    <ErrorMessage errors={errors} name="user.keywords" render={({message}) => (<span style={styles.error}>{message}</span>)}/>
                    <Typography variant="h3" sx={styles.h3} marginTop={4}>App Logo</Typography>
                    {/*Image Logo*/}
                    <Box sx={{display: "flex", flexDirection: "column", width: "100%", height: "100%", alignItems: "center"}}>
                        <Box
                            component="img"
                            src={validatorImage(infoSiteEdit.logo, mediaLogo)}
                            alt={"Logo"}
                            sx={{ width: {xs:"70%", sm:"50%"}, height: "100%", borderRadius: { xs: "12px", md: "24px" }, alignSelf: "center" }}/>
                        <Uploader text={"Update Logo"} media={mediaLogo} hookForm={{...register("user.imageLogo")}}/>
                    </Box>
                    <Typography variant="h3" sx={styles.h3} marginTop={4}>Screenshots:</Typography>
                    <Box sx={{display: "grid", alignSelf: {md: "center"}, alignItems: "center",  marginLeft: "20px", marginBottom: "30px", gap: {xs:3, md:7}, gridTemplateColumns: {xs:"150px 150px", md: "180px 180px"}, gridTemplateRows: {xs:"300px 300px", md:"330px 330px"}}}>
                        {/* Image One*/}
                        <Box sx={{display: "flex", flexDirection: "column", width: "100%", height: "100%", alignItems: "center"}}>
                            <Box
                                component="img"
                                src={validatorImage(infoSiteEdit.media[0], mediaOne)} 
                                alt={"Screenshot1"}
                                sx={{ width: "80%", height: "100%",  borderRadius: { xs: "12px", md: "24px" }, alignSelf: "center" }}/>
                            <Uploader text={"Upload"} media={mediaOne} hookForm={{...register("user.imageOne")}}/>
                        </Box>
                        {/* Image Two */}
                        <Box sx={{display: "flex", flexDirection: "column", width: "100%", height: "100%", alignItems: "center"}}>
                            <Box
                                component="img"
                                src={ validatorImage(infoSiteEdit.media[1], mediaTwo)} 
                                alt={"Screenshot2"}
                                sx={{ width: "80%", height: "100%", borderRadius: { xs: "12px", md: "24px" }, alignSelf: "center", }} />
                            <Uploader text={"Upload"} media={mediaTwo} hookForm={{...register("user.imageTwo")}}/>                      
                        </Box>
                        {/* Image Three */}
                        <Box sx={{display: "flex", flexDirection: "column", width: "100%", height: "100%", alignItems: "center"}}>
                            <Box
                                component="img"
                                src={ validatorImage(infoSiteEdit.media[2], mediaThree)} 
                                alt={"Screenshot3"}
                                sx={{ width: "80%", height: "100%", borderRadius: { xs: "12px", md: "24px" }, alignSelf: "center", }} />
                            <Uploader text={"Upload"} media={mediaThree} hookForm={{...register("user.imageThree")}}/>
                        </Box>
                        {/* Image Four */}
                        <Box sx={{display: "flex", flexDirection: "column", width: "100%", height: "100%", alignItems: "center"}}>
                            <Box
                                component="img"
                                src={ validatorImage(infoSiteEdit.media[3], mediaFour)} 
                                alt={"Screenshot4"}
                                sx={{ width: "80%", height: "100%", borderRadius: { xs: "12px", md: "24px" },  alignSelf: "center", }}/>
                            <Uploader text={"Upload"} media={mediaFour} hookForm={{...register("user.imageFour")}}/>
                        </Box>
                    </Box>
                    <Typography variant="body2" sx={{ fontSize: "12px", textAlign: "initial", alignSelf: {md: "center"}, margin: {xs:"5%", md: 0}, fontWeight: "500", width: {xs: "350px"}}}>Screenshots should not reveal sensitive account information.</Typography>
                    <ButtonSimple text={loading? <CircularProgress color="inherit"/> : "Submit"} disabled={loading}  type={"submit"} styles={styles.buttonSubmit}/>
                    <SnackbarBasic open={openSnackbar} styles={{ width: "200px"}} autoHideDuration={5000} severity={"success"} message={"App edited successfully"} vertical={"bottom"} horizontal={"center"}/>
                    <Typography variant="body2"  color={colorText.four}  sx={styles.text}>{textInput.four}</Typography>
                </form>
                <Modal open={showModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={styles.modal}>
                        <ClaimSite setShow={setShowModal} resetForm={reset} dataOtp={dataOtp} setDataOtp={setDataOtp} setShowEdit={setShowEdit} setExecuto={setExecuto}/>
                    </Box>
                </Modal>
            </Paper>
        </Box>
    )
}
