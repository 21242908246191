import React from "react";
import { Box, Typography } from "@mui/material";
import Fondo from "./globalComponents/imagens/backgroundInitial.png"
import Cantonica from "./globalComponents/imagens/cantonica.png"
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
    const navigate = useNavigate();

    return(
        <Box sx={{height: {xs: "90vh", lg: "100vh"}, display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <Box
                component="img"
                src={Fondo}
                sx={{display: {xs: "none", lg:"block"}, width: "-webkit-fill-available", height:"-webkit-fill-available", position: "absolute", zIndex:0}}
            />
            <Box
                component="img"
                src={Cantonica}
                alt={"Logo_Cantonica"}
                sx={{width: {xs: "100px", sm: "200px", md:"250px"}, alignSelf: "center",  zIndex:5, marginTop: {sm:"30px"}}}
            />
            <Box sx={{display: "flex", zIndex:5, flexDirection: "column", alignSelf: "center", marginTop: 3, gap: {xs: 1, lg: 0},width:{xs: "100%", md: "50%"}}}>
                <Typography variant="h2" sx={{fontSize: {xs:"28px", md:"45px"}, alignSelf: "center",margin: "5%", fontWeight: "bold"}}>
                    ¡Upps! Error 4<span style={{color:"red"}}>0</span>4
                </Typography>
                <Typography variant="h6" sx={{fontSize: {xs:"23px", sm:"28px"}, alignSelf:{ xs: "flex-start",  sm: "center"},margin: {xs:"5%", lg:"3%"}, fontWeight: "bold", width:{md:"100%"}}}>
                    We are sorry, but this page is not available.
                </Typography>
                <Box sx={{width:"100%", display:"flex", flexDirection:"column"}}>
                    <Typography variant="h6" sx={{fontSize: {xs:"15px", sm:"23px"}, alignSelf:"center",margin: "5%", fontWeight: "bold"}}>
                        Don't worry, we'll help you get back to the
                    </Typography>
                    <Typography variant="h6" sx={{fontSize: {xs:"24px", sm:"30px"}, alignSelf: "center", fontWeight: "bold", color: "red"}} onClick={()=>{navigate("/")}}>
                        Homepage
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}