import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  useMediaQuery,
  Button,
  Popover,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import EmailIcon from "@mui/icons-material/Email";
import logo from "../components/globalComponents/imagens/logo.svg";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const isGreaterThan750 = useMediaQuery("(min-width: 750px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: "#FFF0E3",
        paddingLeft: "1.5vw",
        paddingRight: "1.5vw",
        boxShadow: 0,
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "80px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: isMd ? "85px" : "172px",
          }}
        >
          {!isGreaterThan750 ? (
            <Box>
              <MenuIcon
                id="demo-positioned-button"
                sx={{
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={handleClick}
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              />
              <Popover
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem onClick={() => navigate("/")}>
                  <ListItemIcon>
                    <HomeIcon fontSize="small" />
                  </ListItemIcon>
                  Home
                </MenuItem>
                <MenuItem onClick={() => navigate("/aboutus")}>
                  <ListItemIcon>
                    <InfoIcon fontSize="small" />
                  </ListItemIcon>
                  About Us
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <StickyNote2Icon fontSize="small" />
                  </ListItemIcon>
                  Blogs
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <EmailIcon fontSize="small" />
                  </ListItemIcon>
                  Get in Touch
                </MenuItem>
              </Popover>
            </Box>
          ) : (
            <></>
          )}

          <img src={logo} alt="Cantonica Logo" />
          {isMd ? (
            <></>
          ) : (
            <Typography
              sx={{ fontSize: "20px", color: "black", fontWeight: "700" }}
            >
              CANTONICA
            </Typography>
          )}
        </Box>

        {isGreaterThan750 ? (
          <Box
            sx={{
              display: "flex",
              color: "#A1550F",
              gap: isMd ? 1 : 2,
            }}
          >
            <Button
              href="/"
              sx={{ color: "inherit", fontWeight: "600", fontSize: "16px" }}
            >
              Home
            </Button>
            <Button
              href="#"
              sx={{ color: "inherit", fontWeight: "600", fontSize: "16px" }}
            >
              About Us
            </Button>
            <Button
              href="#"
              sx={{ color: "inherit", fontWeight: "600", fontSize: "16px" }}
            >
              Blogs
            </Button>
            <Button
              href="#"
              sx={{ color: "inherit", fontWeight: "600", fontSize: "16px" }}
            >
              Get in Touch
            </Button>
          </Box>
        ) : (
          <></>
        )}

        <Box
          sx={{
            display: "flex",
            color: "#A1550F",
            gap: isMd ? 1 : 2,
          }}
        >
          <Button
            href="/login"
            sx={{ color: "inherit", fontWeight: "600", fontSize: "16px" }}
          >
            Log in
          </Button>
          <Button
            href="/signup"
            sx={{
              color: "white",
              fontWeight: "600",
              bgcolor: "#D27B2C",
              borderRadius: 2,
              "&:hover": {
                color: "black",
              },
              fontSize: "16px",
              width: "95px",
              height: "44px",
              padding: 0,
            }}
          >
            Sign up
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
