import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ErrorMessage } from "@hookform/error-message";
import {
  Box,
  Typography,
  CircularProgress,
  TextField,
  Modal,
} from "@mui/material";
import { InputPassword, ButtonSimple } from "../globalComponents/inputs/inputs";
import { SnackbarBasic } from "../globalComponents/feedBacks/FeedBacks";

export const FormEditProfile = ({ closeModal }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const err = errors.user === undefined ? {} : errors.user;
  const createPassword = watch("user.createPassword");
  const confirmPassword = watch("user.confirmPassword");

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const [contact, setContact] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState({
    message1: "",
    message2: "",
  });

  let xuser =
    localStorage.xuser !== undefined
      ? JSON.parse(localStorage.xuser)
      : navigate("/Login");

  const handleClose = () => {
    setShowModal(false);
  };

  const onSubmit = ({ user }) => {
    setContact({
      ...contact,
      email: user.email,
      name: user.name,
      user_id: xuser._id,
      password: user.createPassword,
    });
    if (!loading && user.createPassword === user.confirmPassword) {
      setLoading(true);
      user = {
        ...user,
        password: user.createPassword,
      };
      axios
        .put(`/users/${xuser._id}/information`, user)
        .then((response) => {
          if (xuser.name !== user.name) {
            xuser.name = user.name;
            localStorage.xuser = JSON.stringify(xuser);
            xuser = JSON.parse(localStorage.xuser);
          }

          if (xuser.email !== user.email) {
            setShowModal(true);
          } else {
            setOpenSnackbar({ success: true, error: false });
            setTimeout(() => {
              setOpenSnackbar({ success: false, error: false });
              closeModal(false);
              reset();
            }, 5000);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          setOpenSnackbar({ success: false, error: true });
          setTimeout(() => {
            setOpenSnackbar({ success: false, error: false });
          }, 5000);
        });
    }
    if (!loading && !user.createPassword) {
      setLoading(true);
      axios
        .put(`/users/${xuser._id}/information`, user)
        .then((response) => {
          if (xuser.name !== user.name) {
            xuser.name = user.name;
            localStorage.xuser = JSON.stringify(xuser);
            xuser = JSON.parse(localStorage.xuser);
          }
          if (xuser.email !== user.email) {
            setShowModal(true);
          } else {
            setOpenSnackbar({ success: true, error: false });
            setTimeout(() => {
              setOpenSnackbar({ success: false, error: false });
              closeModal(false);
              reset();
            }, 5000);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          setOpenSnackbar({ success: false, error: true });
          setTimeout(() => {
            setOpenSnackbar({ success: false, error: false });
          }, 5000);
        });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        margin: "2%",
        paddingBlock: "20px",
        alignSelf: "center",
        marginTop: 3,
        gap: { xs: 1, lg: 0 },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontSize: { xs: "28px", sm: "32px" },
          textAlign: "center",
          width: "100%",
          alignSelf: "center",
          marginY: "5%",
          fontWeight: "bold",
        }}
      >
        Edit Profile
      </Typography>
      <Box
        component="form"
        autoComplete="off"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          alignItems: "center",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          label={"Email"}
          defaultValue={xuser.email}
          error={err.email ? true : false}
          type={"text"}
          inputProps={{ maxLength: 40, borderradius: "15px" }}
          sx={{
            marginY: "5px",
            marginTop: "20px",
            width: { xs: "343px", md: "400px" },
            height: { xs: "40px", md: "35px" },
            fieldset: { borderRadius: "15px" },
            div: { alingItems: "center" },
          }}
          {...register("user.email", {
            required: "This field can´t be empty",
          })}
        />
        <ErrorMessage
          errors={err}
          name="user.email"
          render={({ message }) => (
            <span
              style={{
                fontSize: "12px",
                color: "red",
                fontWeight: "bold",
                width: "90%",
                overflow: "hidden",
                alignSelf: "center",
              }}
            >
              {message}
            </span>
          )}
        />
        <TextField
          defaultValue={xuser.name}
          error={err.name ? true : false}
          label={"Name"}
          type={"text"}
          inputProps={{ maxLength: 40, borderradius: "15px" }}
          sx={{
            marginY: "5px",
            marginTop: "20px",
            width: { xs: "343px", md: "400px" },
            height: { xs: "40px", md: "35px" },
            fieldset: { borderRadius: "15px" },
            div: { alingItems: "center" },
          }}
          {...register("user.name", {
            required: "This field can´t be empty",
          })}
        />
        <ErrorMessage
          errors={err}
          name="user.name"
          render={({ message }) => (
            <span
              style={{
                fontSize: "12px",
                color: "red",
                fontWeight: "bold",
                width: "90%",
                overflow: "hidden",
                alignSelf: "center",
              }}
            >
              {message}
            </span>
          )}
        />
        <InputPassword
          label={"Change Password"}
          styles={{
            marginY: "5px",
            marginTop: "20px",
            width: { xs: "343px", md: "400px" },
            height: { xs: "40px", md: "35px" },
            fieldset: { borderRadius: "15px" },
            div: { alingItems: "center" },
          }}
          error={err.createPassword ? true : false}
          inputProps={{ maxLength: 16 }}
          showPassword={showPasswordOne}
          setShowPassword={setShowPasswordOne}
          hookForm={{
            ...register("user.createPassword", {
              pattern: {
                value: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
                message:
                  "The password must be between 8 and 16 characters, at least one digit, one lower case and one upper case.",
              },
              validate: (value) => {
                value !== confirmPassword
                  ? setMessage({
                      ...message,
                      message1: "Passwords do not match",
                    })
                  : setMessage({ message2: "", message1: "" });
              },
            }),
          }}
        />
        <ErrorMessage
          errors={errors}
          name="user.createPassword"
          render={({ message }) => (
            <span
              style={{
                fontSize: "12px",
                marginTop: 16,
                color: "red",
                fontWeight: "bold",
                width: "75%",
                overflow: "hidden",
                alignSelf: "center",
              }}
            >
              {message}
            </span>
          )}
        />
        {createPassword ? (
          <>
            <span
              style={{
                fontSize: "12px",
                marginTop: 16,
                color: "red",
                fontWeight: "bold",
                width: "75%",
                overflow: "hidden",
                alignSelf: "center",
              }}
            >
              {message.message1}
            </span>
            <InputPassword
              label={"Confirm Password"}
              error={err.confirmPassword ? true : false}
              inputProps={{ maxLength: 16 }}
              styles={{
                marginY: "5px",
                marginTop: "20px",
                width: { xs: "343px", md: "400px" },
                height: { xs: "40px", md: "35px" },
                fieldset: { borderRadius: "15px" },
                div: { alingItems: "center" },
              }}
              showPassword={showPasswordTwo}
              setShowPassword={setShowPasswordTwo}
              hookForm={{
                ...register("user.confirmPassword", {
                  pattern: {
                    value: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
                    message:
                      "The password must be between 8 and 16 characters, at least one digit, one lower case and one upper case.",
                  },
                  validate: (value) => {
                    value !== createPassword
                      ? setMessage({
                          ...message,
                          message2: "Passwords do not match",
                        })
                      : setMessage({ message1: "", message2: "" });
                  },
                }),
              }}
            />
            <ErrorMessage
              errors={errors}
              name="user.confirmPassword"
              render={({ message }) => (
                <span
                  style={{
                    fontSize: "12px",
                    marginTop: 16,
                    color: "red",
                    fontWeight: "bold",
                    width: "75%",
                    overflow: "hidden",
                    alignSelf: "center",
                  }}
                >
                  {message}
                </span>
              )}
            />
            <span
              style={{
                fontSize: "12px",
                marginTop: 16,
                color: "red",
                fontWeight: "bold",
                width: "75%",
                overflow: "hidden",
                alignSelf: "center",
              }}
            >
              {message.message2}
            </span>
          </>
        ) : (
          ""
        )}
        <ButtonSimple
          styles={{
            marginY: "30px",
            width: { xs: "343px", md: "400px" },
            height: "40px",
            borderRadius: "15px",
            background: "var(--btn-green)",
            ":hover": { background: "var(--btn-green)" },
          }}
          text={loading ? <CircularProgress color="inherit" /> : "Continue"}
          type={"submit"}
        />
        <SnackbarBasic
          open={openSnackbar.success}
          styles={{ width: "300px" }}
          autoHideDuration={5000}
          severity={"success"}
          message={"Changed successfully"}
          vertical={"bottom"}
          horizontal={"center"}
        />
        <SnackbarBasic
          open={openSnackbar.error}
          styles={{ width: "300px" }}
          autoHideDuration={5000}
          severity={"error"}
          message={"Sorry, there was a problem. Please try again later"}
          vertical={"bottom"}
          horizontal={"center"}
        />
      </Box>
      <Modal
        open={showModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 300, sm: 500 },
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
          }}
        >
          <ChangeEmail
            contact={contact}
            setContact={setContact}
            setShowModal={setShowModal}
            resetForm={reset}
            closeModal={closeModal}
          />
        </Box>
      </Modal>
    </Box>
  );
};

const ChangeEmail = ({
  contact,
  setContact,
  setShowModal,
  resetForm,
  closeModal,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState({
    success: false,
    error: false,
  });
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    setFocus,
    reset,
    formState: { errors },
  } = useForm();
  const code1 = watch("user.code1");
  const code2 = watch("user.code2");
  const code3 = watch("user.code3");
  const code4 = watch("user.code4");
  const code5 = watch("user.code5");
  const code6 = watch("user.code6");

  useEffect(() => {
    if (code1) {
      setFocus("user.code2");
    }
    if (code2) {
      setFocus("user.code3");
    }
    if (code3) {
      setFocus("user.code4");
    }
    if (code4) {
      setFocus("user.code5");
    }
    if (code5) {
      setFocus("user.code6");
    }
    if (code6) {
      if (code1 === "") {
        setFocus("user.code1");
      }
      if (code2 === "") {
        setFocus("user.code2");
      }
      if (code3 === "") {
        setFocus("user.code3");
      }
      if (code4 === "") {
        setFocus("user.code4");
      }
      if (code5 === "") {
        setFocus("user.code5");
      }
      if (code6 === "") {
        setFocus("user.code6");
      }
    }
  }, [code1, code2, code3, code4, code5, setFocus, code6]);

  const onSubmit = ({ user }) => {
    if (!loading && localStorage.xuser) {
      let xuser = JSON.parse(localStorage.xuser);
      user = {
        user_id: contact.user_id,
        code: code1 + code2 + code3 + code4 + code5 + code6,
      };
      setLoading(true);
      axios
        .post(`/users/validate_otp`, user)
        .then((response) => {
          setLoading(false);
          xuser.email = contact.email;
          localStorage.xuser = JSON.stringify(xuser);
          xuser = JSON.parse(localStorage.xuser);
          setOpenSnackbar({ success: true, error: false });
          setTimeout(() => {
            reset();
            resetForm();
            setShowModal(false);
            closeModal(false);
            setContact({});
            setOpenSnackbar({ success: false, error: false });
          }, 3000);
        })
        .catch((error) => {
          const err = error.response.data;
          if (
            err.error ===
            "an error occurred while trying to update the email, please try again later."
          ) {
            setText(
              "An error occurred while trying to update the email, please try again later."
            );
          } else {
            reset();
            setLoading(false);
            setOpenSnackbar({ success: false, error: true });
            setTimeout(() => {
              setOpenSnackbar({ success: false, error: false });
            }, 4000);
          }
        });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "2%",
          paddingBlock: "20px",
          alignSelf: "center",
          marginTop: 3,
          gap: { xs: 1, lg: 0 },
        }}
      >
        <Box
          sx={{
            alignSelf: "center",
            width: { xs: "100%", md: "75%" },
            marginBottom: { xs: "25px", md: "30px" },
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "28px", sm: "32px" },
              textAlign: "center",
              width: "100%",
              alignSelf: "center",
              marginY: "5%",
              fontWeight: "bold",
            }}
          >
            Email Change Validation
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              color: "var(--black-100)",
              textAlign: "justify",
              marginTop: "20px",
              width: "100%",
              fontWeight: 600,
              fontSize: "13px",
            }}
          >
            Check your new email and enter the code received to confirm your
            changes.
          </Typography>
        </Box>
        <Box
          component="form"
          autoComplete="off"
          style={{ display: "flex", flexDirection: "column" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            sx={{
              display: "flex",
              width: "90%",
              alignSelf: "center",
              justifyContent: "space-evenly",
            }}
          >
            <TextField
              required
              type={"text"}
              error={errors.code1 ? true : false}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 1,
                borderradius: "15px",
              }}
              sx={{
                marginTop: "5px",
                marginBottom: "20px",
                width: "40px",
                height: { xs: "40px", md: "38px" },
                fieldset: { borderRadius: "15px" },
                div: { alingItems: "center" },
              }}
              {...register("user.code1", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^\d{1}$/,
                  message: "Please, enter the digit.",
                },
              })}
            />
            <TextField
              required
              type={"text"}
              error={errors.code2 ? true : false}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 1,
                borderradius: "15px",
              }}
              sx={{
                marginTop: "5px",
                marginBottom: "20px",
                width: "40px",
                height: { xs: "40px", md: "38px" },
                fieldset: { borderRadius: "15px" },
                div: { alingItems: "center" },
              }}
              {...register("user.code2", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^\d{1}$/,
                  message: "Please, enter the digit.",
                },
              })}
            />
            <TextField
              type={"text"}
              error={errors.code3 ? true : false}
              required
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 1,
                borderradius: "15px",
              }}
              sx={{
                marginTop: "5px",
                marginBottom: "20px",
                width: "40px",
                height: { xs: "40px", md: "38px" },
                fieldset: { borderRadius: "15px" },
                div: { alingItems: "center" },
              }}
              {...register("user.code3", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^\d{1}$/,
                  message: "Please, enter the digit.",
                },
              })}
            />
            <TextField
              required
              error={errors.code4 ? true : false}
              type={"text"}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 1,
                borderradius: "15px",
              }}
              sx={{
                marginTop: "5px",
                marginBottom: "20px",
                width: "40px",
                height: { xs: "40px", md: "38px" },
                fieldset: { borderRadius: "15px" },
                div: { alingItems: "center" },
              }}
              {...register("user.code4", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^\d{1}$/,
                  message: "Please, enter the digit.",
                },
              })}
            />
            <TextField
              type={"text"}
              error={errors.code5 ? true : false}
              required
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 1,
                borderradius: "15px",
              }}
              sx={{
                marginTop: "5px",
                marginBottom: "20px",
                width: "40px",
                height: { xs: "40px", md: "38px" },
                fieldset: { borderRadius: "15px" },
                div: { alingItems: "center" },
              }}
              {...register("user.code5", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^\d{1}$/,
                  message: "Please, enter the digit.",
                },
              })}
            />
            <TextField
              required
              error={errors.code6 ? true : false}
              type={"text"}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 1,
                borderradius: "15px",
              }}
              sx={{
                marginTop: "5px",
                marginBottom: "20px",
                width: "40px",
                height: { xs: "40px", md: "38px" },
                fieldset: { borderRadius: "15px" },
                div: { alingItems: "center" },
              }}
              {...register("user.code6", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^\d{1}$/,
                  message: "Please, enter the digit.",
                },
              })}
            />
          </Box>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              fontSize: "12px",
              marginTop: 16,
              color: "red",
              fontWeight: "bold",
              width: "75%",
              overflow: "hidden",
              alignSelf: "center",
            }}
          >
            {text}
          </Typography>
          <ButtonSimple
            styles={{
              marginY: "30px",
              width: { xs: "300px", md: "400px" },
              height: "50px",
              borderRadius: "15px",
              background: "#262626",
              ":hover": { background: "#262626" },
              div: { display: "flex", justifyContent: "center" },
            }}
            text={
              loading ? <CircularProgress color="inherit" /> : "Verify Code"
            }
            type={"submit"}
          />
          <SnackbarBasic
            open={openSnackbar.success}
            styles={{ width: "300px" }}
            autoHideDuration={5000}
            severity={"success"}
            message={"Email changed successfully"}
            vertical={"bottom"}
            horizontal={"center"}
          />
          <SnackbarBasic
            open={openSnackbar.error}
            styles={{ width: "300px" }}
            autoHideDuration={5000}
            severity={"error"}
            message={
              "The code you entered is incorrect. Please try again or send a new email."
            }
            vertical={"bottom"}
            horizontal={"center"}
          />
        </Box>
      </Box>
    </Box>
  );
};
