import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SnackbarBasic } from "../globalComponents/feedBacks/FeedBacks";
import {
  InputPassword,
  InputSimple,
  ButtonSimple,
} from "../globalComponents/inputs/inputs";
import Fondo from "../globalComponents/imagens/backgroundInitial.png";
import Cantonica from "../globalComponents/imagens/cantonica.png";
import axios from "axios";

const styles = {
  box: {
    height: { lg: "100vh" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    width: { xs: "100%", md: "50%", lg: "40%" },
    margin: "2%",
    paddingBlock: "20px",
    zIndex: 5,
    alignSelf: "center",
    marginTop: 3,
    gap: { xs: 1, lg: 0 },
    boxShadow: {
      xs: "none",
      md: "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    },
  },
  h2: {
    fontSize: "32px",
    textAlign: { xs: "center", md: "start" },
    width: "75%",
    alignSelf: "center",
    margin: { xs: "5%", md: 0 },
    fontWeight: "bold",
  },
  div: {
    alignSelf: "center",
    width: { xs: "100%", md: "70%" },
    marginY: { xs: "25px", md: "30px" },
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginTop: "5px",
    marginBottom: "20px",
    width: { xs: "343px", md: "400px" },
    height: { xs: "40px", md: "38px" },
    fieldset: {
      borderRadius: "15px",
    },
    div: {
      alingItems: "center",
    },
  },
  button: {
    marginY: "30px",
    width: { xs: "343px", md: "400px" },
    height: "50px",
    borderRadius: "15px",
    background: "var(--btn-green)",
    ":hover": {
      background: "var(--btn-green)",
    },
  },
  typography: {
    color: "var(--black-100)",
    marginY: "20px",
    width: "100%",
    cursor: "pointer",
    fontWeight: "600",
    fontSize: "13px",
  },
  span: {
    marginBlock: "10px",
    color: "#F60000",
    fontWeight: "600",
    fontSize: "13px",
  },
  error: {
    fontSize: "12px",
    color: "red",
    fontWeight: "bold",
    width: "90%",
    overflow: "hidden",
    alignSelf: "center",
    marginBottom: "15px",
  },
  snackbar: {
    width: "200px",
  },
};

export const FormRecovery = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState("");
  const [contact, setContact] = useState("");
  const [information, setInformation] = useState(
    "Please enter your email to reset your password"
  );
  const [title, setTitle] = useState("Forgot Password");

  const hadleReturn = () => {
    if (visible === "passwords") {
      setVisible("code");
      setTitle("Email is Sent");
      setInformation("Please enter the code that was sent by email");
    } else if (visible === "code") {
      setVisible("");
      setTitle("Forgot Password");
      setInformation("Please enter your email to reset your password");
    } else {
      navigate(-1);
    }
  };

  return (
    <Box sx={styles.box}>
      <Box
        component="img"
        src={Fondo}
        sx={{
          display: { xs: "none", lg: "block" },
          width: "-webkit-fill-available",
          height: "-webkit-fill-available",
          position: "absolute",
          zIndex: 0,
        }}
      />
      <Box
        component="img"
        src={Cantonica}
        alt={"Logo_Cantonica"}
        sx={{
          width: { xs: "100px", sm: "200px", md: "250px" },
          alignSelf: "center",
          zIndex: 5,
          marginTop: { xs: "30px", md: 0 },
        }}
      />
      <Paper sx={styles.paper}>
        <Box
          sx={{
            display: "flex",
            marginTop: { md: "30px" },
            justifyContent: { xs: "flex-start", md: "space-around" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <IconButton
            aria-label="back"
            onClick={hadleReturn}
            sx={{ display: { xs: "none", md: "block" }, width: "70px" }}
          >
            <ArrowBackIcon
              sx={{ fontSize: "30px", width: "70px", color: "#000" }}
            />
          </IconButton>
          <Typography variant="h2" sx={styles.h2}>
            {title}
          </Typography>
        </Box>
        <Box sx={styles.div}>
          <Typography variant="subtitle1" gutterBottom>
            {information}
          </Typography>
        </Box>
        {(() => {
          switch (visible) {
            case "code":
              return (
                <FormCode
                  contact={contact}
                  setVisible={setVisible}
                  setInformation={setInformation}
                  setTitle={setTitle}
                />
              );
            case "passwords":
              return <FormPasswords contact={contact} />;
            default:
              return (
                <FormEmail
                  setContact={setContact}
                  setVisible={setVisible}
                  setInformation={setInformation}
                  setTitle={setTitle}
                />
              );
          }
        })()}
      </Paper>
    </Box>
  );
};

const FormEmail = ({ setContact, setVisible, setInformation, setTitle }) => {
  const [loading, setLoading] = useState(false);
  const [snackbarSuccess, SetSnackbarSuccess] = useState(false);
  const [snackbarError, SetSnackbarError] = useState({
    text: "",
    show: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const err = errors.user === undefined ? {} : errors.user;

  const onSubmit = ({ user }) => {
    setLoading(true);
    axios
      .post(`/users/set_password`, user)
      .then((response) => {
        SetSnackbarSuccess(true);
        setLoading(false);
        setTimeout(() => {
          SetSnackbarSuccess(false);
          setVisible("code");
        }, 3000);
        const idUser = response.data.user_id;
        setContact(idUser);
        setTitle("Email is Sent");
        setInformation("Please enter the code that was sent by email.");
      })
      .catch((error) => {
        const err = error.response.data;
        setLoading(false);
        if (err.message === "An unexpected error has occurred on the server.") {
          SetSnackbarError({
            show: true,
            text: "Email not registered, please enter a valid email.",
          });
          setTimeout(() => {
            SetSnackbarError({ show: false, text: "" });
          }, 4000);
        } else {
          SetSnackbarError({
            show: true,
            text: "Sorry, there was a problem. Please try again later",
          });
          setTimeout(() => {
            SetSnackbarError({ show: false, text: "" });
          }, 4000);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={styles.form}>
      <InputSimple
        label={"Email"}
        error={err.email ? true : false}
        inputProps={{ maxLength: 40 }}
        type={"text"}
        styles={styles.input}
        hookForm={{
          ...register("user.email", {
            required: "This field can´t be empty",
            pattern: {
              value:
                /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
              message: "Please, Enter a valid email",
            },
          }),
        }}
      />
      <ErrorMessage
        errors={errors}
        name="user.email"
        render={({ message }) => <span style={styles.error}>{message}</span>}
      />
      <ButtonSimple
        styles={styles.button}
        text={loading ? <CircularProgress color="inherit" /> : "Send Code"}
        type={"submit"}
      />
      <SnackbarBasic
        open={snackbarSuccess}
        styles={{ width: "300px" }}
        autoHideDuration={5000}
        severity={"success"}
        message={"Code sent to your email"}
        vertical={"bottom"}
        horizontal={"center"}
      />
      <SnackbarBasic
        open={snackbarError.show}
        styles={{ width: "300px" }}
        autoHideDuration={5000}
        severity={"error"}
        message={snackbarError.text}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </form>
  );
};

const FormCode = ({ contact, setInformation, setVisible, setTitle }) => {
  const [loading, setLoading] = useState(false);
  const [snackbarSuccess, SetSnackbarSuccess] = useState(false);
  const [snackbarError, SetSnackbarError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const err = errors.user === undefined ? {} : errors.user;

  const onSubmit = ({ user }) => {
    setLoading(true);
    user = {
      ...user,
      user_id: contact,
    };
    axios
      .post(`/users/validate_otp`, user)
      .then((response) => {
        SetSnackbarSuccess(true);
        setLoading(false);
        setTimeout(() => {
          SetSnackbarSuccess(false);
        }, 3000);
        setTitle("Change your Password");
        setInformation("Please, enter your new password");
        setVisible("passwords");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        SetSnackbarError(true);
        setTimeout(() => {
          SetSnackbarError(false);
        }, 3000);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={styles.form}>
      <InputSimple
        label={"Code"}
        error={err.code ? true : false}
        type={"text"}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
          maxLength: 6,
          borderRadius: "15px",
        }}
        styles={styles.input}
        hookForm={{
          ...register("user.code", {
            required: "This field can´t be empty",
            pattern: {
              value: /^\d{6}$/,
              message: "Please, enter the six digit code",
            },
          }),
        }}
      />
      <ErrorMessage
        errors={errors}
        name="user.code"
        render={({ message }) => <span style={styles.error}>{message}</span>}
      />
      <ButtonSimple
        styles={styles.button}
        text={loading ? <CircularProgress color="inherit" /> : "Verify Code"}
        type={"submit"}
      />
      <SnackbarBasic
        open={snackbarSuccess}
        styles={{ width: "300px" }}
        autoHideDuration={5000}
        severity={"success"}
        message={"Valid code."}
        vertical={"bottom"}
        horizontal={"center"}
      />
      <SnackbarBasic
        open={snackbarError}
        styles={{ width: "300px" }}
        autoHideDuration={5000}
        severity={"error"}
        message={"Invalid code"}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </form>
  );
};

const FormPasswords = ({ contact }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [snackbarSuccess, SetSnackbarSuccess] = useState(false);
  const [snackbarError, SetSnackbarError] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const [message, setMessage] = useState({
    message1: "",
    message2: "",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const err = errors.user === undefined ? {} : errors.user;
  const createPassword = watch("user.password");
  const confirmPassword = watch("user.confirmPassword");

  const onSubmit = ({ user }) => {
    if (message.message1 === "" && message.message2 === "") {
      if (createPassword === confirmPassword) {
        setLoading(true);
        axios
          .put(`/users/${contact}/information`, user)
          .then((response) => {
            setLoading(false);
            SetSnackbarSuccess(true);
            setTimeout(() => {
              SetSnackbarSuccess(false);
              navigate("/");
            }, 4000);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            SetSnackbarError(true);
            setTimeout(() => {
              SetSnackbarError(false);
            }, 4000);
          });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={styles.form}>
      <InputPassword
        label={"New Password"}
        error={err.newPassword ? true : false}
        styles={styles.input}
        showPassword={showPasswordOne}
        setShowPassword={setShowPasswordOne}
        hookForm={{
          ...register("user.password", {
            required: "This field can´t be empty",
            pattern: {
              value: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
              message:
                "The password must be between 8 and 16 characters, at least one digit, one lower case and one upper case.",
            },
            validate: (value) => {
              value !== confirmPassword
                ? setMessage({ ...message, message1: "Passwords do not match" })
                : setMessage({ message2: "", message1: "" });
            },
          }),
        }}
      />
      <ErrorMessage
        errors={errors}
        name="user.newPassword"
        render={({ message }) => <span style={styles.error}>{message}</span>}
      />
      <span style={styles.error}>{message.message1}</span>
      <InputPassword
        label={"Confirm Password"}
        error={err.confirmPassword ? true : false}
        styles={styles.input}
        showPassword={showPasswordTwo}
        setShowPassword={setShowPasswordTwo}
        hookForm={{
          ...register("user.confirmPassword", {
            required: "This field can´t be empty",
            pattern: {
              value: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
              message:
                "The password must be between 8 and 16 characters, at least one digit, one lower case and one upper case.",
            },
            validate: (value) => {
              value !== createPassword
                ? setMessage({ ...message, message2: "Passwords do not match" })
                : setMessage({ message1: "", message2: "" });
            },
          }),
        }}
      />
      <ErrorMessage
        errors={errors}
        name="user.confirmPassword"
        render={({ message }) => <span style={styles.error}>{message}</span>}
      />
      <span style={styles.error}>{message.message2}</span>
      <SnackbarBasic
        open={snackbarSuccess}
        styles={styles.snackbar}
        autoHideDuration={5000}
        severity={"success"}
        message={"Successful change"}
        vertical={"bottom"}
        horizontal={"center"}
      />
      <SnackbarBasic
        open={snackbarError}
        styles={styles.snackbar}
        autoHideDuration={5000}
        severity={"error"}
        message={"We're sorry. there was an error, please try again."}
        vertical={"bottom"}
        horizontal={"center"}
      />
      <ButtonSimple
        styles={styles.button}
        text={
          loading ? <CircularProgress color="inherit" /> : "Change Password"
        }
        type={"submit"}
      />
    </form>
  );
};
