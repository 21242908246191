import React from "react";
import { Box, Typography, Rating } from '@mui/material';


export const CardReview = ({review}) => {
    return(
        <Box sx={{display: "flex", flexDirection: "column",  borderRadius: "15px", background: "#F5F5F5", gap: "0.5rem", textAlign: "justify", padding: "2rem"}}>
            <Box sx={{display: "flex", justifyContent:"space-between"}}>
                <Typography variant="h4" sx={{color: "var(--black-100)", fontWeight: 700, fontSize: {xs:12, md: 14}, width: "50%"}}>{review.user_name}</Typography>
                <Typography variant="h4" sx={{color: "#cfcfcf", fontWeight: 700, fontSize: {xs:12, md: 14}}}>{review.newDate}</Typography>
            </Box>
            <Rating name="read-only" value={review.rating} precision={0.5} sx={{fontSize: {md:"28px"}}} readOnly/>
            <Typography variant="body" sx={{color: "#333", fontWeight: 500, fontSize: {xs:12, md: 14}}}>{review.comment}</Typography>
        </Box>
    )
}