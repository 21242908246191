import React, { useState, useEffect } from "react";
import { Modal, Box, Typography } from "@mui/material";
import { TableDashboard } from "./globalComponents/tables/TableDashboard";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { searcher } from "./globalComponents/connections";
import Logo from "../components/globalComponents/imagens/cantonica.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PersonIcon from "@mui/icons-material/Person";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import { ButtonSimple } from "./globalComponents/inputs/inputs";
import { FormEditApp } from "./forms/FormEditApp";
import { CardMini } from "./globalComponents/cards/CardMini";

const stylesTableTop = {
  display: "flex",
  gap: 2,
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  alignSelf: "start",
  overflowY: "scroll",
  height: "90vh",
  boxShadow: {
    xs: "none",
    md: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  "::-webkit-scrollbar ": {
    WebkitAppearance: "none",
  },
  "::-webkit-scrollbar:vertical": {
    width: "8px",
  },
  "::-webkit-scrollbar-button:increment": {
    display: "none",
  },
  "::-webkit-scrollbar-button": {
    display: "none",
  },
  "::-webkit-scrollbar:horizontal": {
    height: "8px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#797979",
    borderRadius: "20px",
    border: "2px solid #f1f2f3",
  },
  "::-webkit-scrollbar-track": {
    borderRadius: "10px",
  },
};

// const dataCards = [{
//     claimed_sites : 24,
//     created_users : 17,
//     more_saved_sites: [
//         {
//             name:"linkedin",
//             saves: 21,
//             url: "https://www.linkedin.com",
//         },
//         {
//             name:"samsung",
//             saves: 18,
//             url: "https://www.samsung.com",
//         },
//         {
//             name:"space",
//             saves: 16,
//             url: "https://www.space.com",
//         },
//         {
//             name:"ilovepdf",
//             saves: 13,
//             url: "https://www.ilovepdf.com",
//         },
//         {
//             name:"fa90cebook",
//             saves: 11,
//             url: "https://www.fa90cebook.com",
//         },
//         {
//             name:"instagram",
//             saves: 10,
//             url: "https://www.instagram.com",
//         },
//         {
//             name:"instagram12",
//             saves: 9,
//             url: "https://www.instagram12.com",
//         },
//         {
//             name:"instagram23",
//             saves: 8,
//             url: "https://www.instagram23.com",
//         },
//         {
//             name:"instagram34",
//             saves: 6,
//             url: "https://www.instagram34.com",
//         },
//         {
//             name:"Facebook",
//             saves: 5,
//             url: "https://www.Facebook.com",
//         },
//     ],
//     total_sites: 205,
// }]
export const Dashboard = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.xuser);
  const [idSiteDelete, setIdSiteDelete] = useState("");
  const [sites, setSites] = useState([]); // all sites table
  const [sitesClaimed, setSitesClaimed] = useState([]); // sites Claimed table
  const [sitesUnclaimed, setSitesUnclaimed] = useState([]); // sites Unclaimed table
  const [topSite, setTopSites] = useState([]); // top ten sites favorites
  const [dataCards, setDataCards] = useState([]); // data mini cards

  const [table, setTable] = useState(""); // show table
  const [valueInputSearch, setValueInputSearch] = useState("");
  const [search, setSearch] = useState(false);
  const [pageSearch, setPageSearch] = useState([]);
  const [page, setPage] = useState(1); // all sites table
  const [pageClaimed, setPageClaimed] = useState(1); //  sites Claimed table
  const [pageUnclaimed, setPageUnclaimed] = useState(1); // sites Unclaimed table
  const [totalPages, setTotalPages] = useState(0); // All
  const [totalPagesClaimed, setTotalPagesClaimed] = useState(0); // Claimed
  const [totalPagesUnclaimed, setTotalPagesUnclaimed] = useState(0); // Unclaimed
  const [pageTable, setPageTable] = useState(0); // all
  const [pageTableClaimed, setPageTableClaimed] = useState(0); // Claimed
  const [pageTableUnclaimed, setPageTableUnclaimed] = useState(0); // Unclaimed
  const [quantity, setQuantity] = useState(5); // all
  const [quantityClaimed, setQuantityClaimed] = useState(5); // Claimed
  const [quantityUnclaimed, setQuantityUnclaimed] = useState(5); // Unclaimed

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [executor, setExecutor] = useState(true);
  const [infoSiteEdit, setInfoSiteEdit] = useState({});

  useEffect(() => {
    axios
      .get(`/admin/get_all_metrics`)
      .then((response) => {
        const data = response.data;
        setDataCards(data);
        setTopSites(data.more_saved_sites);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (search) {
      searcher(setPageSearch, valueInputSearch);
      setSearch(false);
    }
  }, [pageSearch, valueInputSearch, search]);

  // All Sites
  useEffect(() => {
    if (!executor) {
      window.location.reload(true);
    } else {
      axios
        .post(`/sites`, { page: page })
        .then((response) => {
          const databack = response.data.data;
          setTotalPages(response.data.totalPages);
          databack.forEach((element) => {
            element.viewed = false;
          });
          setSites((data) => [...data, ...databack]);
        })
        .catch((error) => {
          console.log(error);
        });
      setExecutor(true);
    }
  }, [page, executor]);

  // Claimed Sites
  useEffect(() => {
    axios
      .post(`/sites/search_admin?query=claimed`, { page: pageClaimed })
      .then((response) => {
        const databack = response.data.data;
        setTotalPagesClaimed(response.data.totalPages);
        databack.forEach((element) => {
          element.viewed = false;
        });
        setSitesClaimed((data) => [...data, ...databack]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [pageClaimed]);

  // Unclaimed Sites
  useEffect(() => {
    axios
      .post(`/sites/search_admin?query=unclaimed`, { page: pageUnclaimed })
      .then((response) => {
        const databack = response.data.data;
        setTotalPagesUnclaimed(response.data.totalPages);
        databack.forEach((element) => {
          element.viewed = false;
        });
        setSitesUnclaimed((data) => [...data, ...databack]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [pageUnclaimed]);

  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };

  const handleReturn = () => {
    if (showEdit) {
      setShowEdit(false);
      setInfoSiteEdit({});
    } else {
      navigate("/");
    }
  };

  // Table All
  const handleChangePageAll = (off) => {
    if (quantity <= sites.length) {
      setPageTable(pageTable + 5);
      setQuantity(quantity + 5);
      if (quantity + 5 === sites.length) {
        setPage(page + 1);
      }
    } else {
      off(false);
    }
  };

  const handleForwardPageAll = (off) => {
    if (pageTable > 0) {
      setPageTable(pageTable - 5);
      setQuantity(quantity - 5);
      off(true);
    }
  };

  // Table Claimed
  const handleChangePageClaimed = (off) => {
    if (quantityClaimed <= sitesClaimed.length) {
      setPageTableClaimed(pageTableClaimed + 5);
      setQuantityClaimed(quantityClaimed + 5);
      if (quantityClaimed + 5 === sitesClaimed.length) {
        setPageClaimed(page + 1);
      }
    } else {
      off(false);
    }
  };

  const handleForwardPageClaimed = (off) => {
    if (pageTableClaimed > 0) {
      setPageTableClaimed(pageTableClaimed - 5);
      setQuantityClaimed(quantityClaimed - 5);
      off(true);
    }
  };

  //Table Unclaimed
  const handleChangePageUnclaimed = (off) => {
    if (quantityUnclaimed <= sitesUnclaimed.length) {
      setPageTableUnclaimed(pageTableUnclaimed + 5);
      setQuantityUnclaimed(quantityUnclaimed + 5);
      if (quantityUnclaimed + 5 === sitesUnclaimed.length) {
        setPageUnclaimed(page + 1);
      }
    } else {
      off(false);
    }
  };

  const handleForwardPageUnclaimed = (off) => {
    if (pageTableUnclaimed > 0) {
      setPageTableUnclaimed(pageTableUnclaimed - 5);
      setQuantityUnclaimed(quantityUnclaimed - 5);
      off(true);
    }
  };

  return user.isAdmin ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingBottom: "30px",
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          width: "95%",
          alignSelf: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignSelf: "start",
            marginX: 0,
          }}
        >
          <ArrowBackIcon
            onClick={handleReturn}
            sx={{
              alignSelf: "center",
              fontSize: "40px",
              marginTop: "45px",
              cursor: "pointer",
            }}
          />
          <Box
            component="img"
            src={Logo}
            sx={{ width: "245px", height: "43px", marginTop: "45px" }}
            alt="logo_cantonica"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: { xs: 0, sm: 5 },
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              alignSelf: { xs: "start", sm: "end" },
              marginTop: "25px",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            Dashboard
          </Typography>
        </Box>
      </Box>
      {!showEdit ? (
        <Box
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: { xs: "100%", md: "70% 25%" },
            gridTemplateRows: { xs: "55% 45%", sm: "40% 50%", md: "100%" },
            justifyContent: "space-between",
            width: "95%",
            alignSelf: "center",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gap: 1,
              gridTemplateRows: { md: "20% 80%" },
              gridTemplateColumns: "100%",
              justifyContent: "space-between",
              width: "100%",
              alignSelf: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                width: "100%",
                alignSelf: "start",
              }}
            >
              <CardMini
                icon={<PersonIcon sx={{ fontSize: "2.5rem" }} />}
                title={"Registered Users"}
                quantity={dataCards?.created_users}
              />
              <CardMini
                icon={<AppShortcutIcon sx={{ fontSize: "2.5rem" }} />}
                title={"Total App"}
                quantity={dataCards?.total_sites}
              />
              <CardMini
                icon={<MobileFriendlyIcon sx={{ fontSize: "2.5rem" }} />}
                title={"Claimed Apps"}
                quantity={dataCards?.claimed_sites}
              />
            </Box>
            {(() => {
              switch (table) {
                case "Claimed":
                  return (
                    <TableDashboard
                      data={sitesClaimed}
                      setSearch={setSearch}
                      valueInputSearch={valueInputSearch}
                      setValueInputSearch={setValueInputSearch}
                      table={table}
                      setTable={setTable}
                      setIdSiteDelete={setIdSiteDelete}
                      pageTable={pageTableClaimed}
                      setShowModalDelete={setShowModalDelete}
                      setShowEdit={setShowEdit}
                      setEditSite={setInfoSiteEdit}
                      quantity={quantityClaimed}
                      handleChangePage={handleChangePageClaimed}
                      handleForwardPage={handleForwardPageClaimed}
                    />
                  );
                case "Unclaimed":
                  return (
                    <TableDashboard
                      data={sitesUnclaimed}
                      setSearch={setSearch}
                      valueInputSearch={valueInputSearch}
                      setValueInputSearch={setValueInputSearch}
                      table={table}
                      setTable={setTable}
                      setIdSiteDelete={setIdSiteDelete}
                      pageTable={pageTableUnclaimed}
                      setShowModalDelete={setShowModalDelete}
                      setShowEdit={setShowEdit}
                      setEditSite={setInfoSiteEdit}
                      quantity={quantityUnclaimed}
                      handleChangePage={handleChangePageUnclaimed}
                      handleForwardPage={handleForwardPageUnclaimed}
                    />
                  );
                case "Search":
                  return (
                    <TableDashboard
                      data={pageSearch}
                      setSearch={setSearch}
                      valueInputSearch={valueInputSearch}
                      setValueInputSearch={setValueInputSearch}
                      table={table}
                      setTable={setTable}
                      setIdSiteDelete={setIdSiteDelete}
                      pageTable={pageTableClaimed}
                      setShowModalDelete={setShowModalDelete}
                      setShowEdit={setShowEdit}
                      setEditSite={setInfoSiteEdit}
                      quantity={quantityClaimed}
                      handleChangePage={handleChangePageClaimed}
                      handleForwardPage={handleForwardPageClaimed}
                    />
                  );
                default:
                  return (
                    <TableDashboard
                      data={sites}
                      setSearch={setSearch}
                      valueInputSearch={valueInputSearch}
                      setValueInputSearch={setValueInputSearch}
                      table={table}
                      setTable={setTable}
                      setIdSiteDelete={setIdSiteDelete}
                      pageTable={pageTable}
                      setShowModalDelete={setShowModalDelete}
                      setShowEdit={setShowEdit}
                      setEditSite={setInfoSiteEdit}
                      quantity={quantity}
                      handleChangePage={handleChangePageAll}
                      handleForwardPage={handleForwardPageAll}
                    />
                  );
              }
            })()}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              alignSelf: "start",
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                alignSelf: { xs: "start", sm: "center" },
                marginTop: { xs: 3, sm: 0 },
                marginLeft: { xs: "2rem", sm: 0 },
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              Top Favorites
            </Typography>
            <Box sx={stylesTableTop}>
              {topSite
                ? topSite.map((element, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        gap: 2,
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: "90%",
                        alignSelf: "center",
                        borderBottom: "solid #F4F6F9 1px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "200px",
                          }}
                        >
                          <Typography
                            variant="h4"
                            gutterBottom
                            sx={{
                              marginTop: "15px",
                              fontWeight: "bold",
                              fontSize: "14px",
                              textAlign: "start",
                            }}
                          >
                            {element.name}
                          </Typography>
                          <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                              fontWeight: "bold",
                              fontSize: "12px",
                              textAlign: "start",
                            }}
                          >
                            {element.url}
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            marginTop: "15px",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          {element.saves}
                        </Typography>
                      </Box>
                    </Box>
                  ))
                : ""}
            </Box>
          </Box>
        </Box>
      ) : (
        <FormEditApp
          setShowEdit={setShowEdit}
          infoSiteEdit={infoSiteEdit}
          setInfoSiteEdit={setInfoSiteEdit}
          setExecuto={setExecutor}
        />
      )}
      {/* Modal Delete App */}
      <Modal
        open={showModalDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 300, sm: 500 },
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <ModalDelete
            idSiteDelete={idSiteDelete}
            setShowModalDelete={setShowModalDelete}
            setIdSiteDelete={setIdSiteDelete}
            setSites={setSites}
          />
        </Box>
      </Modal>
    </Box>
  ) : (
    <Navigate to="/" />
  );
};
const ModalDelete = ({
  idSiteDelete,
  setIdSiteDelete,
  setShowModalDelete,
  setSites,
}) => {
  const [text, setText] = useState("Are you sure you want to delete this app?");
  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState("");

  const handleDelete = (id) => {
    axios
      .delete(`/admin/${id}/delete`)
      .then((response) => {
        setSites((mySites) => mySites.filter((item) => item._id !== id));
        setSuccessful(true);
        setText("Mobile web app delete successfully");
        setTimeout(() => {
          setShowModalDelete(false);
          setIdSiteDelete("");
          setSuccessful(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        setError("Sorry! there was an error. Please try again later.");
      });
  };

  return (
    <>
      {!successful ? (
        <ErrorOutlineIcon
          sx={{
            color: "red",
            fontSize: { xs: "3rem", sm: "4rem" },
            alignSelf: "center",
          }}
        />
      ) : (
        <CheckCircleOutlineIcon
          sx={{
            color: "var(--btn-green)",
            fontSize: { xs: "3rem", sm: "4rem" },
            alignSelf: "center",
          }}
        />
      )}
      <Typography
        variant="h2"
        gutterBottom
        sx={{
          textAlign: "center",
          marginTop: "25px",
          fontWeight: "bold",
          fontSize: { xs: "18px", sm: "24px" },
          color: successful ? "var(--btn-green)" : "var(--black-100)",
        }}
      >
        {text}
      </Typography>
      {!successful ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            alignSelf: "center",
          }}
        >
          <ButtonSimple
            text={"Yes"}
            onClick={() => {
              handleDelete(idSiteDelete);
            }}
            styles={{
              marginY: "30px",
              width: { xs: "120px", sm: "200px" },
              height: "50px",
              borderRadius: "15px",
              background: "#262626",
              ":hover": { background: "#262626" },
              div: { display: "flex", justifyContent: "center" },
            }}
          />
          <ButtonSimple
            text={"No"}
            onClick={() => {
              setShowModalDelete(false);
              setIdSiteDelete("");
            }}
            styles={{
              marginY: "30px",
              width: { xs: "120px", sm: "200px" },
              height: "50px",
              borderRadius: "15px",
              background: "#262626",
              ":hover": { background: "#262626" },
              div: { display: "flex", justifyContent: "center" },
            }}
          />
        </Box>
      ) : (
        ""
      )}
      <span
        style={{
          fontSize: "12px",
          color: "red",
          fontWeight: "bold",
          width: "75%",
          overflow: "hidden",
          alignSelf: "center",
        }}
      >
        {error}
      </span>
    </>
  );
};
