import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";
import axios from "axios";
import { AppData } from "../data/AppData";
import { keyframes } from "@mui/material/";

import {
  Box,
  Drawer,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  useMediaQuery,
} from "@mui/material/";

import {
  ContactMail,
  Home,
  PermIdentity,
  RssFeed,
  Settings,
  LiveHelp,
  Search,
  Mic,
  NotificationsNone,
  NavigateBefore,
  NavigateNext,
  Favorite,
  StarRate,
} from "@mui/icons-material";

import logo from "../components/globalComponents/imagens/logo.svg";
import { Divider } from "@mui/material-next";

const drawerWidth = 240;

const styles = {
  activeLink: {
    backgroundColor: "#bdbdbd",
    borderTopRightRadius: 32,
    borderBottomRightRadius: 32,
    borderLeft: "6px solid #FFA743",
    borderTopLeftRadius: 32,
    borderBottomLeftRadius: 32,
  },
  activeButton: {
    backgroundColor: "black",
    color: "white",
  },
};

const rigthToLeftAnimation = keyframes`
from {
  transform: translateX(0)
}
to{
  transform: translateX(-100%);
}
`;

const leftToRightAnimation = keyframes`
from {
  transform: translateX(0)
}
to{
  transform: translateX(100%);
}
`;

const Carousel = ({ appCategories }) => {
  const AppCategoryContext = createContext(null);

  const [selectedButton, setSelectedButton] = useState(0);
  const [currentIndex, setcurrentIndex] = useState(0);

  //This variable will be passed to the siblings components of the home page to display the applications based on category
  const [currentAppCategory, setCurrentAppCategory] = useState("All");

  const isSmallScreen = useMediaQuery('(max-width: "600px")');
  const isMediumScreen = useMediaQuery('(max-width: "900px")');

  //State variables declared to show transitions of app category
  const [leftToRight, setLeftToRight] = useState(false);
  const [rightToLeft, setRightToLeft] = useState(false);

  //Total number of app category to be showin in the website based on window size
  const itemCount = isSmallScreen ? 4 : isMediumScreen ? 6 : 10;

  //Carousel Previous and Next Buttons
  const previousPage = () => {
    setLeftToRight(true);
    setTimeout(() => {
      setLeftToRight(false);
    }, 500);
    setcurrentIndex(Math.max(currentIndex - itemCount, 0));
  };

  const nextPage = () => {
    setRightToLeft(true);
    setTimeout(() => {
      setRightToLeft(false);
    }, 500);
    setcurrentIndex(
      Math.min(currentIndex + itemCount, appCategories.length - itemCount)
    );
  };

  //State used for showing the data based on the option selected by the user
  const handleCategoriesButtonClick = (index, value) => {
    setSelectedButton(index);
    setCurrentAppCategory(value);
  };

  return (
    <AppCategoryContext.Provider value={currentAppCategory}>
      <Typography variant="body1" sx={{ color: "#637381" }} align="left">
        Features / Trending Apps
      </Typography>
      <Typography variant="h6" align="left" fontWeight="bold">
        Featured Web Apps & PWAs
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 3,
        }}
      >
        {currentIndex > 0 && (
          <NavigateBefore
            sx={{
              cursor: "pointer",
            }}
            onClick={previousPage}
          />
        )}

        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-around",
            animation: leftToRight
              ? `${leftToRightAnimation} 0.5s ease-in-out`
              : rightToLeft
              ? `${rigthToLeftAnimation} 0.5s ease-in-out`
              : "none",
          }}
        >
          {appCategories
            .slice(currentIndex, currentIndex + itemCount)
            .map((item, index) => (
              <Button
                sx={{
                  backgroundColor:
                    selectedButton === currentIndex + index
                      ? "black"
                      : "#F3F5F7",
                  color:
                    selectedButton === currentIndex + index
                      ? "white"
                      : "#637381",
                  borderRadius: 32,
                  "&:hover": {
                    backgroundColor:
                      selectedButton === currentIndex + index
                        ? "black"
                        : "#F3F5F7",
                  },
                }}
                key={index}
                onClick={() => {
                  handleCategoriesButtonClick(currentIndex + index, item);
                }}
              >
                {item}
              </Button>
            ))}
        </Box>

        {currentIndex + itemCount < appCategories.length && (
          <NavigateNext
            sx={{
              cursor: "pointer",
            }}
            onClick={nextPage}
          />
        )}
      </Box>
    </AppCategoryContext.Provider>
  );
};

export const HomePage = () => {
  //This state is used to set the active link
  const [selectedItem, setSelectedItem] = useState(0);

  //Accesing the  AppCategoryContext which is defined in the Carousel function mentioned above
  const currentAppCategory = useContext("AppCategoryContext");

  const [applicationData, setApplicationData] = useState([]);

  //This useRef hook is used to apply scrollng for the applications
  const featuredWebAppsRef = useRef(null);

  const scrollFeaturedAppsRight = () => {
    if (featuredWebAppsRef.current) {
      featuredWebAppsRef.current.scrollLeft += 200;
    }
  };

  const scrollFeaturedAppsLeft = () => {
    if (featuredWebAppsRef.current) {
      featuredWebAppsRef.current.scrollLeft -= 200;
    }
  };

  //Fetching the data based on the app cateogry selected by the user
  useEffect(() => {
    async function fetchAppBasedOnCategory() {
      try {
        // const response = await axios.get(`/blogs`);

        // if (response.status !== 200) {
        //   throw new Error("Failed to fetch the applications");
        // }

        // const { data } = response;

        setApplicationData(AppData);
      } catch (error) {
        console.error("Error fetching applications", error);
      }
    }
    fetchAppBasedOnCategory();
  }, []);

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  const appCategories = [
    "All",
    "Games",
    "Education",
    "Social Networking",
    "Productivity",
    "Health & Fitness",
    "Utilities",
    "Entertainment",
    "Shopping",
    "Travel",
    "Finance",
    "News & Magazines",
    "Food & Drink",
    "Weather",
    "Music Creation & Production",
    "Photo & Video Editing",
    "Dating",
    "Parenting",
    "Language Learning",
    "Navigation & Maps",
    "Virtual Reality (VR) & Augmented Reality (AR)",
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            justifyContent: "space-between",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        {/* Top contents of the side navigation bar */}
        <Box>
          {/* Cantonica logo along with its name */}
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
              pt: 3,
            }}
          >
            <img src={logo} alt="Cantonica Logo" />
            <Typography
              sx={{ fontSize: "20px", color: "black", fontWeight: "700" }}
            >
              CANTONICA
            </Typography>
          </Box>

          <List>
            <ListItem
              key="Home"
              disablePadding
              sx={{
                backgroundColor: selectedItem === 0 ? styles.activeLink : null,
              }}
            >
              <ListItemButton onClick={() => handleItemClick(0)}>
                <ListItemIcon>
                  <Home sx={{ color: selectedItem === 0 ? "#FFA743" : null }} />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>

            <ListItem
              key="Discover"
              disablePadding
              sx={{
                backgroundColor: selectedItem === 1 ? styles.activeLink : null,
              }}
            >
              <ListItemButton onClick={() => handleItemClick(1)}>
                <ListItemIcon>
                  <Home sx={{ color: selectedItem === 1 ? "#FFA743" : null }} />
                </ListItemIcon>
                <ListItemText primary="Discover" />
              </ListItemButton>
            </ListItem>

            <ListItem
              key="AboutUs"
              disablePadding
              sx={{
                backgroundColor: selectedItem === 2 ? styles.activeLink : null,
              }}
            >
              <ListItemButton onClick={() => handleItemClick(2)}>
                <ListItemIcon>
                  <Home sx={{ color: selectedItem === 2 ? "#FFA743" : null }} />
                </ListItemIcon>
                <ListItemText primary="AboutUs" />
              </ListItemButton>
            </ListItem>

            <ListItem
              key="Profile"
              disablePadding
              sx={{
                backgroundColor: selectedItem === 3 ? styles.activeLink : null,
              }}
            >
              <ListItemButton onClick={() => handleItemClick(3)}>
                <ListItemIcon>
                  <PermIdentity
                    sx={{ color: selectedItem === 3 ? "#FFA743" : null }}
                  />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItemButton>
            </ListItem>

            <ListItem
              key="Feed"
              disablePadding
              sx={{
                backgroundColor: selectedItem === 4 ? styles.activeLink : null,
              }}
            >
              <ListItemButton onClick={() => handleItemClick(4)}>
                <ListItemIcon>
                  <RssFeed
                    sx={{ color: selectedItem === 4 ? "#FFA743" : null }}
                  />
                </ListItemIcon>
                <ListItemText primary="Feed" />
              </ListItemButton>
            </ListItem>

            <ListItem
              key="Contact"
              disablePadding
              sx={{
                backgroundColor: selectedItem === 5 ? styles.activeLink : null,
              }}
            >
              <ListItemButton onClick={() => handleItemClick(5)}>
                <ListItemIcon>
                  <ContactMail
                    sx={{ color: selectedItem === 5 ? "#FFA743" : null }}
                  />
                </ListItemIcon>
                <ListItemText primary="Contact" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>

        {/*Top Content navigation bar code ends */}

        {/*Bottom contents of the side navigation bar*/}

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <List>
            <ListItem
              key="Setting"
              disablePadding
              sx={{
                backgroundColor: selectedItem === 6 ? styles.activeLink : null,
              }}
            >
              <ListItemButton onClick={() => handleItemClick(6)}>
                <ListItemIcon>
                  <Settings
                    sx={{ color: selectedItem === 6 ? "#FFA743" : null }}
                  />
                </ListItemIcon>
                <ListItemText primary="Setting" />
              </ListItemButton>
            </ListItem>

            <ListItem
              key="FAQs"
              disablePadding
              sx={{
                backgroundColor: selectedItem === 7 ? styles.activeLink : null,
              }}
            >
              <ListItemButton onClick={() => handleItemClick(7)}>
                <ListItemIcon>
                  <LiveHelp
                    sx={{ color: selectedItem === 7 ? "#FFA743" : null }}
                  />
                </ListItemIcon>
                <ListItemText primary="FAQs" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />

          <Typography sx={{ color: "#919EAB" }}>
            Terms Privacy Contact
          </Typography>
          <Typography sx={{ color: "#919EAB" }}>
            Copyright 2024 By Cantonica
          </Typography>
        </Box>

        {/*Bottom contents of the side navigation bar ends*/}
      </Drawer>

      <Box sx={{ width: "100%", p: 2, overflow: "hidden" }}>
        <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{
                border: 1,
                borderRadius: 32,
                "& fieldset": { border: "none" },
                color: "black",
                backgroundColor: "#F3F5F7",
              }}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <Mic />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Box sx={{ display: "flex", gap: 2, alignItems: "center", pr: 2 }}>
              <NotificationsNone />
              <Button
                sx={{
                  backgroundColor: "#FFA743",
                  color: "black",
                  borderRadius: 32,
                }}
              >
                Request Developer Access
              </Button>
              <Home />
            </Box>
          </Box>

          <Box
            sx={{
              backgroundColor: "#D9D9D9",
              width: "100%",
              height: "50vh",
              borderRadius: 4,
            }}
          ></Box>

          <Carousel appCategories={appCategories} />

          <Box sx={{ position: "relative" }}>
            <Box
              component="div"
              ref={featuredWebAppsRef}
              sx={{
                display: "flex",
                flexWrap: "nowrap", // Prevent wrapping of flex items
                overflow: "hidden", // Hide overflow content
                gap: 4,
              }}
            >
              <NavigateBefore
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "30%",
                  height: "4vh",
                  width: "2vw",
                  color: "#707070",
                  border: 1,
                  borderRadius: "50%",
                  backgroundColor: "white",
                }}
                onClick={scrollFeaturedAppsLeft}
              />

              {applicationData &&
                applicationData.length > 0 &&
                applicationData.map((data) => {
                  return (
                    <Box sx={{ cursor: "pointer" }}>
                      <Box
                        sx={{
                          width: "15vw",
                          height: "25vh",
                          backgroundColor: "#F3F5F7",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={data.image}
                          style={{
                            width: "13vw",
                            height: "16vh",
                          }}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ pt: 2 }}>
                          <Typography
                            variant="body1"
                            align="left"
                            fontWeight="bold"
                          >
                            {data.id}
                          </Typography>
                          <Typography
                            variant="body1"
                            align="left"
                            fontWeight="bold"
                          >
                            {data.name}
                          </Typography>
                          <Typography
                            variant="body1"
                            align="left"
                            sx={{ color: "#637381" }}
                          >
                            {data.category}
                          </Typography>

                          <Box
                            sx={{
                              display: "flex",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "#637381", display: "inline-block" }}
                            >
                              {data.rating}
                            </Typography>
                            <StarRate
                              sx={{
                                color: "#FFA743",
                                width: "1vw",
                                height: "2vh",
                              }}
                            />
                          </Box>
                        </Box>
                        <Favorite sx={{ color: "grey", cursor: "pointer" }} />
                      </Box>
                    </Box>
                  );
                })}

              <NavigateNext
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "30%",
                  right: 0,
                  height: "4vh",
                  width: "2vw",
                  color: "#707070",
                  border: 1,
                  borderRadius: "50%",
                  backgroundColor: "white",
                }}
                onClick={scrollFeaturedAppsRight}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
