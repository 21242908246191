import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from '@mui/material';
import { allAppsContext } from "../../../App";
import { addApp, addAppSearch } from "../connections";
import { ButtonSimple } from "../inputs/inputs";

export const CardApps = ({element, page}) => {
    const { dataUser } = useContext(allAppsContext)
    const conditional = element.saved === true ?  "Saved" : "Save to Favorites"
    const navigate = useNavigate();
    const token = localStorage.token

    const [textButton, setTextButton] = useState(conditional)

    //Favorite app
    const handleSaved = () =>{
        if(token){
            if(textButton === "Save to Favorites"){
                const user = JSON.parse(localStorage.xuser)
                page === "Apps"  ? addApp(user._id, element._id, dataUser, setTextButton) : addAppSearch(user._id, element._id, dataUser, setTextButton)
            }
        } else{
            navigate("/Login")
        }
    }


    const info = () => {
        element.viewed = true
        navigate(`/app/${element.nameExpanded}/${element._id}`)
    }

    const styleButton = {
        marginTop: {xs:"15px", sm: "30px"},
        width: {xs:"139px", lg: "150px"},
        height: {xs:"25px", lg:"35px"},
        borderRadius: "15px",
        fontSize: "10px",
        background: textButton === "Saved" ? "#B7B7B7" : "#262626",
        ":hover": {
            background: textButton === "Saved" ? "#B7B7B7" : "#262626",
        },
        div:{
            display: "flex",
            alignContent: "flex-start",
            width: "100%", 
        },        
    }
    
    return(
        <>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer"}} onClick={info} >
                <Box
                    component="img"
                    src={element.logo}
                    onClick={info}
                    alt={element.name}
                    sx={{width: {xs: "30px", sm: "50px", md:"68px"}, height: {xs:"30px", sm: "50px", md:"68px"}, borderRadius:{xs:"15px", md: "24px", cursor: "pointer"}}}
                />
                <Typography variant="h3" sx={{color: element.viewed ? "#747B84" : "#09101D", fontSize: "18px", textAlign: "initial", fontWeight: "700", width: {xs:"85%", md: "80%"}, marginLeft:{md: "5%"}, height: "21px", overflow: "hidden", cursor: "pointer"}} onClick={info}>{element.name.charAt(0).toUpperCase() + element.name.slice(1) }</Typography>
            </Box>
            <Typography onClick={info} variant="body2" sx={{ color: "#333333",  fontSize: "12px", textAlign: "justify", marginTop: {xs:"15px", md:"20px"}, fontWeight: "500", maxHeight: "50px", overflow: "hidden", textOverflow: "ellipsis", cursor: "pointer"}}>{element.description} </Typography>
            <ButtonSimple styles={styleButton} text={textButton} onClick={handleSaved}/>
        </>
    )
}
