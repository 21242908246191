import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { Box, Paper, Typography, CircularProgress } from "@mui/material";
import {
  InputSimple,
  InputPassword,
  ButtonSimple,
} from "../globalComponents/inputs/inputs";
import { SnackbarBasic } from "../globalComponents/feedBacks/FeedBacks";
import Fondo from "../globalComponents/imagens/backgroundInitial.png";
import Cantonica from "../globalComponents/imagens/cantonica.png";

const styles = {
  box: {
    display: "flex",
    justifyContent: "center",
    height: { lg: "100vh" },
    flexDirection: "column",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    width: { xs: "100%", md: "50%", lg: "40%" },
    zIndex: 5,
    alignSelf: "center",
    marginTop: { sm: 3 },
    gap: { xs: 1, lg: 0 },
    boxShadow: {
      xs: "none",
      md: "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    },
  },
  h2: {
    fontSize: "32px",
    alignSelf: { xs: "flex-start", sm: "center" },
    margin: { xs: "5%", md: 0 },
    fontWeight: "bold",
  },
  div: {
    alignSelf: "center",
    width: "70%",
    textAlign: "center",
    marginBottom: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginY: "5px",
    marginTop: "5px",
    width: { xs: "343px", md: "400px" },
    height: { xs: "40px", md: "35px" },
    padding: "10.5px 14px",
    label: {
      paddingLeft: "10px",
      paddingTop: "5px",
    },
    fieldset: {
      borderRadius: "15px",
      height: { xs: "55px", md: "50px" },
    },
    div: {
      alingItems: "center",
    },
    button: {
      paddingY: 0,
    },
  },
  button: {
    marginTop: "20px",
    marginBottom: "10px",
    width: { xs: "343px", md: "400px" },
    height: "40px",
    borderRadius: "15px",
    background: "var(--btn-green)",
    ":hover": {
      background: "var(--btn-green)",
    },
  },
  typography: {
    color: "var(--black-100)",
    marginY: { xs: "20px", md: 0 },
    width: "100%",
    cursor: "pointer",
    fontWeight: "600",
    fontSize: "13px",
  },
  span: {
    marginInline: "10px",
    color: "#F60000",
    fontWeight: "600",
    fontSize: "13px",
  },
  error: {
    fontSize: "12px",
    color: "red",
    fontWeight: "bold",
    width: "90%",
    overflow: "hidden",
    alignSelf: "center",
  },
  snackbar: {
    width: "200px",
  },
};

export const FormRegisterRefer = () => {
  const { id } = useParams();
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(
    "We're sorry. there was an error, please try again"
  );
  const [openSnackbar, setOpenSnackbar] = useState({
    success: false,
    error: false,
  });
  const [message, setMessage] = useState({
    message1: "",
    message2: "",
  });
  const navigate = useNavigate();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const err = errors.user === undefined ? {} : errors.user;
  const createPassword = watch("user.createPassword");
  const confirmPassword = watch("user.confirmPassword");

  const onSubmit = ({ user }) => {
    const nameUser = user.name + " " + user.lastname;
    if (message.message1 === "" && message.message2 === "") {
      if (createPassword === confirmPassword) {
        setLoading(true);
        axios
          .post(`/referrals/${id}/initiate_referral`, {
            name: nameUser,
            email: user.email,
            password: user.createPassword,
          })
          .then((response) => {
            const token = response.data.token;
            const user = response.data.user;
            localStorage.token = token;
            localStorage.xuser = JSON.stringify(user);
            setLoading(false);
            setOpenSnackbar({ error: false, success: true });
            setTimeout(() => {
              setOpenSnackbar({ success: false, error: false });
              navigate("/");
            }, 5000);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            if (error.response.status === 500) {
              setResponse("This email already exists");
              setOpenSnackbar({ success: false, error: true });
              setTimeout(() => {
                setOpenSnackbar({ success: false, error: false });
                setResponse(
                  "We're sorry. there was an error, please try again"
                );
              }, 7000);
            } else {
              setOpenSnackbar({ success: false, error: true });
              setTimeout(() => {
                setOpenSnackbar({ success: false, error: false });
              }, 7000);
            }
          });
      }
    }
  };

  return (
    <Box sx={styles.box}>
      <Box
        component="img"
        src={Fondo}
        sx={{
          display: { xs: "none", lg: "block" },
          width: "-webkit-fill-available",
          height: "-webkit-fill-available",
          position: "absolute",
          zIndex: 0,
        }}
      />
      <Box
        component="img"
        src={Cantonica}
        alt={"Logo_Cantonica"}
        onClick={() => {
          navigate("/");
        }}
        sx={{
          width: { xs: "200px", md: "250px" },
          alignSelf: "center",
          zIndex: 5,
          marginTop: { xs: "24px", sm: "20px" },
        }}
      />
      <Paper sx={styles.paper} elevation={0}>
        <Typography variant="subtitle1" sx={styles.h2}>
          Sign Up Referral
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={styles.form}>
          <InputSimple
            label={"First Name"}
            error={err.name ? true : false}
            inputProps={{ maxLength: 20 }}
            type={"text"}
            styles={styles.input}
            hookForm={{
              ...register("user.name", {
                required: "This field can´t be empty",
              }),
            }}
          />
          <ErrorMessage
            errors={errors}
            name="user.name"
            render={({ message }) => (
              <span style={styles.error}>{message}</span>
            )}
          />
          <InputSimple
            label={"Last Name"}
            error={err.lastname ? true : false}
            inputProps={{ maxLength: 20 }}
            type={"text"}
            styles={styles.input}
            hookForm={{
              ...register("user.lastname", {
                required: "This field can´t be empty",
              }),
            }}
          />
          <ErrorMessage
            errors={errors}
            name="user.lastname"
            render={({ message }) => (
              <span style={styles.error}>{message}</span>
            )}
          />
          <InputSimple
            label={"Email"}
            error={err.email ? true : false}
            inputProps={{ maxLength: 40 }}
            type={"text"}
            styles={styles.input}
            hookForm={{
              ...register("user.email", {
                required: "This field can´t be empty",
                pattern: {
                  value:
                    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                  message: "Enter a valid email",
                },
              }),
            }}
          />
          <ErrorMessage
            errors={errors}
            name="user.email"
            render={({ message }) => (
              <span style={styles.error}>{message}</span>
            )}
          />
          <InputPassword
            label={"Create Password"}
            styles={styles.input}
            error={err.createPassword ? true : false}
            inputProps={{ maxLength: 16 }}
            showPassword={showPasswordOne}
            setShowPassword={setShowPasswordOne}
            hookForm={{
              ...register("user.createPassword", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
                  message:
                    "The password must be between 8 and 16 characters, at least one digit, one lower case and one upper case.",
                },
                validate: (value) => {
                  value !== confirmPassword
                    ? setMessage({
                        ...message,
                        message1: "Passwords do not match",
                      })
                    : setMessage({ message2: "", message1: "" });
                },
              }),
            }}
          />
          <ErrorMessage
            errors={errors}
            name="user.createPassword"
            render={({ message }) => (
              <span style={styles.error}>{message}</span>
            )}
          />
          <span style={styles.error}>{message.message1}</span>
          <InputPassword
            label={"Confirm Password"}
            error={err.confirmPassword ? true : false}
            inputProps={{ maxLength: 16 }}
            styles={styles.input}
            showPassword={showPasswordTwo}
            setShowPassword={setShowPasswordTwo}
            hookForm={{
              ...register("user.confirmPassword", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
                  message:
                    "The password must be between 8 and 16 characters, at least one digit, one lower case and one upper case.",
                },
                validate: (value) => {
                  value !== createPassword
                    ? setMessage({
                        ...message,
                        message2: "Passwords do not match",
                      })
                    : setMessage({ message1: "", message2: "" });
                },
              }),
            }}
          />
          <ErrorMessage
            errors={errors}
            name="user.confirmPassword"
            render={({ message }) => (
              <span style={styles.error}>{message}</span>
            )}
          />
          <span style={styles.error}>{message.message2}</span>
          <ButtonSimple
            styles={styles.button}
            text={loading ? <CircularProgress color="inherit" /> : "Continue"}
            type={"submit"}
          />
        </form>
        <Box sx={styles.div}>
          <Typography variant="subtitle1" gutterBottom sx={styles.typography}>
            Do you have an account?
            <span style={styles.span} onClick={() => navigate("/Login")}>
              Login
            </span>
          </Typography>
        </Box>
        <SnackbarBasic
          open={openSnackbar.success}
          styles={styles.snackbar}
          autoHideDuration={5000}
          severity={"success"}
          message={"Successful registration"}
          vertical={"bottom"}
          horizontal={"center"}
        />
        <SnackbarBasic
          open={openSnackbar.error}
          styles={styles.snackbar}
          autoHideDuration={5000}
          severity={"error"}
          message={response}
          vertical={"bottom"}
          horizontal={"center"}
        />
      </Paper>
    </Box>
  );
};
