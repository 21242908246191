import React, {useState, useEffect, useContext} from "react"
import { Box } from "@mui/material"
import { Header } from "./globalComponents/Header";
import { allAppsContext } from "../App";
import { aplicationsAll, aplicationsUser, topLastApps, topSites, topSitesUser } from "./globalComponents/connections";

export const tabsContext = React.createContext();
export const appsLikeContext = React.createContext();

export const Layout = ({children}) => {
    const [hasMore, setHasMore] = useState(true)
    const [page, setPage] = useState(1)
    const [appsLike, setAppsLike] = useState([]) // for save apps 
    const [topLast, setTopLast] = useState([])
    const [inputValue, setInputValue ]= useState("")
    const [site, setSite] = useState("Apps")
    const { setData, setDataUser, setTopApps } = useContext(allAppsContext)
    const token = localStorage.token

    // apps
    useEffect(() => {
        if(token){
            const user = JSON.parse(localStorage.xuser)
            aplicationsUser(user._id, setDataUser, setHasMore, page)
        } else{
            aplicationsAll(setData, setHasMore, page)
        }
      }, [setData, setDataUser, token, page]);
  
    useEffect(()=>{
        if(token){
            const user = JSON.parse(localStorage.xuser)
            topSitesUser(setTopApps, user._id)
        } else {
            topSites(setTopApps)
        }
    }, [setTopApps, token])

    useEffect(()=>{
        topLastApps(setTopLast)
    },[])

    return(
        <tabsContext.Provider value={{ inputValue, setInputValue, page, setPage, hasMore}}>
            <appsLikeContext.Provider value={{appsLike, setAppsLike, site, setSite, topLast}}>
                <Header />
                <Box id="scrollableDiv" sx={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: {xs: "0", md:"2%"}, overflow:"hidden " }}>
                    {children}
                </Box>
            </appsLikeContext.Provider>
        </tabsContext.Provider>
    )
}