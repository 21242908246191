import axios from "axios";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

// All apps - all users
export const aplicationsAll = (setData, setHasMore, num) => {
  axios
    .post(`/sites`, { page: num })
    .then((response) => {
      const databack = response.data.data;
      const totalPages = response.data.totalPages;
      setHasMore(num < totalPages);
      databack.forEach((element) => {
        element.urlShow = element.url.replace("https://www.", "");
        element.viewed = false;
        element.nameExpanded = element.name.replaceAll(" ", "_");
      });
      setData((data) => [...data, ...databack]);
    })
    .catch((error) => {
      console.log(error);
    });
};

// All apps - user logged
export const aplicationsUser = (id, setDataUser, setHasMore, num) => {
  axios
    .get(`/sites/user_id/${id}?page=${num}`)
    .then((response) => {
      const databack = response.data.data;
      const totalPages = response.data.totalPages;
      setHasMore(num < totalPages);
      databack.forEach((element) => {
        element.urlShow = element.url.replace("https://www.", "");
        element.viewed = false;
        element.nameExpanded = element.name.replaceAll(" ", "_");
      });
      setDataUser((dataUser) => [...dataUser, ...databack]);
    })
    .catch((error) => {
      console.log(error);
    });
};

// Seacher all users
export const searcher = (setResults, word) => {
  axios
    .get(`/sites/search?query=${word}`, {
      cancelToken: source.token,
    })
    .then((response) => {
      const data = response.data;
      data.forEach((element) => {
        element.urlShow = element.url.replace("https://www.", "");
        element.viewed = false;
        element.nameExpanded = element.name.replaceAll(" ", "_");
      });
      setResults(data);
    })
    .catch((error) => {
      if (axios.isCancel(error)) return;
      console.error(error);
    });
};

// Seacher user logged from Apps
export const searcherUser = (setResults, word, idUser) => {
  axios
    .get(`/sites/${idUser}/search?query=${word}`, {
      cancelToken: source.token,
    })
    .then((response) => {
      const data = response.data;
      data.forEach((element) => {
        element.urlShow = element.url.replace("https://www.", "");
        element.viewed = false;
        element.nameExpanded = element.name.replaceAll(" ", "_");
      });
      setResults(data);
    })
    .catch((error) => {
      if (axios.isCancel(error)) return;
    });
};

// All apps saved user
export const mySaves = (id, setAppsLike) => {
  axios
    .get(`/sites/user/${id}`)
    .then((response) => {
      const data = response.data;
      data.forEach((element) => {
        element.urlShow = element.url.replace("https://www.", "");
        element.viewed = false;
        element.nameExpanded = element.name.replaceAll(" ", "_");
      });
      setAppsLike(data);
    })
    .catch((error) => {
      console.log(error);
    });
};

// Apps creates User
export const myCreatedApps = (idUser, setApps, setLoading) => {
  axios
    .get(`/sites/${idUser}/created`)
    .then((response) => {
      const data = response.data;
      setLoading(false);
      setApps(data);
    })
    .catch((error) => {
      setLoading(false);
      console.log(error);
    });
};

// Remove App from Saved Apps
export const removeApp = (
  idUser,
  element,
  dataUser,
  setDataUser,
  setAppsLike
) => {
  axios
    .put(`/user_sites/${idUser}/remove_site/${element._id}`)
    .then((response) => {
      setAppsLike((appsLike) =>
        appsLike.filter((item) => item._id !== element._id)
      );
      if (dataUser.some((item) => item._id === element._id)) {
        dataUser.forEach((item) => {
          if (item._id === element._id) {
            item.saved = false;
          }
        });
        setDataUser([...dataUser, element]);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// Add App from Apps
export const addApp = (idUser, idItem, dataUser, setTextButton) => {
  axios
    .put(`/user_sites/${idUser}/add_site/${idItem}`)
    .then((response) => {
      dataUser.forEach((item) =>
        item._id === idItem ? (item.saved = true) : ""
      );
      setTextButton("Saved");
    })
    .catch((error) => {
      console.log(error);
    });
};

// Remove App from Search Result
export const removeAppSearch = (
  idUser,
  element,
  dataUser,
  setDataUser,
  setResults,
  setAppsLike
) => {
  axios
    .put(`/user_sites/${idUser}/remove_site/${element._id}`)
    .then((response) => {
      setAppsLike((appsLike) =>
        appsLike.filter((item) => item._id !== element._id)
      );
      setResults((results) =>
        results.filter((item) => item._id !== element._id)
      );
      if (dataUser.some((item) => item._id === element._id)) {
        dataUser.forEach((item) => {
          if (item._id === element._id) {
            item.saved = false;
          }
        });
        setDataUser([...dataUser, element]);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// Add App from Search Result
export const addAppSearch = (idUser, idItem, dataUser, setTextButton) => {
  axios
    .put(`/user_sites/${idUser}/add_site/${idItem}`)
    .then((response) => {
      dataUser.forEach((item) =>
        item._id === idItem ? (item.saved = true) : ""
      );
      setTextButton("Saved");
    })
    .catch((error) => {
      console.log(error);
    });
};

// Remove App from Expanded
export const removeAppExpanded = (
  idUser,
  element,
  dataUser,
  setDataUser,
  setTextButton
) => {
  axios
    .put(`/user_sites/${idUser}/remove_site/${element._id}`)
    .then((response) => {
      element.site_stats.saves -= 1;
      if (!dataUser.some((item) => item._id === element._id)) {
        setDataUser([...dataUser, element]);
      }
      setTextButton("Save to Favorites");
    })
    .catch((error) => {
      console.log(error);
    });
};

// Add App from Expanded
export const addAppExpanded = (
  idUser,
  idItem,
  setDataUser,
  setTextButton,
  siteId
) => {
  axios
    .put(`/user_sites/${idUser}/add_site/${idItem}`)
    .then((response) => {
      siteId.site_stats.saves += 1;
      setDataUser((dataUser) => dataUser.filter((item) => item._id !== idItem));
      setTextButton("Remove from Favorites");
    })
    .catch((error) => {
      console.log(error);
    });
};

// Create a Site
export const createApp = (
  data,
  setTextInput,
  setColorText,
  setLoading,
  dataUser,
  setShowModal,
  setOpenSnackbar,
  reset
) => {
  axios
    .post(`/sites/add_site`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      setOpenSnackbar(true);
      setColorText({
        one: "#747B84",
        two: "#747B84",
        three: "#747B84",
        four: "#747B84",
      });
      setTextInput({
        one: "“Mobile web apps” are websites that function in-browser in a way similar to that of content downloaded from app stores.",
        two: "",
        three:
          "If you´re the owner of this web app, please enter an email (using the site´s URL) to activate admin access.",
        four: "",
      });
      if (response.data.message === "code sended. Site indexed successfully.") {
        setShowModal(true);
        setTimeout(() => {
          reset();
          setOpenSnackbar(false);
        }, 5000);
      } else if (response.data.message === "code sended") {
        setShowModal(true);
        setTimeout(() => {
          reset();
          setOpenSnackbar(false);
        }, 5000);
      } else {
        const dataResponse = response.data;
        console.log(dataResponse);
        dataResponse.urlShow = dataResponse.url.replace("https://www.", "");
        dataResponse.viewed = false;
        dataResponse.nameExpanded = dataResponse.name.replaceAll(" ", "_");
        dataUser.push(dataResponse);
        setTimeout(() => {
          window.location.replace(
            `/app/${dataResponse.nameExpanded}/${dataResponse._id}`
          );
          reset();
          setOpenSnackbar(false);
        }, 5000);
      }
    })
    .catch((error) => {
      const err = error.response;
      console.log(error);
      if (err?.data?.error === "Site already exists") {
        setColorText({
          one: "red",
          two: "red",
          three: "#747B84",
          four: "#747B84",
        });
        setTextInput({
          one: "This Movile Web App has already been indexed on cantonica. please add a description and screenshot in a diferent language and/or request admin access to manage your web apps page",
          two: "This mobile web app has already been provided a description, keywords, and screenshots in English. You may try submitting details in another language or you may claim this app to have admin rights. Claim an app by entering an email matching the web app’s URL.",
          three:
            "If you´re the owner of this web app, please enter an email (using the site´s URL) to activate admin access.",
          four: "",
        });
      } else if (
        err?.data?.error ===
        "Invalid URL '': No scheme supplied. Perhaps you meant https://?"
      ) {
        setColorText({
          one: "red",
          two: "#747B84",
          three: "#747B84",
          four: "#747B84",
        });
        setTextInput({
          one: "Invalid URL '': No scheme supplied. Perhaps you meant https://?",
          two: "",
          three:
            "If you´re the owner of this web app, please enter an email (using the site´s URL) to activate admin access.",
          four: "",
        });
      } else if (
        err?.data?.error === "This email is the admin for another site"
      ) {
        setColorText({
          one: "#747B84",
          two: "#747B84",
          three: "red",
          four: "#747B84",
        });
        setTextInput({
          one: "“Mobile web apps” are websites that function in-browser in a way similar to that of content downloaded from app stores.",
          two: "",
          three: "This email is the admin for another site",
          four: "",
        });
      } else if (
        err?.data?.error ===
        "There was an error sending the confirmation code, please try again later. Sucessfully"
      ) {
        dataUser.push(err?.data?.site);
        setColorText({
          one: "#747B84",
          two: "#747B84",
          three: "#747B84",
          four: "red",
        });
        setTextInput({
          one: "“Mobile web apps” are websites that function in-browser in a way similar to that of content downloaded from app stores.",
          two: "",
          three:
            "If you´re the owner of this web app, please enter an email (using the site´s URL) to activate admin access.",
          four: "There was an error sending the confirmation code to your admin email. Please try again later.",
        });
      } else if (err?.data?.error === "This site has already been claimed.") {
        setColorText({
          one: "#747B84",
          two: "#747B84",
          three: "#747B84",
          four: "red",
        });
        setTextInput({
          one: "“Mobile web apps” are websites that function in-browser in a way similar to that of content downloaded from app stores.",
          two: "",
          three:
            "If you´re the owner of this web app, please enter an email (using the site´s URL) to activate admin access.",
          four: "This web application already has an administrator assigned to it. Please contact tech@cantonica.com if you believe this is a mistake and you are the legitimate administrator.",
        });
      } else {
        setColorText({
          one: "#747B84",
          two: "#747B84",
          three: "#747B84",
          four: "red",
        });
        setTextInput({
          one: "“Mobile web apps” are websites that function in-browser in a way similar to that of content downloaded from app stores.",
          two: "",
          three:
            "If you´re the owner of this web app, please enter an email (using the site´s URL) to activate admin access.",
          four: "¡Upps! We had an error, please try again later.",
        });
      }
      setLoading(false);
    });
};

// Delete Site
export const DeleteSite = (
  id,
  setSuccessful,
  setShowModalDelete,
  setIdSiteDelete,
  setError,
  setText,
  setMySites
) => {
  axios
    .delete(`/sites/${id}/delete_ownership`)
    .then((response) => {
      setMySites((mySites) => mySites.filter((item) => item._id !== id));
      setSuccessful(true);
      setText("Mobile web app removed successfully");
      setTimeout(() => {
        setShowModalDelete(false);
        setIdSiteDelete("");
        setSuccessful(false);
      }, 3000);
    })
    .catch((error) => {
      console.log(error);
      setError("Sorry! there was an error. Please try again later.");
    });
};

// Edit Site
export const editSite = (
  infoSiteEdit,
  data,
  setTextInput,
  setColorText,
  setLoading,
  setShowModal,
  setOpenSnackbar,
  setInfoSiteEdit,
  setShowEdit,
  setExecuto
) => {
  axios
    .put(`/sites/${infoSiteEdit._id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      setOpenSnackbar(true);
      setColorText({
        one: "#747B84",
        two: "#747B84",
        three: "#747B84",
        four: "#747B84",
      });
      setTextInput({
        one: "“Mobile web apps” are websites that function in-browser in a way similar to that of content downloaded from app stores.",
        two: "",
        three:
          "If you´re the owner of this web app, please enter an email (using the site´s URL) to activate admin access.",
        four: "",
      });
      if (response.data.message === "code sended") {
        setShowModal(true);
        setOpenSnackbar(false);
      } else {
        setExecuto((data) => !data);
        setTimeout(() => {
          setOpenSnackbar(false);
          setInfoSiteEdit({});
          setShowEdit(false);
        }, 3000);
      }
      setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
    });
};

// Code OTP  To claim the domain
export const otpValidate = (
  data,
  reset,
  setShow,
  setData,
  setOpenSnackbar,
  setLoading,
  setText,
  resetCode,
  location
) => {
  axios
    .post(`/sites/validate_otp`, data)
    .then((response) => {
      setLoading(false);
      setOpenSnackbar({ success: true, error: false, successEdit: false });
      setText("Send a new email");

      setTimeout(() => {
        setLoading(false);
        reset();
        setShow(false);
        setData();
        setOpenSnackbar({ success: false, error: false });
        if (location === "/NewApp") {
          window.location.replace(
            `/app/${response.data.name.replaceAll(" ", "_")}/${
              response.data._id
            }`
          );
        } else {
          window.location.reload();
        }
      }, 5000);
    })
    .catch((error) => {
      setLoading(false);
      const err = error.response;
      console.log(error);
      if (
        err.error ===
        "An error occurred while trying to claim the site, please try again later."
      ) {
        setText(
          "An error occurred while trying to claim the site, please try again later."
        );
      } else {
        resetCode();
        setOpenSnackbar({ success: false, error: true });
        setTimeout(() => {
          setOpenSnackbar({ success: false, error: false });
        }, 4000);
      }
    });
};

// top6 best Sites
export const topSites = (setData) => {
  axios
    .get(`/sites/top6_saved`)
    .then((response) => {
      const data = response.data;
      data.forEach((element) => {
        element.urlShow = element.url.replace("https://www.", "");
        element.viewed = false;
        element.nameExpanded = element.name.replaceAll(" ", "_");
      });
      setData(data);
    })
    .catch((error) => {
      console.log(error);
    });
};

// top6 best Sites User
export const topSitesUser = (setData, idUser) => {
  axios
    .get(`/sites/top6_saved_logged/${idUser}`)
    .then((response) => {
      const data = response.data;
      data.forEach((element) => {
        element.urlShow = element.url.replace("https://www.", "");
        element.viewed = false;
        element.nameExpanded = element.name.replaceAll(" ", "_");
      });
      setData(data);
    })
    .catch((error) => {
      console.log(error);
    });
};

// Top ten last Apps
export const topLastApps = (setLast) => {
  axios
    .get(`/sites/get_lastest_added`)
    .then((response) => {
      const data = response.data;
      data.forEach((element) => {
        element.urlShow = element.url.replace("https://www.", "");
        element.viewed = false;
        element.nameExpanded = element.name.replaceAll(" ", "_");
      });
      setLast(data);
    })
    .catch((error) => {
      console.log(error);
    });
};

// Reviews App
export const reviewSite = (id, setReviews, setEditReview, setMyReview) => {
  axios
    .get(`/reviews/site/${id}`)
    .then((response) => {
      const user = JSON.parse(localStorage.xuser);
      const data = response.data;
      data.forEach((item) => {
        item.newDate = item.created_at.substr(5, 6);
        item.rating = Number(item.rating);
        if (item.user_id === user._id) {
          setEditReview(true);
          setMyReview(item);
        }
      });
      setReviews(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

// Raiting App
export const ratingSite = (id, setRating) => {
  axios
    .get(`/reviews/${id}/average_rating`)
    .then((response) => {
      const rating = response.data.average;
      let ra = rating.toString().substr(0, 3);
      ra = Number(ra);
      setRating(ra);
    })
    .catch((error) => {
      console.log(error);
    });
};

// get data one Site not users
export const getData = (id, setSiteid) => {
  axios
    .get(`/sites/${id}`)
    .then((response) => {
      const data = response.data;
      data.urlShow = data.url
        .replace("https://www.", "")
        .replace("http://www.", "");
      data.nameExpanded = data.name.replaceAll(" ", "_");
      setSiteid(data);
    })
    .catch((error) => {
      console.log(error);
    });
};

// get data one Site for users
export const getDataUser = (id, setSiteid, idUser, setTextButton) => {
  axios
    .post(`/sites/${id}`, idUser)
    .then((response) => {
      const data = response.data[0];
      data.saved
        ? setTextButton("Remove from Favorites")
        : setTextButton("Save to Favorites");
      data.urlShow = data.url
        .replace("https://www.", "")
        .replace("http://www.", "");
      data.nameExpanded = data.name.replaceAll(" ", "_");
      setSiteid(data);
    })
    .catch((error) => {
      console.log(error);
    });
};
// get data one Site for users
export const getUserScore = (userId, setUserScore) => {
  axios
    .get(`/sites/user_actions/score_breakdown/${userId}`)
    .then((response) => {
      const data = response.data;
      console.log("user score data", data);
      console.log(data);
      setUserScore(data);
    })
    .catch((error) => {
      console.log(error);
    });
};

// get data one Site for users
export const getUserScoreBreakdown = (userId) => {
  axios
    .get(`/sites/user_actions/score_breakdown/${userId}`)
    .then((response) => {
      const data = response.data;
      console.log("user score data", data);
    })
    .catch((error) => {
      console.log(error);
    });
};
