import React, { useContext, useEffect} from "react";
import {Box, Typography} from '@mui/material';
import { CardSaved } from "./globalComponents/cards/CardSaved";
import { appsLikeContext } from "./Layout";
import { mySaves } from "./globalComponents/connections";

export const Favorites = () => {
    const { appsLike, setAppsLike, setSite } = useContext(appsLikeContext)
    
    // Favorites Apps 
    useEffect(() => {
        const user = JSON.parse(localStorage.xuser)
        mySaves(user._id, setAppsLike)
        setTimeout(() => {
            setSite("Favorites")
        }, 0o100);
    }, [setAppsLike, setSite]);
    
    return(
        <Box sx={{ display: {xs:"flex", md: "grid"}, flexDirection: "column", gridTemplateColumns: {md: "45% 45%", lg:"30% 30% 30%"},  width: {sm:"90%", md:"100%"},justifyContent: "center", padding: {xs: "0", md:"2%"}, alignSelf: "center"}}>
            {appsLike.length > 0 ? appsLike.map(element => (
                <Box key={element._id} sx={{ marginX: 2, paddingY: "7%", paddingX: "3%", borderBottom: "1px solid #F4F6F9", ":hover": ""}}>
                    <CardSaved element={element} page={"Favorites"}  data={appsLike}/>
                </Box>)
            ):
                <Box sx={{width: "100%", alignSelf:"center", marginTop: "15px", fontSize: "12px"}}>
                    <Typography variant="h2" gutterBottom sx={{alignSelf:"center", marginTop: "15px", fontSize: "12px"}}>You have no favorite apps.</Typography>
                </Box>
            }
        </Box>
    )
}