import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {Box, Typography} from '@mui/material';

export const Tabs =()=>{
    const token = localStorage.token
    const location = useLocation()
    const navigate = useNavigate();
    const validationLocation = location.pathname !== "/" && location.pathname !== "/Favorites" && location.pathname !== "/NewApp" ? true : false
    const [activate, setActivate] = useState({
        app: true,
        favorites: false,
        newApp: false,
    })

    useEffect(()=>{
        if(location.pathname === "/"){setActivate({ app: true, favorites: false, newApp: false, })}
        if(location.pathname === "/Favorites"){setActivate({ app: false, favorites: true, newApp: false, })}
        if(location.pathname === "/NewApp"){setActivate({ app: false, favorites: false, newApp: true, })}
    }, [location, setActivate])

    return (
        <Box sx={{ display: "grid", gridTemplateColumns: "33% 33% 34%", width: '100%', marginTop: "30px", borderBottom: validationLocation ? "solid 2px var(--black-100)": ""}}>
          <Box sx={{textAlign: "center", cursor:"pointer", borderBottom: activate.app ? "solid 2px var(--black-100)": "", paddingBottom: "1rem"}} onClick={()=>{navigate("/")}}>
              <Typography sx={{fontWeight: "bold", fontSize: "0.8rem"}}>APPS</Typography> 
          </Box>          
          <Box sx={{textAlign: "center", cursor:"pointer", borderBottom: activate.favorites ? "solid 2px var(--black-100)":"", paddingBottom: "1rem"}} onClick={() => {token? navigate("/Favorites") : navigate("/Login")}}>
              <Typography sx={{fontWeight: "bold", fontSize: "0.8rem"}}>FAVORITES</Typography> 
          </Box>
          <Box sx={{textAlign: "center", cursor:"pointer", borderBottom: activate.newApp ? "solid 2px var(--black-100)":"", paddingBottom: "1rem"}} onClick={() => {token? navigate("/NewApp") : navigate("/Login")}}>
              <Typography sx={{fontWeight: "bold", fontSize: "0.8rem"}}>INDEX NEW APPS</Typography> 
          </Box>
        </Box>
      );
    
}