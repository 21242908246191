import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Box, Typography, TextField, CircularProgress } from "@mui/material";
import { ButtonSimple } from "../globalComponents/inputs/inputs";
import { otpValidate } from "../globalComponents/connections";
import { SnackbarBasic } from "../globalComponents/feedBacks/FeedBacks";
import { useLocation } from "react-router-dom";

const styles = {
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "2%",
    paddingBlock: "20px",
    alignSelf: "center",
    marginTop: 3,
    gap: { xs: 1, lg: 0 },
  },
  h2: {
    fontSize: { xs: "28px", sm: "32px" },
    textAlign: "center",
    width: "100%",
    alignSelf: "center",
    marginY: "5%",
    fontWeight: "bold",
  },
  div: {
    alignSelf: "center",
    width: { xs: "100%", md: "70%" },
    marginY: { xs: "25px", md: "30px" },
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginTop: "5px",
    marginBottom: "20px",
    width: "40px",
    height: { xs: "40px", md: "38px" },
    fieldset: {
      borderRadius: "15px",
    },
    div: {
      alingItems: "center",
    },
  },
  typography: {
    color: "var(--black-100)",
    textAlign: "justify",
    marginTop: "20px",
    width: "100%",
    fontWeight: 600,
    fontSize: "13px",
  },
  typography2: {
    textAlign: "center",
    marginTop: "20px",
    width: "100%",
    fontWeight: "bold",
    fontSize: "13px",
    cursor: "pointer",
  },
  span: {
    marginBlock: "10px",
    color: "red",
    fontWeight: "600",
    fontSize: "15px",
  },
  snackbar: {
    width: "300px",
  },
  button: {
    marginY: "30px",
    width: { xs: "300px", md: "400px" },
    height: "50px",
    borderRadius: "15px",
    background: "#262626",
    ":hover": {
      background: "#262626",
    },
    div: {
      display: "flex",
      justifyContent: "center",
    },
  },
};
export const ClaimSite = ({
  setShow,
  resetForm,
  dataOtp,
  setDataOtp,
  setShowEdit,
  setExecuto,
}) => {
  const location = useLocation();
  const [text, setText] = useState("Send a new email");
  const [counterSendEmail, setCounterSendEmail] = useState(0);
  const [disabledInput, setDisabledInput] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState({
    success: false,
    error: false,
    successEdit: false,
  });
  const [loading, setLoading] = useState(false);
  const validation =
    openSnackbar.error || text !== "Send a new email" ? true : false;
  const {
    register,
    handleSubmit,
    watch,
    setFocus,
    reset,
    formState: { errors },
  } = useForm();
  const code1 = watch("user.code1");
  const code2 = watch("user.code2");
  const code3 = watch("user.code3");
  const code4 = watch("user.code4");
  const code5 = watch("user.code5");
  const code6 = watch("user.code6");

  useEffect(() => {
    if (code1) {
      setFocus("user.code2");
    }
    if (code2) {
      setFocus("user.code3");
    }
    if (code3) {
      setFocus("user.code4");
    }
    if (code4) {
      setFocus("user.code5");
    }
    if (code5) {
      setFocus("user.code6");
    }
    if (code6) {
      if (code1 === "") {
        setFocus("user.code1");
      }
      if (code2 === "") {
        setFocus("user.code2");
      }
      if (code3 === "") {
        setFocus("user.code3");
      }
      if (code4 === "") {
        setFocus("user.code4");
      }
      if (code5 === "") {
        setFocus("user.code5");
      }
      if (code6 === "") {
        setFocus("user.code6");
      }
    }
  }, [code1, code2, code3, code4, code5, code6, setFocus]);

  const newEmail = () => {
    setCounterSendEmail(counterSendEmail + 1);
    if (counterSendEmail > 2) {
      setDisabledInput(true);
      setText(
        "You have exceeded the limit of codes sent to your email, please try again later."
      );
    } else {
      axios
        .post(`/sites/create_otp`, dataOtp)
        .then((response) => {
          setOpenSnackbar({ success: false, error: false });
          setText("Code Sent");
        })
        .catch((error) => {
          setText("We had an error sending the code, please try again later");
        });
    }
  };

  const onSubmit = ({ user }) => {
    setLoading(true);
    const code = code1 + code2 + code3 + code4 + code5 + code6;
    const data = {
      user_id: dataOtp.user_id,
      site_url: dataOtp.site_url,
      admin_email: dataOtp.email,
      code: code,
    };
    otpValidate(
      data,
      resetForm,
      setShow,
      setDataOtp,
      setOpenSnackbar,
      setLoading,
      setText,
      reset,
      location.pathname
    );
    setText("Send a new email");
    if (openSnackbar.success) {
      setShowEdit(false);
      setExecuto((data) => !data);
    }
  };

  return (
    <Box sx={styles.box}>
      <Box sx={styles.paper}>
        <Box sx={styles.div}>
          <Typography variant="h2" sx={styles.h2}>
            Domain Validation
          </Typography>
          <Typography variant="subtitle1" gutterBottom sx={styles.typography}>
            Please check your email and enter the code received to verify your
            ownership of this app. <span style={styles.span}>*</span>
          </Typography>
        </Box>
        <Box
          component="form"
          autoComplete="off"
          style={styles.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            sx={{
              display: "flex",
              width: "90%",
              alignSelf: "center",
              justifyContent: "space-evenly",
            }}
          >
            <TextField
              required
              disabled={disabledInput}
              type={"text"}
              error={errors.code1 ? true : false}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 1,
                borderradius: "15px",
              }}
              sx={styles.input}
              {...register("user.code1", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^\d{1}$/,
                  message: "Please, enter the digit.",
                },
              })}
            />
            <TextField
              required
              disabled={disabledInput}
              type={"text"}
              error={errors.code2 ? true : false}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 1,
                borderRadius: "15px",
              }}
              sx={styles.input}
              {...register("user.code2", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^\d{1}$/,
                  message: "Please, enter the digit.",
                },
              })}
            />
            <TextField
              disabled={disabledInput}
              type={"text"}
              error={errors.code3 ? true : false}
              required
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 1,
                borderRadius: "15px",
              }}
              sx={styles.input}
              {...register("user.code3", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^\d{1}$/,
                  message: "Please, enter the digit.",
                },
              })}
            />
            <TextField
              required
              disabled={disabledInput}
              error={errors.code4 ? true : false}
              type={"text"}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 1,
                borderRadius: "15px",
              }}
              sx={styles.input}
              {...register("user.code4", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^\d{1}$/,
                  message: "Please, enter the digit.",
                },
              })}
            />
            <TextField
              type={"text"}
              disabled={disabledInput}
              error={errors.code5 ? true : false}
              required
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 1,
                borderRadius: "15px",
              }}
              sx={styles.input}
              {...register("user.code5", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^\d{1}$/,
                  message: "Please, enter the digit.",
                },
              })}
            />
            <TextField
              required
              disabled={disabledInput}
              error={errors.code6 ? true : false}
              type={"text"}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                maxLength: 1,
                borderRadius: "15px",
              }}
              sx={styles.input}
              {...register("user.code6", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^\d{1}$/,
                  message: "Please, enter the digit.",
                },
              })}
            />
          </Box>
          <ButtonSimple
            styles={styles.button}
            text={
              loading ? <CircularProgress color="inherit" /> : "Submit Code"
            }
            type={loading ? "" : "submit"}
          />
          <Typography
            variant="subtitle1"
            gutterBottom
            color={validation ? "red" : "var(--black-100)"}
            sx={styles.typography2}
            onClick={newEmail}
          >
            {text}
          </Typography>
          <SnackbarBasic
            open={openSnackbar.success}
            styles={styles.snackbar}
            autoHideDuration={5000}
            severity={"success"}
            message={"You are now confirmed as an owner of this web app."}
            vertical={"bottom"}
            horizontal={"center"}
          />
          <SnackbarBasic
            open={openSnackbar.successEdit}
            styles={styles.snackbar}
            autoHideDuration={5000}
            severity={"success"}
            message={"Successful email modification."}
            vertical={"bottom"}
            horizontal={"center"}
          />
          <SnackbarBasic
            open={openSnackbar.error}
            styles={styles.snackbar}
            autoHideDuration={5000}
            severity={"error"}
            message={
              "The code you entered is incorrect. Please try again or send a new email."
            }
            vertical={"bottom"}
            horizontal={"center"}
          />
        </Box>
      </Box>
    </Box>
  );
};
