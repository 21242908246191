import React, {useState} from "react";
import {Table, TableHead, TableBody, TableCell, TableContainer, Paper, OutlinedInput, TableRow, IconButton, InputAdornment, Typography, Box, CircularProgress, Menu, MenuItem } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';

const stylesTableTop = {
    width: "100%", 
    marginTop: 3, 
    alignSelf: "center",
    overflowY:"scroll",
    boxShadow: { xs: "none", md: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"},
    "::-webkit-scrollbar ": {
        WebkitAppearance: "none",
    },
    "::-webkit-scrollbar:vertical": {
        width: "8px",
    },
    "::-webkit-scrollbar-button:increment":{
        display: "none",
    }, 
    "::-webkit-scrollbar-button" :{
        display: "none",
    },                          
    "::-webkit-scrollbar:horizontal": {
        height: "8px"
    },                        
    "::-webkit-scrollbar-thumb": {
        backgroundColor: "#797979",
        borderRadius: "20px",
        border: "2px solid #f1f2f3",
    },                        
    "::-webkit-scrollbar-track": {
        borderRadius: "10px",
    }
}


export const TableDashboard = ({data, setIdSiteDelete, setSearch, table, setTable, valueInputSearch, setValueInputSearch, setShowModalDelete, setShowEdit, setEditSite, pageTable, quantity, handleChangePage, handleForwardPage}) => {
    const [forward, setForward] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    return (
          <Box sx={{display: "flex", flexDirection: "column", minHeight: {xs: "300px", md:"500px"}}}>
            <Box sx={{display: "flex", flexDirection: {xs: "column", sm:"row"}, justifyContent: "space-between"}}>
              <Typography variant="h3" gutterBottom sx={{alignSelf:{xs: "center", sm: "start"}, marginTop: {xs: 3, sm: 5}, marginLeft:{xs: "2rem", sm: 0}, fontWeight: "bold", fontSize: "18px"}}>
                  Mobile web apps {table === "" || table === "Search" ? "" : table}
              </Typography>   
              <OutlinedInput
                id="outlined-adornment-password"
                type={'text'}
                value={valueInputSearch}
                sx={{ marginBottom: "5px", marginTop: {xs: "20px", sm: "45px"}, alignSelf: {xs: "center", sm: "end"}, marginRight: {sm: "30px", md: 0}, width: {xs: "300px", md: "300px"}, height: {xs:"40px", md:"38px"}, input: { color: "gray", }, fieldset: { borderRadius: "15px", }}}
                onChange={(event)=> {event.preventDefault(); setValueInputSearch(event.target.value)}}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={()=> {setSearch(true); setTable("Search");}}
                      edge="end"
                      sx={{backgroundColor: "#F5F5F5", borderRadius: "35%", borderBottomLeftRadius: 0, borderTopLeftRadius: 0, width: "50px",}}
                    >
                      <SearchIcon/>
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            {data?.length > 0 ?
              <>   
                <TableContainer component={Paper} sx={stylesTableTop}>
                  <Table sx={{  minWidth: 350}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell  sx={{fontWeight: "bold", textAlign: {xs: "center", sm: "inherit"}}}>Name</TableCell> 
                            <TableCell  sx={{fontWeight: "bold", display: {xs: "none", sm: "table-cell"}}}>Url</TableCell>
                            <TableCell  sx={{fontWeight: "bold"}}>Status</TableCell>
                            <TableCell  ></TableCell>
                            <TableCell  sx={{fontWeight: "bold", cursor: "pointer"}} onClick={(event) => {setAnchorEl(event.currentTarget)}} id="basic-button"  aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}><FilterAltIcon/></TableCell>
                              <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={() => {setAnchorEl(null)}}
                                    MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={()=>{setTable("");  setAnchorEl(null)}}>All Sites</MenuItem>
                                    <MenuItem onClick={()=>{setTable("Claimed"); setAnchorEl(null)}}>Claimed</MenuItem>
                                    <MenuItem onClick={()=>{setTable("Unclaimed"); setAnchorEl(null)}}>Unclaimed</MenuItem>
                              </Menu>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {data
                        .slice(pageTable, quantity)
                        .map((item) => (
                        <TableRow
                          key={item._id}
                          sx={{ '&:last-child td':0, '&:last-child th':0}}>
                          <TableCell sx={{textAlign: {xs: "center", sm: "inherit"}}}>{item.name}</TableCell>
                          <TableCell sx={{display: {xs: "none", sm: "table-cell"}}} >{item.url}</TableCell>
                          <TableCell sx={{textAlign: {xs: "center", sm: "start"}, fontWeight: "500", color: item.admin_email === "admin@cantonica.com" ? "red" : "#5cb85c" }} >{item.admin_email === "admin@cantonica.com" ? "Unclaimed" : "Claimed"}</TableCell>
                          <TableCell sx={{textAlign: {xs: "end", sm: "inherit"}}} ><EditIcon onClick={()=>{setEditSite(item); setShowEdit(true)}} sx={{cursor: "pointer"}}/></TableCell>
                          <TableCell sx={{textAlign: {xs: "end", sm: "inherit"}}} ><DeleteIcon onClick={()=>{setIdSiteDelete(item._id); setShowModalDelete(true)}}  sx={{cursor: "pointer"}} /></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{display: table === "Search" ? "none" : "flex", flexDirection: "row", justifyContent: "space-evenly", alignSelf: "end", margin: "16px"}}>
                    <Typography variant="body2" gutterBottom sx={{fontSize: "14px", fontWeight: "bold", marginRight: "10px"}}>{(pageTable + 1)} - {quantity} of {data.length}</Typography>        
                    <ArrowBackIosNewIcon sx={{color: pageTable !== 0 ? "#09101D" : "#727272", fontSize: "18px", marginInline: "10px", cursor: "pointer"}}  onClick={()=>(pageTable !== 0 ? handleForwardPage(setForward) : null)}/>
                    <ArrowForwardIosIcon sx={{color: forward ? "#09101D" : "#727272", fontSize: "18px", marginInline: "10px", cursor: "pointer"}} onClick={()=>(forward ? handleChangePage(setForward) : null)}/>
                </Box>
              </>
            :
              <Box sx={{height: {xs: "300px", md:"500px"}}}>
                <CircularProgress color="inherit" sx={{alignSelf: "center", marginTop: 5}}/>
              </Box>
            }
        </Box>
        );
}