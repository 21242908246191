import React from "react";
import {Box} from '@mui/material'
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

export const InputSimple = ({ placeholder, label, styles, error, type, hookForm,  inputProps}) => {
    return(
        <div style={styles.div}>
            <TextField label={label} placeholder={placeholder} variant="outlined" inputProps={inputProps} type={type} error={error} sx={styles} {...hookForm} />
        </div>
    )
}

export const InputStandard = ({placeholder, styles, error, type, hookForm,  inputProps}) => {
    return(
        <TextField  placeholder={placeholder} variant="standard"  inputProps={inputProps} type={type} error={error} sx={styles} {...hookForm}/>
    )
}

export const InputMultiline = ({placeholder, styles, error, type, hookForm,  inputProps}) => {
    return(
        <Box sx={styles.div}>
            <TextField multiline fullWidth  rows={4} placeholder={placeholder} styles={styles} error={error} type={type} {...hookForm}  inputProps={inputProps} />
        </Box>
    )
}
export const InputPassword = ({styles, label, error, hookForm, inputProps, showPassword, setShowPassword}) => {
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return(
        <div style={styles.div}>
                <TextField
                    sx={styles}
                    inputProps={inputProps}
                    type={showPassword ? 'text' : 'password'}
                    label={label}
                    error={error}
                    {...hookForm}
                    InputProps={{
                        endAdornment:
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>,
                    }}
                />        
        </div>
    )
}

export const InputSearch = ({onChange, styles, placeholder, disabled, onKeyUp}) => {
    return(
        <div style={styles.div}>
            <TextField
                    sx={styles}
                    disabled={disabled}
                    onKeyUp={onKeyUp}
                    placeholder={placeholder}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>
                    }}
                    onChange={onChange}
                    />        
        </div>
    )
}

export const InputSelect = ({  value, label, onChange, data, styles}) => {
    return(
            <Select value={value} label={label} onChange={onChange} labelId="demo-simple-select-label"  id="demo-simple-select" sx={styles}>
                {data.length === 0 ?
                    <MenuItem value={""}>No hay elementos</MenuItem>
                :
                data.map((element) => (
                    <MenuItem key={element.id} value={element.value}>{element.text}</MenuItem>
                ))
                }
            </Select>
    )
}

export const ButtonSimple = ({ text, onClick, styles, type, disabled}) => {
    return(
        <div style={styles.div}>
             <Button variant="contained" type={type} onClick={onClick} disabled={disabled} sx={styles}>{text}</Button>
        </div>
    )
}

export const ButtonIcon = ({ text, onClick, styles, type, startIcon, endIcon, variant}) => {
    return(
        <div style={styles.div}>
             <Button variant={variant} type={type} onClick={onClick} sx={styles} startIcon={startIcon} endIcon={endIcon}>{text}</Button>
        </div>
    )
}
