//Storing the team static data
export const AppData = [{
    id: 1,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},
{
    id: 2,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},

{
    id: 3,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},

{
    id: 4,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},

{
    id: 5,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},

{
    id: 6,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},

{
    id: 7,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},

{
    id: 8,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},

{
    id: 9,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},

{
    id: 10,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},


{
    id: 11,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},


{
    id: 12,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},


{
    id: 13,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},



{
    id: 14,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},



{
    id: 15,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},


{
    id: 16,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},



{
    id: 17,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},


{
    id: 18,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},



{
    id: 19,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
},



{
    id: 20,
    image: "https://www.freepnglogos.com/uploads/spotify-logo-png/image-gallery-spotify-logo-21.png",
    name: "Spotify",
    category: "Education",
    rating: 5.0
}

] 