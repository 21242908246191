import React, { useContext, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { removeApp, removeAppSearch } from "../connections";
import { allAppsContext } from "../../../App";
import { appsLikeContext } from "../../Layout";

export const CardSaved = ({element, page, setResults}) => {
    const { dataUser, setDataUser } = useContext(allAppsContext)
    const { setAppsLike } = useContext(appsLikeContext)
    const [colorTitle, setColortitle] = useState("#09101D")
    const navigate = useNavigate();

    //remove like
    const disliked = (e) => {
        e.stopPropagation()
        if(localStorage.xuser){
            const user = JSON.parse(localStorage.xuser)
            page === "Favorites" ?
            removeApp(user._id, element, dataUser, setDataUser, setAppsLike)
            :
            removeAppSearch(user._id, element, dataUser, setDataUser, setResults, setAppsLike)
        }
    }    

    const info = () => {
        navigate(`/app/${element.nameExpanded}/${element._id}`)
        setColortitle("747B84")
    }

    return(
        <>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-around", cursor: "pointer"}}>
                <Box
                    component="img"
                    src={element.logo}
                    alt={element.name}
                    onClick={info}
                    sx={{width: {xs: "30px", sm: "50px", md:"68px"}, height: {xs:"30px", sm: "50px", md:"68px"}, borderRadius:{xs:"15px", md: "24px"}, cursor: "pointer"}}
                />
                <Typography variant="h3" sx={{fontSize: "18px", textAlign: "initial", color: colorTitle, fontWeight: "700", width: {xs:"65%", md: "60%"}, marginLeft:{md: "5%"}, height: "21px", overflow: "hidden", cursor:"pointer"}} onClick={info}>{element.name.charAt(0).toUpperCase() + element.name.slice(1) }</Typography>
                <DeleteOutlineIcon onClick={disliked}/>
            </Box>
            <Typography variant="body2" onClick={info} sx={{ fontSize: "12px",  textAlign: "justify", color: "#333333", marginTop: {xs:"15px", md:"20px"}, marginLeft: "5%", fontWeight: "500", maxHeight: "50px", overflow: "hidden", textOverflow: "ellipsis", cursor: "pointer"}}>{element.description} </Typography>
        </>
    )
}
