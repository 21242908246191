import React from "react";
import { ButtonIcon } from "../inputs/inputs";
import "./Uploader.css"

export const Uploader = ({hookForm, media, text}) => {
    const conditional = media !== undefined && media.length > 0 

     const stylesObj = {
        marginY: "10px",
        width: text === "Update Logo" ? "140px" : "113px",
        height: text === "Update Logo" ? "30px" : "44px",
        borderRadius: "15px",
        background: conditional ? "#262626" : "#B7B7B7",
        ":hover": {
            background: conditional ? "#262626" : "#B7B7B7",
        },
    }    

    return(
        <div className="input-file">
            <input type="file" accept="image/*" {...hookForm} />
            <ButtonIcon containerClassname={"button"} text={text} styles={stylesObj} variant="contained" />
        </div>
    )
}