import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from 'swiper';
import { Box } from "@mui/material"
import notFound from "../imagens/imageNotFound.png"
import "./Slider.css"

import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/pagination"
import "swiper/css/navigation"

import { EffectCoverflow} from "swiper"

export const Slider = ({images, hookForm, media, handleOpenModal}) => {
    const token = localStorage.token
    const dataImages = [{img:notFound, replace:false}, {img:notFound, replace:false}, {img:notFound, replace:false}, {img:notFound, replace:false}]
    dataImages.forEach((element, index)=> {
        if(images?.length > 0 && images[index] !== undefined){
            element.img = images[index]
            element.replace = true
        }  
    })
    
    const validatorImage = (imageDefault, imageForm) => {
        let image
        if(imageForm !== undefined && imageForm.length > 0){
            image =  URL.createObjectURL(imageForm[0])
        } else if(imageDefault !== notFound){
            image = imageDefault
        } else {
            image = notFound
        }
        return image
    }

    return(
            <Swiper
                modules={[Navigation, EffectCoverflow]}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={false}
                loop={false}
                slidesPerView={3}
                navigation
                coverflowEffect={{ rotate: 0, stretch: 0, depth: 100, modifier: 2, slideShadows: false,}}
            >
                {dataImages && dataImages.map((element, index) => (
                    <SwiperSlide
                     key={index}>
                        {element.replace ?
                            <Box
                                component="img"
                                src={element.img}
                                onClick={()=>{handleOpenModal(element.img)}}
                                alt={""}
                                id={""}
                                sx={{width: "100%", height: "100%", borderRadius:{xs:"15px", sm: "24px"}, objectFit: "cover"}}
                            />
                        :
                            <Box sx={{display: token ? "flex": "none", flexDirection: "column", width: "100%", height: "100%", alignItems: "center"}}>
                                <Box
                                    component="img"
                                    src={validatorImage(element.img, media[index])} 
                                    alt={""}
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: { xs: "12px", md: "24px" },
                                        alignSelf: "center",
                                    }}
                                />
                                <input type="file" accept="image/*" {...hookForm(`user.img${index}`)} style={{ position: "absolute", textAlign: "right", opacity: 0, zIndex: 2, cursor: "pointer", height: "100%", width: "100%", marginTop: "10px", maxWidth: "200px"}}/>
                            </Box>                        
                        }
                    </SwiperSlide>
                ))}
            </Swiper>
    )
}
