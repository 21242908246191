import React, { useState, useRef } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import {
  InputPassword,
  InputSimple,
  ButtonSimple,
  ButtonIcon,
} from "../globalComponents/inputs/inputs";
import { Box, Paper, Typography, CircularProgress } from "@mui/material";
import Fondo from "../globalComponents/imagens/backgroundInitial.png";
import Cantonica from "../globalComponents/imagens/cantonica.png";
import GoogleIcon from "@mui/icons-material/Google";
import { LoginSocialGoogle } from "reactjs-social-login";
import { SnackbarBasic } from "../globalComponents/feedBacks/FeedBacks";

const styles = {
  hiddenButton: {
    display: "none", // Make the GoogleLogin button hidden
  },
  box: {
    height: { lg: "100vh" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    zIndex: 5,
    flexDirection: "column",
    alignSelf: "center",
    marginTop: { sm: 3 },
    gap: { xs: 1, lg: 0 },
    boxShadow: {
      xs: "none",
      md: "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    },
    width: { xs: "100%", md: "50%", lg: "40%" },
  },
  h2: {
    fontSize: "28px",
    alignSelf: { xs: "flex-start", sm: "center" },
    margin: "5%",
    fontWeight: "bold",
  },
  div: {
    alignSelf: "center",
    width: "70%",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginTop: "5px",
    marginBottom: "20px",
    width: { xs: "343px", md: "400px" },
    height: { xs: "40px", md: "38px" },
    fieldset: {
      borderRadius: "15px",
    },
    div: {
      alingItems: "center",
    },
  },
  button: {
    marginY: "10px",
    width: { xs: "343px", md: "400px" },
    height: "50px",
    borderRadius: "15px",
    background: "var(--btn-green)",
    ":hover": {
      background: "var(--btn-green)",
    },
  },
  buttonFace: {
    width: { xs: "343px", md: "400px" },
    height: "50px",
    borderRadius: "15px",
    color: "white",
    marginBottom: "10px",
    background: "#425993",
    ":hover": {
      background: "#425993",
    },
  },
  buttonGoo: {
    width: { xs: "343px", md: "400px" },
    height: "50px",
    borderRadius: "15px",
    color: "white",
    background: "#5384EC",
    ":hover": {
      background: "#5384EC",
    },
  },
  text: {
    color: "var(--black-100)",
    width: "100%",
    fontWeight: "600",
    fontSize: "13px",
  },
  typography: {
    color: "var(--black-100)",
    marginY: "15px",
    width: "100%",
    cursor: "pointer",
    fontWeight: "600",
    fontSize: "13px",
  },
  span: {
    marginInline: "10px",
    color: "#F60000",
    fontWeight: "600",
    fontSize: "13px",
  },
  error: {
    fontSize: "12px",
    color: "red",
    fontWeight: "bold",
    width: "90%",
    overflow: "hidden",
    alignSelf: "center",
  },
};

export const FormLogin = () => {
  const googleRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const err = errors.user === undefined ? {} : errors.user;

  const REDIRECT_URI = process.env.REDIRECT_URI;
  const handleFailure = (result) => {
    console.log(result);
    console.log("hubo un error");
  };

  const handleLoginGoogle = ({ data }) => {
    const user = {
      name: data.name,
      email: data.email,
      isGoogle: true,
      uidGoogle: data.sub,
    };
    axios
      .post(`/users/authentication`, user)
      .then((response) => {
        const token = response.data.token;
        const user = response.data.user;
        localStorage.token = token;
        localStorage.xuser = JSON.stringify(user);
        navigate(-1) === undefined ? navigate("/") : navigate(-1);
      })
      .catch((error) => {
        console.log(error);
        setOpenSnackbar(true);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 5000);
      });
  };

  const onSubmit = ({ user }) => {
    setLoading(true);
    axios
      .post(`/users/authentication`, user)
      .then((response) => {
        const token = response.data.token;
        const user = response.data.user;
        localStorage.token = token;
        localStorage.xuser = JSON.stringify(user);
        setLoading(false);
        navigate(-1) === undefined ? navigate("/") : navigate(-1);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (error.response.status === 500) {
          setMessage({ email: "this email doesn't exist" });
          setTimeout(() => {
            setMessage({ email: "" });
          }, 7000);
        } else if (error.response.status === 401) {
          setMessage({ password: "Password is incorrect" });
          setTimeout(() => {
            setMessage({ password: "" });
          }, 7000);
        } else {
          setOpenSnackbar(true);
          setTimeout(() => {
            setOpenSnackbar(false);
          }, 5000);
        }
      });
  };

  return (
    <Box sx={styles.box}>
      <Box
        component="img"
        src={Fondo}
        sx={{
          display: { xs: "none", lg: "block" },
          width: "-webkit-fill-available",
          height: "-webkit-fill-available",
          position: "absolute",
          zIndex: 0,
        }}
      />
      <Box
        component="img"
        src={Cantonica}
        alt={"Logo_Cantonica"}
        onClick={() => {
          navigate("/");
        }}
        sx={{
          width: { xs: "200px", md: "250px" },
          alignSelf: "center",
          zIndex: 5,
          marginTop: { xs: "24px", sm: "30px" },
        }}
      />
      <Paper sx={styles.paper} elevation={0}>
        <Typography variant="h2" sx={styles.h2}>
          Login
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={styles.form}>
          <InputSimple
            label={"Email"}
            error={err.email ? true : false}
            type={"text"}
            inputProps={{ maxLength: 40, borderRadius: "15px" }}
            styles={styles.input}
            hookForm={{
              ...register("user.email", {
                required: "This field can´t be empty",
                pattern: {
                  value:
                    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
                  message: "Please, Enter a valid email",
                },
              }),
            }}
          />
          <ErrorMessage
            errors={errors}
            name="user.email"
            render={({ message }) => (
              <span style={styles.error}>{message}</span>
            )}
          />
          <span style={styles.error}>{message.email}</span>
          <InputPassword
            label={"Password"}
            error={err.password ? true : false}
            showPassword={showPassword}
            inputProps={{ maxLength: 16 }}
            setShowPassword={setShowPassword}
            styles={styles.input}
            hookForm={{
              ...register("user.password", {
                required: "This field can´t be empty",
                pattern: {
                  value: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
                  message:
                    "The password must be between 8 and 16 characters, at least one digit, one lower case and one upper case.",
                },
              }),
            }}
          />
          <ErrorMessage
            errors={errors}
            name="user.password"
            render={({ message }) => (
              <span style={styles.error}>{message}</span>
            )}
          />
          <span style={styles.error}>{message.password}</span>
          <ButtonSimple
            styles={styles.button}
            text={loading ? <CircularProgress color="inherit" /> : "Continue"}
            type={"submit"}
          />
        </form>
        <div>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={styles.text}
            onClick={() => navigate("/RecoveryPassword")}
          >
            or
          </Typography>
          <LoginSocialGoogle
            ref={googleRef}
            client_id={
              "763415949181-980aboip7npob76djldc7slp7hniljs1.apps.googleusercontent.com"
            }
            onLogoutFailure={handleFailure}
            redirect_uri={REDIRECT_URI}
            scope="openid profile email"
            discoveryDocs="claims_supported"
            onResolve={handleLoginGoogle}
            onReject={(err) => {
              console.log("ERROR: ", err);
              setOpenSnackbar(true);
              setTimeout(() => {
                setOpenSnackbar(false);
              }, 5000);
            }}
          >
            <ButtonIcon
              styles={styles.buttonGoo}
              variant={"contained"}
              text={"Continue with Google"}
              startIcon={<GoogleIcon />}
            />
          </LoginSocialGoogle>
        </div>
        <div styles={styles.div}>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={styles.typography}
            onClick={() => navigate("/RecoveryPassword")}
          >
            Forgot your password
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              marginInline: "10px",
              color: "#F60000",
              fontWeight: "600",
              fontSize: "13px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/Signup")}
          >
            Register via Email
          </Typography>
        </div>
        <SnackbarBasic
          open={openSnackbar}
          styles={{ width: "300px" }}
          autoHideDuration={5000}
          severity={"warning"}
          message={"Sorry, there was a problem. Please try again later"}
          vertical={"bottom"}
          horizontal={"center"}
        />
      </Paper>
    </Box>
  );
};
