import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Avatar, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { InputSearch } from "./inputs/inputs";
import { tabsContext } from "../Layout";
import Logo from "./imagens/cantonica.png";
import { Tabs } from "./tables/newTabs";
import { getUserScore, getUserScoreBreakdown } from "./connections";
import { Link } from "react-router-dom";
import { ButtonSimple } from "../globalComponents/inputs/inputs";

const styleButton = {
  marginTop: { xs: "15px", sm: "30px" },
  width: { xs: "139px", lg: "150px" },
  height: { xs: "25px", lg: "35px" },
  borderRadius: "15px",
  fontSize: "10px",
  background: "#262626",
  ":hover": {
    background: "#262626",
  },
  div: {
    display: "flex",
    alignContent: "flex-start",
    width: "100%",
  },
};

const stylesInput = {
  marginBottom: "5px",
  marginTop: { xs: 0, sm: "45px" },
  marginRight: { sm: "30px", md: 0 },
  width: { xs: "343px", md: "400px" },
  height: { xs: "40px", md: "38px" },
  input: {
    color: "gray",
  },
  fieldset: {
    borderRadius: "15px",
  },
  div: {
    display: { md: "flex" },
    height: { md: "40px" },
    alignContent: { xs: "center", md: "end" },
  },
};
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const Header = () => {
  const navigate = useNavigate();
  const token = localStorage.token;
  const user = token ? JSON.parse(localStorage.xuser) : false;
  const { setInputValue } = useContext(tabsContext);
  const nameUser =
    localStorage.xuser !== undefined ? JSON.parse(localStorage.xuser) : "";

  function stringAvatar(name) {
    const nameUser = name.nameUser.name;
    const validation = nameUser.split(" ").length > 1;

    return {
      sx: {
        bgcolor: stringToColor(nameUser),
        cursor: "pointer",
      },
      children: validation
        ? `${nameUser.toUpperCase().split(" ")[0][0]}${
            nameUser.toUpperCase().split(" ")[1][0]
          }`
        : `${nameUser.split(" ")[0][0]}`,
    };
  }

  const handleSearch = (e) => {
    setInputValue(e.target.value);
    const word = e.target.value;
    if (e.key === "Enter" && word.length >= 1) {
      navigate(`/searching/${word}`);
    }
  };

  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const open3 = Boolean(anchorEl3);
  const [anchorEl4, setAnchorEl4] = useState(null);
  const open4 = Boolean(anchorEl4);
  const [userScore, setUserScore] = useState(0);

  useEffect(() => {
    getUserScore(user._id, setUserScore);
    console.log(user);
    getUserScoreBreakdown(user._id);
  }, []);

  const handleScoreClick = () => {
    console.log("You have clicked");
  };

  return (
    <Box sx={{ position: "sticky", top: 0, zIndex: 10, background: "white" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: {
            xs: "space-between",
            sm: "space-evenly",
            md: token ? "space-around" : "space-between",
          },
          width: "100%",
          borderBottom: "1px solid #F4F6F9",
          paddingBottom: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "space-between", md: "space-around" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Box
              component="img"
              src={Logo}
              sx={{ width: "245px", height: "43px", marginTop: "45px" }}
              alt="logo_cantonica"
              onClick={() => {
                navigate("/");
              }}
            />
            {token ? (
              <Box
                sx={{
                  display: { xs: token ? "block" : "none", sm: "none" },
                  cursor: "pointer",
                  alignSelf: "end",
                  marginBottom: "10px",
                }}
              >
                <Avatar
                  {...stringAvatar({ nameUser })}
                  id="basic-button"
                  aria-controls={open1 ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open1 ? "true" : undefined}
                  onClick={(event) => {
                    setAnchorEl1(event.currentTarget);
                  }}
                />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl1}
                  open={open1}
                  onClose={() => {
                    setAnchorEl1(null);
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl1(null);
                      navigate("/profile");
                    }}
                  >
                    Profile
                  </MenuItem>
                  {user?.isAdmin ? (
                    <MenuItem
                      onClick={() => {
                        setAnchorEl1(null);
                        navigate("/Dashboard_admin");
                      }}
                    >
                      Dashboard
                    </MenuItem>
                  ) : (
                    ""
                  )}
                  <MenuItem
                    onClick={() => {
                      setAnchorEl1(null);
                      localStorage.clear();
                      navigate("/Login");
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box
                sx={{
                  display: { xs: !token ? "block" : "none", sm: "none" },
                  alignSelf: "end",
                  marginBottom: "5px",
                }}
              >
                <MenuIcon
                  sx={{ fontSize: "24px", cursor: "pointer" }}
                  onClick={(event) => {
                    setAnchorEl3(event.currentTarget);
                  }}
                  id="basic-button3"
                  aria-controls={open3 ? "basic-menu3" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open3 ? "true" : undefined}
                />
                <Menu
                  id="basic-menu3"
                  anchorEl={anchorEl3}
                  open={open3}
                  onClose={() => {
                    setAnchorEl3(null);
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button3",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl1(null);
                      navigate("/Login");
                    }}
                  >
                    Login
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
          <InputSearch
            styles={stylesInput}
            placeholder={"search"}
            onKeyUp={handleSearch}
          />
        </Box>
        <Box>
          {token ? (
            <Box
              sx={{
                display: { xs: "none", sm: token ? "flex" : "none" },
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                width: "60px",
                marginTop: "45px",
                borderLeft: "1px solid #F4F6F9",
              }}
            >
              <Avatar
                {...stringAvatar({ nameUser })}
                id="basic-button2"
                aria-controls={open2 ? "basic-menu2" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                onClick={(event) => {
                  setAnchorEl2(event.currentTarget);
                }}
              />
              <Menu
                id="basic-menu2"
                anchorEl={anchorEl2}
                open={open2}
                onClose={() => {
                  setAnchorEl2(null);
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button2",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl2(null);
                    navigate("/profile");
                  }}
                >
                  Profile
                </MenuItem>
                {user?.isAdmin ? (
                  <MenuItem
                    onClick={() => {
                      setAnchorEl1(null);
                      navigate("/Dashboard_admin");
                    }}
                  >
                    Dashboard
                  </MenuItem>
                ) : (
                  ""
                )}
                <MenuItem
                  onClick={() => {
                    setAnchorEl2(null);
                    localStorage.clear();
                    navigate("/Login");
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box
              sx={{
                display: { xs: "none", sm: !token ? "flex" : "none" },
                alignItems: "center",
                justifyContent: "center",
                width: "60px",
                marginTop: "45px",
                marginRight: "45px",
              }}
            >
              <MenuIcon
                sx={{ fontSize: "24px", cursor: "pointer" }}
                onClick={(event) => {
                  setAnchorEl4(event.currentTarget);
                }}
                id="basic-button4"
                aria-controls={open4 ? "basic-menu4" : undefined}
                aria-haspopup="true"
                aria-expanded={open4 ? "true" : undefined}
              />
              <Menu
                id="basic-menu4"
                anchorEl={anchorEl4}
                open={open4}
                onClose={() => {
                  setAnchorEl4(null);
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button4",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl4(null);
                    navigate("/Login");
                  }}
                >
                  Login
                </MenuItem>
              </Menu>
            </Box>
          )}

          <Link to={`/AboutUs`} style={{ textDecoration: "none" }}>
            <ButtonSimple styles={styleButton} text={"About Us"} />
          </Link>

          {/* <Box sx={{ marginTop: "15px" }} onClick={handleScoreClick}>
            {userScore} Hello world
          </Box> */}
        </Box>
      </Box>
      <Tabs />
    </Box>
  );
};
