import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from '@mui/material';

export const CardImage = ({ element}) => {
    const navigate = useNavigate();
 
    return(
        <Box>
            <Box
                component="img"
                src={element.logo}
                onClick={()=>{navigate(`/app/${element.nameExpanded}/${element._id}`)}}
                alt={element.name}
                id={element._id}
                sx={{width: {xs: "68px", sm: "80px", md: "100px"}, height: {xs: "68px", sm: "80px", md: "100px"}, borderRadius:{xs:"15px", md: "24px"}}}
            />
            <Typography variant="h3" sx={{fontSize: 12, fontWeight:"bold", marginTop: "2px"}}>{element.name}</Typography>
        </Box>
    )
}