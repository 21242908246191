import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Modal,
  TextField,
} from "@mui/material";
import { TableApps } from "./globalComponents/tables/TableApps";
import { myCreatedApps, DeleteSite } from "./globalComponents/connections";
import Logo from "../components/globalComponents/imagens/cantonica.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FormEditProfile } from "./forms/FormEditProfile";
import { ButtonIcon, ButtonSimple } from "./globalComponents/inputs/inputs";
import { FormEditApp } from "./forms/FormEditApp";
import { SnackbarBasic } from "./globalComponents/feedBacks/FeedBacks";
import axios from "axios";

export const Profile = () => {
  const navigate = useNavigate();
  const user =
    localStorage.xuser !== undefined
      ? JSON.parse(localStorage.xuser)
      : navigate("/Login");
  const [mySites, setMySites] = useState([]);
  const [infoSiteEdit, setInfoSiteEdit] = useState({});
  const [loading, setLoading] = useState(true);
  const [executor, setExecuto] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showReferrals, setShowReferrals] = useState(false);
  const [showModalReferrals, setShowModalReferrals] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [idSiteDelete, setIdSiteDelete] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.xuser);
    if (!executor) {
      myCreatedApps(user._id, setMySites, setLoading);
    } else {
      myCreatedApps(user._id, setMySites, setLoading);
    }
  }, [setMySites, setLoading, executor]);

  const handleCloseEdit = () => {
    setShowModalEdit(false);
  };
  const handleCloseReferrals = () => {
    setShowModalReferrals(false);
  };
  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };

  const handleReturn = () => {
    if (showEdit) {
      setShowEdit(false);
      setInfoSiteEdit({});
    } else if (showReferrals) {
      setShowReferrals(false);
    } else {
      navigate("/");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingBottom: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          width: "90%",
          alignSelf: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignSelf: "start",
            marginX: { xs: 0, sm: 5 },
          }}
        >
          <ArrowBackIcon
            onClick={handleReturn}
            sx={{
              alignSelf: "center",
              fontSize: "40px",
              marginTop: "45px",
              cursor: "pointer",
            }}
          />
          <Box
            component="img"
            src={Logo}
            onClick={() => {
              navigate("/");
            }}
            sx={{
              width: "245px",
              height: "43px",
              marginTop: "45px",
              cursor: "pointer",
            }}
            alt="logo_cantonica"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: { xs: 0, sm: 5 },
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              alignSelf: "end",
              marginTop: "25px",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            {user.name ? user.name.toUpperCase() : "Profile"}
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              alignSelf: "end",
              fontSize: "12px",
              textAlign: "start",
              cursor: "pointer",
              ":hover": { fontWeight: "bold", color: "red" },
            }}
            onClick={() => {
              setShowModalReferrals(true);
            }}
          >
            My Referrals
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              alignSelf: "end",
              fontSize: "12px",
              textAlign: "start",
              cursor: "pointer",
              ":hover": { fontWeight: "bold", color: "red" },
            }}
            onClick={() => {
              setShowModalEdit(true);
            }}
          >
            Edit Profile
          </Typography>
        </Box>
      </Box>
      {mySites.length > 0 && !loading ? (
        !showEdit ? (
          <TableApps
            data={mySites}
            setIdSiteDelete={setIdSiteDelete}
            setShowModalDelete={setShowModalDelete}
            setShowEdit={setShowEdit}
            setEditSite={setInfoSiteEdit}
          />
        ) : (
          <FormEditApp
            setShowEdit={setShowEdit}
            infoSiteEdit={infoSiteEdit}
            setInfoSiteEdit={setInfoSiteEdit}
            setExecuto={setExecuto}
          />
        )
      ) : loading && mySites.length === 0 ? (
        <CircularProgress
          color="inherit"
          sx={{ alignSelf: "center", marginTop: 5 }}
        />
      ) : (
        <Box
          sx={{
            width: "100%",
            alignSelf: "center",
            marginTop: 5,
            fontSize: "12px",
          }}
        >
          <Typography
            variant="h2"
            gutterBottom
            sx={{ alignSelf: "center", marginTop: "15px", fontSize: "12px" }}
          >
            You have no apps created
          </Typography>
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              alignSelf: "center",
              fontWeight: "bold",
              color: "red",
              marginTop: "12px",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/NewApp");
            }}
          >
            Create a New App
          </Typography>
        </Box>
      )}
      {/* Modal Edit Profile */}
      <Modal
        open={showModalEdit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 300, sm: 500 },
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <FormEditProfile closeModal={handleCloseEdit} />
        </Box>
      </Modal>
      {/* Modal Referrals*/}
      <Modal
        open={showModalReferrals}
        onClose={handleCloseReferrals}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 300, sm: 500 },
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <InfoReferrals closeModal={handleCloseReferrals} id={user._id} />
        </Box>
      </Modal>
      {/* Modal Delete App */}
      <Modal
        open={showModalDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 300, sm: 500 },
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <ModalDelete
            idSiteDelete={idSiteDelete}
            setShowModalDelete={setShowModalDelete}
            setIdSiteDelete={setIdSiteDelete}
            setMySites={setMySites}
          />
        </Box>
      </Modal>
    </Box>
  );
};

const InfoReferrals = ({ closeModal, id }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [referrals, setReferrals] = useState(0);

  useEffect(() => {
    axios
      .get(`/referrals/${id}/referreds`)
      .then((response) => {
        const num = response.data;
        console.log(response);
        setReferrals(num.referrals);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  const copyLink = () => {
    const link = `https://www.cantonica.com/Signup/Referral/${id}`;
    // const link = `http://localhost:3000/Signup/Referral/${id}` // only develop
    navigator.clipboard.writeText(link);
    setOpenSnackbar(true);
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 3000);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography
        variant="h2"
        gutterBottom
        sx={{
          textAlign: "center",
          marginTop: "25px",
          fontWeight: "bold",
          fontSize: { xs: "18px", sm: "24px" },
          color: "var(--black-100)",
        }}
      >
        Refer a Friend
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: { xs: "space-around", md: "start" },
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: "18px",
            marginTop: "1rem",
            marginX: "1rem",
            textAlign: "justify",
          }}
        >
          Link to refer:{" "}
        </Typography>
        <ButtonIcon
          startIcon={<ContentCopyIcon />}
          onClick={copyLink}
          styles={{
            display: { xs: "flex", md: "none" },
            margin: "10px",
            width: "20px",
            height: "30px",
            borderRadius: "10px",
            background: "#262626",
            ":hover": { background: "#262626" },
          }}
          variant="contained"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          marginTop: 1,
        }}
      >
        <TextField
          id="outlined-disabled"
          sx={{
            width: { xs: "343px", sm: "400px", md: "350px" },
            height: { xs: "40px", md: "30px" },
            marginX: "10px",
            fieldset: { borderRadius: "15px", height: "45px" },
          }}
          defaultValue={`https://www.cantonica.com/Signup/Referral/${id}`}
          InputProps={{
            readOnly: true,
          }}
        />
        <ButtonIcon
          startIcon={<ContentCopyIcon />}
          onClick={copyLink}
          styles={{
            display: { xs: "none", md: "flex" },
            margin: "10px",
            width: "20px",
            height: "30px",
            borderRadius: "10px",
            background: "#262626",
            ":hover": { background: "#262626" },
          }}
          variant="contained"
        />
      </Box>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontSize: "18px", margin: "1rem", textAlign: "justify" }}
      >
        Your referrals:{" "}
        <span
          style={{
            alignSelf: "end",
            fontSize: "18px",
            textAlign: "start",
            fontWeight: "bold",
            color: "red",
          }}
        >
          {referrals}
        </span>
      </Typography>
      <SnackbarBasic
        open={openSnackbar}
        styles={{ width: "auto" }}
        autoHideDuration={5000}
        severity={"info"}
        message={"Link Copied"}
        vertical={"bottom"}
        horizontal={"right"}
      />
    </Box>
  );
};
const ModalDelete = ({
  idSiteDelete,
  setIdSiteDelete,
  setShowModalDelete,
  setMySites,
}) => {
  const [text, setText] = useState(
    "Do you confirm that you are surrendering ownership of this app for others within your organization to claim and administer?"
  );
  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState("");

  const handleDelete = () => {
    DeleteSite(
      idSiteDelete,
      setSuccessful,
      setShowModalDelete,
      setIdSiteDelete,
      setError,
      setText,
      setMySites
    );
  };

  return (
    <>
      {!successful ? (
        <ErrorOutlineIcon
          sx={{
            color: "red",
            fontSize: { xs: "3rem", sm: "4rem" },
            alignSelf: "center",
          }}
        />
      ) : (
        <CheckCircleOutlineIcon
          sx={{
            color: "var(--btn-green)",
            fontSize: { xs: "3rem", sm: "4rem" },
            alignSelf: "center",
          }}
        />
      )}
      <Typography
        variant="h2"
        gutterBottom
        sx={{
          textAlign: "center",
          marginTop: "25px",
          fontWeight: "bold",
          fontSize: { xs: "18px", sm: "24px" },
          color: successful ? "var(--btn-green)" : "var(--black-100)",
        }}
      >
        {text}
      </Typography>
      {!successful ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            alignSelf: "center",
          }}
        >
          <ButtonSimple
            text={"Yes"}
            onClick={handleDelete}
            styles={{
              marginY: "30px",
              width: { xs: "120px", sm: "200px" },
              height: "50px",
              borderRadius: "15px",
              background: "#262626",
              ":hover": { background: "#262626" },
              div: { display: "flex", justifyContent: "center" },
            }}
          />
          <ButtonSimple
            text={"No"}
            onClick={() => {
              setShowModalDelete(false);
              setIdSiteDelete("");
            }}
            styles={{
              marginY: "30px",
              width: { xs: "120px", sm: "200px" },
              height: "50px",
              borderRadius: "15px",
              background: "#262626",
              ":hover": { background: "#262626" },
              div: { display: "flex", justifyContent: "center" },
            }}
          />
        </Box>
      ) : (
        ""
      )}
      <span
        style={{
          fontSize: "12px",
          color: "red",
          fontWeight: "bold",
          width: "75%",
          overflow: "hidden",
          alignSelf: "center",
        }}
      >
        {error}
      </span>
    </>
  );
};
