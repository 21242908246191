import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  Box,
  Typography,
  CircularProgress,
  Rating,
  Modal,
  LinearProgress,
} from "@mui/material";
import { ButtonIcon, ButtonSimple } from "./globalComponents/inputs/inputs";
import { Slider } from "./globalComponents/slider/Slider";
import { appsLikeContext } from "./Layout";
import { allAppsContext } from "../App";
import {
  addAppExpanded,
  removeAppExpanded,
  reviewSite,
  ratingSite,
  getData,
  getDataUser,
} from "./globalComponents/connections";
import { CardReview } from "./globalComponents/cards/CardReview";
import IconNew from "./globalComponents/imagens/new.jpg";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Uploader } from "./globalComponents/uploader/Uploader";
import {
  ModalEditReview,
  ModalWriteReview,
} from "./globalComponents/modals/ModalsReview";

const styles = {
  divColumn: {
    display: "flex",
    flexDirection: "column",
    width: "90px",
  },
  h3: {
    fontSize: { xs: "18px", md: "24px" },
    textAlign: "initial",
    margin: { xs: "5%", md: 0 },
    fontWeight: "700",
  },
  h3about: {
    fontSize: "18px",
    textAlign: "initial",
    margin: { xs: "5%", md: 0 },
    marginY: { md: 4 },
    fontWeight: "700",
  },
  text: {
    fontSize: "12px",
    textAlign: "initial",
    margin: { xs: "5%", md: 0 },
    fontWeight: "500",
  },
  h3min: {
    fontSize: { xs: "14px", md: "18px" },
    textAlign: "center",
    margin: { xs: "2%", md: 0 },
    fontWeight: "700",
  },
  textmin: {
    fontSize: "12px",
    textAlign: "center",
    margin: { xs: "5%", md: 0 },
    fontWeight: "500",
  },
  buttonSimple: {
    marginTop: "30px",
    width: "auto",
    height: "44px",
    minWidth: "155px",
    maxWidth: { xs: "170px", sm: "300px" },
    borderRadius: "15px",
    fontSize: "11px",
    background: "#262626",
    ":hover": {
      background: "#262626",
    },
    div: {
      alignSelf: "center",
      textAlign: "center",
    },
  },
  buttonGroup: {
    marginTop: "30px",
    width: "auto",
    height: "44px",
    borderRadius: "15px",
    fontSize: "9px",
    background: "#B7B7B7",
    ":hover": {
      background: "#B7B7B7",
    },
    div: {
      alignSelf: "center",
      textAlign: "center",
    },
  },
};

export const ExpandedInfo = () => {
  const { id } = useParams();
  const [siteid, setSiteid] = useState(null);
  const token = localStorage.token;
  const navigate = useNavigate();
  const { dataUser, setDataUser } = useContext(allAppsContext);
  const { setSite } = useContext(appsLikeContext);
  const [textButton, setTextButton] = useState("Save to Favorites");
  const [textLogo, setTextLogo] = useState("Save Logo");
  const [logotest, setLogoTest] = useState(false);
  const [editReview, setEditReview] = useState(false);
  const [myReview, setMyReview] = useState({});
  const [selectImg, setSelectImg] = useState({});
  const [open, setOpen] = React.useState(false);
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(0);
  const [addScreenshots, setAddScreenshots] = useState({
    text: "Save your Screenshots",
    color: "#747B84",
  });
  const [showModalWrite, setShowModalWrite] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const conditionalColor =
    textButton === "Save to Favorites" ||
    textButton === "Remove from Favorites";
  const xuser =
    localStorage.xuser !== undefined ? JSON.parse(localStorage.xuser) : "";

  const { register, handleSubmit, watch, reset } = useForm();
  const mediaLogo = watch("user.imgLogo");
  const mediaOne = watch("user.img0");
  const mediaTwo = watch("user.img1");
  const mediaThree = watch("user.img2");
  const mediaFour = watch("user.img3");
  const conditionalImgLogo = mediaLogo !== undefined && mediaLogo.length > 0;
  const conditionalImgOne = (mediaOne !== undefined) & (mediaOne?.length > 0);
  const conditionalImgTwo = (mediaTwo !== undefined) & (mediaTwo?.length > 0);
  const conditionalImgThree =
    (mediaThree !== undefined) & (mediaThree?.length > 0);
  const conditionalImgFour =
    (mediaFour !== undefined) & (mediaFour?.length > 0);
  const conditionalImgs =
    conditionalImgOne ||
    conditionalImgTwo ||
    conditionalImgThree ||
    conditionalImgFour
      ? true
      : false;

  const numberOfAppSaves = siteid?.site_stats?.saves; // 'numberOfAppSaves' will hold a reference to number of app saves

  useEffect(() => {
    setSite("Info");
  }, [setSite]);

  useEffect(() => {
    if (localStorage.token) {
      const userId = {
        user_id: xuser._id,
      };

      getDataUser(id, setSiteid, userId, setTextButton);
      siteid?.admin_email === "admin@cantonica.com" &&
      siteid?.logoChanged === false
        ? setLogoTest(true)
        : setLogoTest(false);
    } else {
      getData(id, setSiteid);
      setLogoTest(false);
    }
  }, [id]);

  useEffect(() => {
    reviewSite(id, setReviews, setEditReview, setMyReview);
    ratingSite(id, setRating);
  }, [id]);

  const buttonDisable = {
    marginTop: "30px",
    width: "166px",
    height: "44px",
    borderRadius: "15px",
    fontSize: "11px",
    background: conditionalColor ? "#262626" : "#B7B7B7",
    ":hover": {
      background: conditionalColor ? "#262626" : "#B7B7B7",
    },
    div: {
      alignSelf: "center",
      textAlign: "center",
    },
  };

  //Favorites
  const handleLiked = () => {
    if (token && localStorage.xuser) {
      const user =
        localStorage.xuser !== undefined
          ? JSON.parse(localStorage.xuser)
          : navigate("/Login");
      addAppExpanded(user._id, id, setDataUser, setTextButton, siteid);
    } else {
      navigate("/Login");
    }
  };

  //Remove like
  const handleDisliked = () => {
    if (token && localStorage.xuser) {
      const user =
        localStorage.xuser !== undefined
          ? JSON.parse(localStorage.xuser)
          : navigate("/Login");
      setTextButton(<CircularProgress color="inherit" />);
      removeAppExpanded(user._id, siteid, dataUser, setDataUser, setTextButton);
    } else {
      navigate("/Login");
    }
  };

  // action like - dislike
  const handleAction = () => {
    if (textButton === "Save to Favorites") {
      setTextButton(<CircularProgress color="inherit" />);
      handleLiked();
    }
    if (textButton === "Remove from Favorites") {
      setTextButton(<CircularProgress color="inherit" />);
      handleDisliked();
    }
  };

  const validation = (user) => {
    const formData = new FormData();
    const newSite = {
      _id: xuser._id,
      old_media: siteid?.media,
    };
    formData.append("json", JSON.stringify(newSite));

    if (user.img0 !== undefined && user.img0.length > 0) {
      const file = new File([user.img0.item(0)], user.img0.item(0).name, {
        type: user.img0.item(0).type,
      });
      formData.append("media", file);
    }
    if (user.img1 !== undefined && user.img1.length > 0) {
      const file = new File([user.img1.item(0)], user.img1.item(0).name, {
        type: user.img1.item(0).type,
      });
      formData.append("media", file);
    }
    if (user.img2 !== undefined && user.img2.length > 0) {
      const file = new File([user.img2.item(0)], user.img2.item(0).name, {
        type: user.img2.item(0).type,
      });
      formData.append("media", file);
    }
    if (user.img3 !== undefined && user.img3.length > 0) {
      const file = new File([user.img3.item(0)], user.img3.item(0).name, {
        type: user.img3.item(0).type,
      });
      formData.append("media", file);
    }
    return formData;
  };

  const onSubmit = ({ user }) => {
    setAddScreenshots({
      text: <LinearProgress color="inherit" />,
      color: "#747B84",
    });
    const userValidator = validation(user);
    axios
      .put(`/sites/${id}`, userValidator, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setAddScreenshots({
          text: "Screenshots added successfully",
          color: "yellowgreen",
        });
        getData(id, setSiteid);
        setTimeout(() => {
          reset();
          setAddScreenshots({
            text: "Save your Screenshots",
            color: "#747B84",
          });
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmitLogo = ({ user }) => {
    setTextLogo(<CircularProgress color="inherit" sx={{ fontSize: 30 }} />);
    const formData = new FormData();
    const logo = {
      logoChanged: true,
    };
    formData.append("json", JSON.stringify(logo));
    if (user.imgLogo.length > 0) {
      const file = new File([user.imgLogo.item(0)], user.imgLogo.item(0).name, {
        type: user.imgLogo.item(0).type,
      });
      formData.append("icon", file);
      axios
        .put(`/sites/update_icon/${id}`, formData)
        .then((response) => {
          dataUser.forEach((element) => {
            if (element._id === id) {
              element.logo = URL.createObjectURL(user.imgLogo.item(0));
            }
          });
          setLogoTest(false);
          setTextLogo("Save Logo");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const validatorImage = (imageDB, imageForm) => {
    let image;
    if (imageForm !== undefined && imageForm.length > 0) {
      image = URL.createObjectURL(imageForm[0]);
    } else if (imageDB !== undefined && imageDB.length > 0) {
      image = imageDB;
    }
    return image;
  };

  // Modal Write Review
  const handleClose = () => {
    setShowModalWrite(false);
  };

  // Modal Img
  const handleOpenModal = (img) => {
    setOpen(true);
    setSelectImg(img);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  //Modal Edit Review
  const handleCloseEdit = () => {
    setShowModalEdit(false);
  };
  const handleButtonReview = () => {
    if (localStorage.token) {
      !editReview ? setShowModalWrite(true) : setShowModalEdit(true);
    } else {
      navigate("/Login");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#FFFFFF",
        marginBottom: { xs: "5rem", sm: "3rem" },
        width: { xs: "100%", md: "70%" },
        alignSelf: "center",
        gap: { md: 3 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: { xs: "3rem", sm: "5rem" },
          justifyContent: "center",
          marginTop: "3rem",
          marginRight: { md: "3rem" },
        }}
      >
        {!logotest ? (
          <Box
            component="img"
            src={validatorImage(siteid?.logo, mediaLogo)}
            alt={siteid?.name}
            id={siteid?._id}
            sx={{
              width: { xs: "68px", md: "130px" },
              height: { xs: "68px", md: "130px" },
              borderRadius: { xs: "15px", md: "24px" },
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "68px", md: "130px" },
              height: { xs: "68px", md: "130px" },
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={validatorImage(siteid?.logo, mediaLogo)}
              alt={siteid?.name}
              sx={{
                width: { xs: "68px", md: "130px" },
                height: { xs: "68px", md: "130px" },
                borderRadius: { xs: "15px", md: "24px" },
              }}
            />
            {!conditionalImgLogo && token ? (
              <Uploader
                text={"Update Logo"}
                media={mediaLogo}
                hookForm={{ ...register("user.imgLogo") }}
              />
            ) : (
              <ButtonIcon
                containerClassname={"button"}
                onClick={handleSubmit(onSubmitLogo)}
                text={textLogo}
                styles={{
                  display: token ? "flex" : "none",
                  marginY: "10px",
                  width: "140px",
                  height: "30px",
                  borderRadius: "15px",
                  background: "#262626",
                  ":hover": { background: "#262626" },
                }}
                variant="contained"
              />
            )}
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: { md: 2 } }}>
          <Typography variant="h3" sx={styles.h3}>
            {siteid?.name}
          </Typography>
          <Typography variant="body2" sx={styles.text}>
            {siteid?.urlShow}
          </Typography>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              flexDirection: "row",
              width: "100%",
              marginTop: "0.5rem",
              gap: { md: 4 },
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: "14px",
                textAlign: "center",
                margin: "2%",
                fontWeight: "700",
              }}
            >
              {numberOfAppSaves}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: "12px",
                textAlign: "center",
                marginX: "5%",
                marginY: "2%",
                fontWeight: "500",
              }}
            >
              Saves
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "2rem",
          marginX: { md: "3rem" },
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            width: { xs: "120px", md: "150px" },
            gap: { md: 2 },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Rating
              name="read-only"
              value={rating}
              precision={0.5}
              sx={{ fontSize: { md: "32px" } }}
              readOnly
            />
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: "14px", md: "18px" },
                textAlign: "center",
                marginX: { xs: "2%", md: 0 },
                marginBottom: { xs: "2%", md: 0 },
                fontWeight: "700",
                alignSelf: "center",
                paddingLeft: "1rem",
                marginTop: "10px",
              }}
            >
              {rating}
            </Typography>
          </Box>
          <Typography variant="body2" sx={styles.textmin}>
            {reviews?.length > 0 ? reviews.length + " Reviews" : "0 Reviews"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-evenly",
            gap: { md: 2 },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Rating
              name="read-only"
              value={rating}
              precision={0.5}
              sx={{ fontSize: { md: "32px", alignSelf: "center" } }}
              readOnly
            />
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: "14px", md: "18px" },
                textAlign: "center",
                marginX: { xs: "2%", md: 0 },
                marginBottom: { xs: "2%", md: 0 },
                fontWeight: "700",
                alignSelf: "center",
                paddingLeft: "1rem",
                marginTop: "5px",
              }}
            >
              {rating}
            </Typography>
          </Box>
          <Typography variant="body2" sx={styles.textmin}>
            {reviews?.length > 0 ? reviews.length + " Reviews" : "0 Reviews"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            width: { xs: "120px", md: "150px" },
            marginTop: "0.5rem",
            gap: { md: 4 },
            justifyContent: "center",
          }}
        >
          <Typography variant="h3" sx={styles.h3min}>
            {siteid?.site_stats.saves}
          </Typography>
          <Typography variant="body2" sx={styles.textmin}>
            Saves
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", gap: "1rem", justifyContent: "space-evenly" }}
      >
        <Link
          to={`${siteid?.url}`}
          style={{ marginLeft: "10px" }}
          target="_blank"
        >
          <ButtonSimple text={siteid?.urlShow} styles={styles.buttonSimple} />
        </Link>
        <ButtonSimple
          text={textButton}
          styles={buttonDisable}
          onClick={handleAction}
        />
      </Box>
      {siteid?.media.length >= 0 && (
        <Box
          sx={{
            height: { xs: "220px", sm: "250px", md: "270px", lg: "300px" },
            width: { xs: "100%", sm: "75%", md: "80%" },
            marginTop: { xs: "3rem", lg: "1rem" },
            alignSelf: "center",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              minWidth: "120px",
              fontSize: "18px",
              textAlign: "center",
              marginX: { xs: "5%", md: 0 },
              marginBottom: { xs: "5%", md: 4 },
              fontWeight: "700",
              paddingLeft: "5%",
            }}
          >
            Screenshots
          </Typography>
          {conditionalImgs ? (
            <Typography
              variant="body2"
              sx={{
                color: addScreenshots.color,
                fontWeight: 600,
                cursor: "pointer",
                fontSize: "14px",
                alignSelf: "center",
                marginBottom: { xs: "1rem", md: "2rem" },
              }}
              onClick={handleSubmit(onSubmit)}
            >
              {addScreenshots.text}
            </Typography>
          ) : (
            ""
          )}
          <Slider
            images={siteid?.media}
            hookForm={register}
            media={[mediaOne, mediaTwo, mediaThree, mediaFour]}
            handleOpenModal={handleOpenModal}
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "90%", sm: "70%" },
          alignSelf: "center",
          marginTop: "0.5rem",
        }}
      >
        <Typography variant="h3" sx={styles.h3about}>
          About this app
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "justify", fontSize: { xs: "12px", md: "14px" } }}
        >
          {siteid?.description}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "100px 100px 100px",
            sm: "auto auto auto",
          },
          width: { xs: "90%", sm: "70%" },
          alignSelf: "center",
          gap: 3,
          marginX: "13px",
        }}
      >
        {siteid?.keywords.map((item, index) => (
          <ButtonSimple key={index} text={item} styles={styles.buttonGroup} />
        ))}
      </Box>
      {reviews.length > 0 ? (
        reviews.map((item) => (
          <Box
            key={item._id}
            sx={{
              marginTop: { xs: "2rem", md: "1rem" },
              alignSelf: "center",
              width: { xs: "90%", sm: "75%" },
            }}
          >
            <CardReview review={item} />
          </Box>
        ))
      ) : (
        <Typography
          variant="h2"
          gutterBottom
          sx={{ alignSelf: "center", marginTop: { xs: 5 }, fontSize: "14px" }}
        >
          This app has no reviews yet.
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
          alignSelf: "center",
          bottom: 0,
          paddingY: { xs: "20px" },
          background: "white",
          position: "fixed",
          zIndex: 100,
        }}
      >
        <Box
          sx={{
            display: "flex",
            cursor: "pointer",
            width: { xs: "40%", sm: "25%", md: "15%" },
            alignItems: "end",
            justifyContent: { xs: "space-around", md: "space-evenly" },
          }}
          onClick={handleButtonReview}
        >
          <Box component="img" src={IconNew} />
          <Typography
            variant="h4"
            sx={{ color: "#747B84", fontWeight: 600, fontSize: 14 }}
          >
            {!editReview ? "Write a Review" : "Edit my Review"}
          </Typography>
        </Box>
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = "mailto:support@cantonica.com";
            e.preventDefault();
          }}
          style={{ textDecoration: "none" }}
        >
          <Box
            sx={{
              display: "flex",
              cursor: "pointer",
              width: "100%",
              alignItems: "center",
              justifyContent: { xs: "space-around", md: "space-evenly" },
            }}
            onClick={() => {
              console.log("Help!!");
            }}
          >
            <HelpOutlineIcon sx={{ color: "#747B84" }} />
            <Typography
              variant="h4"
              sx={{ color: "#747B84", fontWeight: 600, fontSize: 14 }}
            >
              App Support
            </Typography>
          </Box>
        </Link>
      </Box>
      <ModalWriteReview
        showModalWrite={showModalWrite}
        handleClose={handleClose}
        idSite={siteid?._id}
        reviews={reviews}
        setEditReview={setEditReview}
        setMyReview={setMyReview}
        setRating={setRating}
      />
      <ModalEditReview
        showModalEdit={showModalEdit}
        handleCloseEdit={handleCloseEdit}
        myReview={myReview}
        reviews={reviews}
        setRating={setRating}
        idSite={siteid?._id}
      />
      <ModalImg
        selectImg={selectImg}
        open={open}
        handleCloseModal={handleCloseModal}
      />
    </Box>
  );
};

const ModalImg = ({ selectImg, open, handleCloseModal }) => {
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: 300, md: 400 },
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          borderRadius: { xs: "15px", sm: "24px" },
        }}
      >
        <Box
          component="img"
          src={selectImg}
          alt={""}
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: { xs: "15px", sm: "24px" },
          }}
        />
      </Box>
    </Modal>
  );
};
