import {
  Divider,
  Box,
  Grid,
  Avatar,
  CardHeader,
  Typography,
  Badge,
  Modal,
  Fade,
} from "@mui/material";
import { Button } from "@mui/material-next";
import { Header } from "./Header";
import { Footer } from "./Footer";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CheckIcon from "@mui/icons-material/Check";
import { marked } from "marked";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";

// style overrides
const styles = {
  overallDiv: {
    textAlign: "left", // Note: This is because App.css has text-align: "center"
  },
  contentBox: {
    paddingRight: "15vw",
    paddingLeft: "15vw",
    marginTop: "9vh",
    marginBottom: "12vh",
  },
  markdownContentBox: {
    color: "#475467",
    fontSize: "18px",
    "& p": {
      display: "flex",
      justifyContent: "center",
    },
    "& img": {
      maxWidth: "100%",
      maxHeight: "65vh",
    },
    "h1,h2,h3,h4,h5,h6": {
      color: "#101828",
    },
    marginBottom: "7vh",
  },
  cardHeader: {
    padding: 0,
    title: {
      fontWeight: 600,
      fontSize: "18px",
    },
    subHeading: {
      fontSize: "16px",
    },
  },
  avatar: {
    width: "56px",
    height: "56px",
  },
  copyLinkBtn: {
    color: "#344054",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    padding: "10px 16px 10px 16px",
    fontSize: "14px",
    fontWeight: 600,
    // width: "123px",
    height: "40px",
    boxShadow: "0px 1px 2px 0px #1018280D",
    icon: {
      color: "#344054",
    },
    marginTop: { xs: "0.5rem", md: 0, sm: 0, lg: 0 },
  },
  socialMediaIcon: {
    color: "#344054",
    border: "1px solid #D0D5DD",
    borderRadius: "8px",
    fontWeight: 600,
    padding: "2px",
    minWidth: "40px",
    height: "40px",
    boxShadow: "0px 1px 2px 0px #1018280D",
    icon: {
      color: "#98A2B3",
      width: "22px",
      height: "22px",
    },
    ".MuiButton-startIcon": {
      marginRight: 0,
    },
  },
  contentHeader: {
    marginTop: "10vh",
    // height: "70vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  publishedDate: {
    color: "#D27B2C",
    fontWeight: 600,
    fontSize: "16px",
  },
  blogTitle: {
    color: "#101828",
    fontSize: "48px",
    fontWeight: 600,
  },
  blogSubTitle: {
    color: "#475467",
    fontSize: "20px",
    fontWeight: 400,
  },
  blogPostCategories: {
    display: "flex",
    justifyContent: "center",
    marginRight: "3vw",
    marginTop: "4vh",
    marginBottom: "4vh",
    ".MuiBadge-badge": {
      position: "relative",
      fontSize: "14px",
      padding: "2px 10px 2px 10px",
      borderRadius: "16px",
      marginRight: "0.5rem",
    },
    ".MuiBadge-root:nth-child(1) .MuiBadge-badge": {
      background: "#FFF0E3",
      color: "#A1550FCC",
    },
    ".MuiBadge-root:nth-child(2) .MuiBadge-badge": {
      background: "#EEF4FF",
      color: "#3538CD",
    },
    ".MuiBadge-root:nth-child(3) .MuiBadge-badge": {
      background: "#FDF2FA",
      color: "#C11574",
    },
  },
  blogTitleImage: {
    width: "65%",
    height: "100%",
    margin: "auto",
  },
  recommendationsDivider: {
    marginBottom: "4vh",
    marginLeft: "7vw",
    marginRight: "7vw",
  },
  recommendationsBox: {
    // border: "1px solid black",
    // height: "60vh",
    marginLeft: "7vw",
    marginRight: "7vw",
    marginBottom: "10vh",
    marginTop: "10vh",
  },
  recommondationsHeading: {
    fontSize: "36px",
    fontWeight: 600,
    color: "#101828",
  },
  recommondatonsSubheading: {
    fontSize: "20px",
    fontWeight: 400,
    color: "#475467",
    marginTop: "1vh",
  },
  recommendedBlog: {
    width: "27vw",
    // height: "50vh",
    padding: "24px 24px 32px 24px",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  recommendedBlogAvatar: {
    width: "40px",
    height: "40px",
  },
  blogCards: { marginTop: "10vh" },
  blogCardContent: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly", // Note: This can be changed to space-between as well depending on how we want it
    minHeight: "53vh",
    marginBottom: "2vh",
  },
  blogCardImg: { width: "100%" },
  blogCardAvatar: { padding: 0 },
  blogCardAvatarTitle: { fontWeight: 600 },
  blogCardTypo1: { fontSize: "14px", color: "#A1550FCC", fontWeight: 600 },
  blogCardTypo2: {
    fontSize: "24px",
    color: "#101828",
    fontWeight: 600,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  blogCardTypo3: { fontSize: "16px", color: "#475467", fontWeight: 400 },
};

//This function is used to convert the date from example 2022-01-20T00:00:00.000+00:00  to 2022-01-20
const convertDate = (timestamp) => {
  const date = new Date(timestamp);
  const extractedDate = date.toISOString().split("T")[0];
  return extractedDate;
};

export const BlogPost = () => {
  //Function used when user pressed the copy link button and immediately presses a close button in modal
  const handleModalClose = () => {
    setShowMessage(false);
  };

  //Function used to copy the link when user presses a button
  const handleCopyLink = async () => {
    try {
      const url = window.location.href;
      await navigator.clipboard.writeText(url);

      setClickedUrl(url);

      setTimeout(() => setClickedUrl(""), 1000);
    } catch (error) {
      console.error("Failed to copy URL:", error);
    }
  };

  const { blogId } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [clickedUrl, setClickedUrl] = useState(""); // This state is used to store the link of the url when button is pressed
  const [showMessage, setShowMessage] = useState(false); //Show the message stating Link Copied when user presses the button

  useEffect(() => {
    async function fetchBlog() {
      try {
        const response = await axios.get(`/blogs/${blogId}`);

        if (response.status !== 200) {
          throw new Error("Failed to fetch the blog data");
        }

        const { data } = response;

        setBlogData(data);
      } catch (error) {
        console.error("Error fetching blog data", error);
      }
    }

    fetchBlog();
  }, [blogId]);

  return (
    <div style={styles.overallDiv}>
      <Header />
      {blogData && (
        <Box id="content-header" style={styles.contentHeader}>
          {blogData.date_created && blogData.date_created.length > 0 && (
            <Typography align="center" style={styles.publishedDate}>
              Published {convertDate(blogData.date_created)}
            </Typography>
          )}
          {blogData.name && blogData.name.length > 0 && (
            <Typography align="center" style={styles.blogTitle}>
              {blogData.name}
            </Typography>
          )}
          {blogData.description && blogData.description.length > 0 && (
            <Typography align="center" style={styles.blogSubTitle}>
              {blogData.description}
            </Typography>
          )}

          {blogData.category && blogData.category.length > 0 && (
            <Box id="blog-post-categories" sx={styles.blogPostCategories}>
              {blogData.category.map((category, index) => {
                return (
                  <Badge key={index} badgeContent={category.name} overlap="" />
                );
              })}
            </Box>
          )}

          <Box
            component="img"
            src={blogData.image}
            alt="blog-title-image"
            style={styles.blogTitleImage}
          />

          <Box sx={styles.contentBox}>
            {blogData.content && blogData.content.length > 0 && (
              <Box id="markdown-content" sx={styles.markdownContentBox}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${marked(blogData.content)}`,
                  }}
                ></div>
              </Box>
            )}
            <Divider />
            <Grid
              id="content-footer"
              container
              spacing={2}
              mt={1}
              justifyContent="space-between"
            >
              <Grid item>
                <CardHeader
                  title={blogData.author.name}
                  avatar={
                    <Avatar
                      alt={blogData.author.name}
                      src={blogData.author.avatar}
                      sx={styles.avatar}
                    ></Avatar>
                  }
                  // subheader={personTitle}
                  sx={styles.cardHeader}
                  titleTypographyProps={styles.cardHeader.title}
                  subheaderTypographyProps={styles.cardHeader.subHeading}
                />
              </Grid>
              <Grid item>
                <Grid container spacing={1.3}>
                  <Grid item>
                    {clickedUrl ? (
                      <Button
                        startIcon={<CheckIcon sx={styles.copyLinkBtn.icon} />}
                        variant="outlined"
                        size="small"
                        sx={styles.copyLinkBtn}
                        onClick={handleCopyLink}
                      >
                        Copied!
                      </Button>
                    ) : (
                      <Button
                        startIcon={
                          <ContentCopyIcon sx={styles.copyLinkBtn.icon} />
                        }
                        variant="outlined"
                        size="small"
                        sx={styles.copyLinkBtn}
                        onClick={handleCopyLink}
                      >
                        Copy link
                      </Button>
                    )}
                  </Grid>

                  {blogData.socialMedia && blogData.socialMedia.twitter && (
                    <Grid item>
                      <Button
                        startIcon={
                          <TwitterIcon sx={styles.socialMediaIcon.icon} />
                        }
                        variant="outlined"
                        size="small"
                        sx={styles.socialMediaIcon}
                      ></Button>
                    </Grid>
                  )}
                  {blogData.socialMedia && blogData.socialMedia.facebook && (
                    <Grid item>
                      <Button
                        startIcon={
                          <FacebookIcon sx={styles.socialMediaIcon.icon} />
                        }
                        variant="outlined"
                        size="small"
                        sx={styles.socialMediaIcon}
                      ></Button>
                    </Grid>
                  )}
                  {blogData.socialMedia && blogData.socialMedia.linkedin && (
                    <Grid item>
                      <Button
                        startIcon={
                          <LinkedInIcon sx={styles.socialMediaIcon.icon} />
                        }
                        variant="outlined"
                        size="small"
                        sx={styles.socialMediaIcon}
                      ></Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}

      <Modal
        open={showMessage}
        onClose={handleModalClose}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={showMessage}>
          <Box
            sx={{
              backgroundColor: "paper",
              boxShadow: 3,
              p: 4,
              outline: "none",
              borderRadius: 1,
            }}
          >
            <Typography>Link Copied!</Typography>
          </Box>
        </Fade>
      </Modal>

      <Footer />
    </div>
  );
};
