import React from "react";
import {Table, TableBody, TableCell, TableContainer, Paper, TableRow, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
export const TableApps = ({data, setIdSiteDelete, setShowModalDelete, setShowEdit, setEditSite}) => {

        return (
          <>
            <Typography variant="h3" gutterBottom sx={{alignSelf:{xs: "start", sm:"center"}, marginTop: {xs: 3, sm: 5}, marginLeft:{xs: "2rem", sm: 0}, fontWeight: "bold", fontSize: "20px"}}>My Apps</Typography>        
            <TableContainer component={Paper} sx={{width: "90%", marginTop: 3, alignSelf: "center"}}>
              <Table sx={{  minWidth: 350 }} aria-label="simple table">
                <TableBody>
                  {data.map((item) => (
                    <TableRow
                      key={item._id}
                      sx={{ '&:last-child td':0, '&:last-child th':0}}>
                      <TableCell align="center">{item.name}</TableCell>
                      <TableCell align="center" sx={{display: {xs: "none", sm: "table-cell"}}}>{item.url}</TableCell>
                      <TableCell align="center"><EditIcon onClick={()=>{setEditSite(item); setShowEdit(true)}}/></TableCell>
                      <TableCell align="center"><DeleteIcon onClick={()=>{setIdSiteDelete(item._id); setShowModalDelete(true)}} /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );
}