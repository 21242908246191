import React, {useState, useContext, useEffect} from "react";
import { Box, Typography } from '@mui/material';
import { CardImage } from "./globalComponents/cards/CardImage";
import { tabsContext, appsLikeContext } from "./Layout";
import { allAppsContext } from "../App";
import { searcherUser, searcher } from "./globalComponents/connections";
import { CardApps } from "./globalComponents/cards/CardsAdd"
import { useNavigate } from "react-router-dom";

export const SearchResult = () => {
    const [results, setResults] = useState([])
    const { inputValue } = useContext(tabsContext)
    const { site  } = useContext(appsLikeContext)
    const { topApps } = useContext(allAppsContext)
    const token = localStorage.token
    const navigate = useNavigate()
    
    //search
    useEffect(() => {
        if(token){
            const user = JSON.parse(localStorage.xuser)
            searcherUser(setResults, inputValue, user._id)
            // site === "Apps" && searcherUser(setResults, inputValue, user._id)
            // site === "Favorites" && searcherSavedUser(setResults, inputValue, user._id)
        } else{
            searcher(setResults, inputValue)
        }               
    }, [inputValue, token, site])      
    
    useEffect(() => {
        if(inputValue.length === 0){navigate(-1)}
        // if(inputValue.length === 0 && site === "Favorites"){navigate("/Favorites")}
    }, [inputValue, navigate])      

    return(
                <Box sx={{display: "flex" , flexDirection: "column",  width: {sm:"80%"}, alignSelf: "center"}}>
                    {results.length !== 0 ? results.map(element => (
                        <Box key={element._id} sx={{ paddingY: {xs:"7%", sm:"5%"}, paddingX: "3%", borderBottom: "1px solid #F4F6F9"}}>
                            <CardApps element={element} results={results} setResults={setResults} />
                        </Box>
                    )): 
                        <Typography variant="h5" sx={{fontSize: "16px", textAlign: "center", marginY: {xs:"50px", md: 0}, fontWeight: "700"}}>No results found</Typography>
                    }
                    <Typography variant="h3" sx={{fontSize: "18px", textAlign: "initial", margin: "5%", fontWeight: "700",}}>Popular Web Apps</Typography>
                    <Box sx={{display: "grid",  gap: {xs:4, sm:5}, gridTemplateColumns: {xs: "100px 100px 100px", sm:  "120px 120px 120px", md:"150px 150px 150px", lg: "170px 170px 170px"}, margin: {xs:"1rem", md:"3rem"}, justifyContent: "space-around"}}>
                        {topApps && topApps.map(element => (
                            <Box key={element._id} sx={{cursor: "pointer",  borderRadius:{xs:"15px", md: "24px"}, margin: 2, ":hover": {boxShadow: "inset 0 0 20px rgba(49, 138, 172, 0.5), 0 0 20px rgba(49, 138, 172, 0.4)", outlineColor: "rgba(49, 138, 172, 0)", outlineOffset: "80px", textShadow: "1px 1px 6px #fff"}}}>
                                <CardImage element={element} data={topApps}/>
                            </Box>            
                        ))}
                    </Box> 
                </Box>
        )
}
